import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientNotes } from "../../store/patient/thunks";
import { setProfileLoadingAction } from "../../store/patient/actions";
import { setPatientIdGeneratingAction } from "../../store/voiceRecorder/actions";
import useRecorder from "../../hooks/useRecorder";
import RecorderControls from "../Basic/VoiceRecord/RecorderControls";
import ScribeAttachModal from "../Basic/VoiceRecord/ScribeAttachModal";
import ScribeEncounterModal from "../Basic/VoiceRecord/ScribeEncounterModal";
import Loader from "../Basic/Loader";

const ScribeTab = () => {
  const { selectedPatientProfile } = useSelector((state) => state.patient);
  const { openAttachModal, openEncounterModal, patientIdGenerating } =
    useSelector((state) => state.voiceRecorder);
  const { recorderState, ...handlers } = useRecorder(
    false,
    selectedPatientProfile?.info.patient_id,
    selectedPatientProfile
  );
  const [notes, setNotes] = useState(selectedPatientProfile?.notes || []);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;

    if (selectedPatientProfile) {
      dispatch(
        getPatientNotes(
          selectedPatientProfile.info.patient_id,
          selectedPatientProfile,
          () => dispatch(setProfileLoadingAction(false))
        )
      );

      interval = setInterval(() => {
        dispatch(
          getPatientNotes(
            selectedPatientProfile.info.patient_id,
            selectedPatientProfile,
            () => dispatch(setProfileLoadingAction(false))
          )
        );
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [selectedPatientProfile?.info?.patient_id]);

  useEffect(() => {
    if (selectedPatientProfile && selectedPatientProfile.notes) {
      setNotes(selectedPatientProfile.notes);
    }
  }, [selectedPatientProfile?.notes]);

  return (
    <div className="max-w-2xl">
      <div className="p-6 rounded-lg border mb-8">
        <RecorderControls recorderState={recorderState} handlers={handlers} />
      </div>
      {patientIdGenerating &&
        patientIdGenerating === selectedPatientProfile?.info.patient_id && (
          <div className="w-full flex items-center justify-center space-x-3 mt-5 text-lg">
            <Loader />
            <p>Generating Note ...</p>
          </div>
        )}

      {openAttachModal && (
        <ScribeAttachModal
          hasEncounters={notes.length > 0}
          onGenerating={() =>
            dispatch(
              setPatientIdGeneratingAction(
                selectedPatientProfile?.info.patient_id
              )
            )
          }
          onEnd={() => dispatch(setPatientIdGeneratingAction(null))}
        />
      )}
      {openEncounterModal && (
        <ScribeEncounterModal
          encounters={notes}
          onGenerating={() =>
            dispatch(
              setPatientIdGeneratingAction(
                selectedPatientProfile?.info.patient_id
              )
            )
          }
          onEnd={() => dispatch(setPatientIdGeneratingAction(null))}
        />
      )}
    </div>
  );
};

export default ScribeTab;
