import axios from "axios";
import { BASE_URL, setAuthToken } from "../../helpers/config";
import {
  setAssistantsAction,
  setCountryAction,
  setErrorAction,
  setNotificationAction,
  setOpenSuccessModalAction,
  setRolesAction,
  setSignupInfoAction,
  setUserAction,
  setUsersAction,
} from "./actions";
import { history } from "../../helpers/history";
import { Pages } from "../../helpers/constants";
import { useIntercom } from "react-use-intercom";

export const getUser = async () => {
  const { data } = await axios.get(`${BASE_URL}/user/me`);
  return data.data;
};

export const updateStoredUser = (user) => (dispatch) => {
  if (localStorage.user) localStorage.setItem("user", JSON.stringify(user));
  if (sessionStorage.user) sessionStorage.setItem("user", JSON.stringify(user));
  dispatch(setUserAction(user));
};

export const setToken = (email, password, remember, intercom) => (dispatch) => {
  axios
    .post(`${BASE_URL}/auth/login`, {
      email: email,
      password: password,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(setErrorAction(response.data.error.message));
      } else {
        const token = response.data.data.auth_token;
        if (token) {
          if (remember) {
            localStorage.setItem("token", token);
          } else {
            sessionStorage.setItem("token", token);
          }

          setAuthToken(token);

          getUser().then((user) => {
            if (remember) {
              localStorage.setItem("user", JSON.stringify(user));
            } else {
              sessionStorage.setItem("user", JSON.stringify(user));
            }
            dispatch(setUserAction(user));
            intercom.update({ email: email });
          });
        }
      }
    });
};

export const setSettings = (settings) => (dispatch) => {
  axios.post(`${BASE_URL}/user/general`, settings).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to save settings",
        })
      );
    } else {
      getUser().then((user) => dispatch(updateStoredUser(user)));
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Settings successfully saved",
          desc: "Your general settings have been successfully saved",
        })
      );
    }
  });
};

export const addQuestions = (questions) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/default-questions/create`, questions)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to save questions",
          })
        );
      } else {
        getUser().then((user) => dispatch(updateStoredUser(user)));
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Questions successfully saved",
            desc: "Question has been successfully added",
          })
        );
      }
    });
};

export const removeQuestions = (questionIds) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/default-questions/remove`, { ids: questionIds })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to delete questions",
          })
        );
      } else {
        getUser().then((user) => dispatch(updateStoredUser(user)));
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Questions successfully saved",
            desc: "Question has been successfully deleted",
          })
        );
      }
    });
};

export const reorganizeQuestions = (questions) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/default-questions/update`, questions)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to reorder questions",
          })
        );
      } else {
        getUser().then((user) => dispatch(updateStoredUser(user)));
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Questions successfully saved",
            desc: "Questions order has been successfully saved",
          })
        );
      }
    });
};

export const updateUser = (updatedFields) => (dispatch) => {
  axios.post(`${BASE_URL}/user/update`, updatedFields).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to update user information",
        })
      );
    } else {
      getUser().then((user) => dispatch(updateStoredUser(user)));
      dispatch(
        setNotificationAction({
          status: "success",
          title: "User information successfully updated",
          desc: "Your personal information has been successfully updated",
        })
      );
    }
  });
};

export const updatePassword = (oldPassword, newPassword) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/update-password`, {
      old_password: oldPassword,
      new_password: newPassword,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update password",
          })
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password successfully updated",
            desc: "Your password has been successfully updated",
          })
        );
      }
    });
};

export const createPassword = (resetToken, newPassword) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/create-password/${resetToken}`, {
      password: newPassword,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Password is not updated",
            desc: "Failed to update password",
          })
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password successfully updated",
            desc: "Your password has been successfully updated",
          })
        );
      }
    });
};

export const forgotPassword = async (email) => {
  await axios.post(`${BASE_URL}/user/forgot-password`, {
    email: email,
  });
};

export const resetPassword = async (payload, newPassword) => {
  await axios.post(`${BASE_URL}/user/reset-password`, {
    payload: payload,
    password: newPassword,
  });
};

export const createUser = (userFields) => (dispatch) => {
  axios.post(`${BASE_URL}/user/create`, userFields).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to create user",
        })
      );
    } else {
      dispatch(getUsers());
      dispatch(setOpenSuccessModalAction(true));
    }
  });
};

export const getUsers = () => (dispatch) => {
  axios.get(`${BASE_URL}/user/list`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load users",
        })
      );
    } else {
      dispatch(setUsersAction(response.data.data.users));
    }
  });
};

export const getRoles = () => (dispatch) => {
  axios.get(`${BASE_URL}/user/roles`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load roles",
        })
      );
    } else {
      dispatch(setRolesAction(response.data.data));
    }
  });
};

export const getCountry = () => (dispatch) => {
  axios.get("https://ipapi.co/json/").then((response) => {
    localStorage.setItem("country", response.data.country_code);
    dispatch(setCountryAction(response.data.country_code));
  });
};

export const getAssistants = () => (dispatch) => {
  axios.get(`${BASE_URL}/user/list-shared`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load assistants",
        })
      );
    } else {
      dispatch(setAssistantsAction(response.data.data.doctors));
    }
  });
};

export const addAssistant = (doctorId) => (dispatch) => {
  axios.post(`${BASE_URL}/user/share/${doctorId}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to add assistant",
        })
      );
    } else {
      dispatch(getAssistants());
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Assistant successfully added",
          desc: "Assistant has been successfully added",
        })
      );
    }
  });
};

export const removeAssistant = (doctorId) => (dispatch) => {
  axios.post(`${BASE_URL}/user/remove-shared/${doctorId}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to remove assistant",
        })
      );
    } else {
      dispatch(getAssistants());
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Assistant successfully removed",
          desc: "Assistant has been successfully removed",
        })
      );
    }
  });
};

export const getUserInfo = (token) => (dispatch) => {
  axios.get(`${BASE_URL}/user/info/${token}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load user info",
        })
      );
    } else {
      dispatch(setSignupInfoAction(response.data.data));
    }
  });
};

export const registerUser =
  (token, password, firstName, lastName) => (dispatch) => {
    axios
      .post(`${BASE_URL}/user/complete-sign-up/${token}`, {
        password: password,
        first_name: firstName,
        last_name: lastName,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to register user",
            })
          );
        } else {
          history.navigate(Pages.LOGIN);
        }
      });
  };
