import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WS_URL = process.env.REACT_APP_WS_URL;

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
