import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import {
  setNumOfFilteredPatientsAction,
  setNumOfPatientsAction,
  setPatientExistsErrorAction,
  setPatientLoaderAction,
  setPatientsAction,
  setProfileLoadingAction,
  setSelectedPatientProfileAction,
} from "./actions";
import { setNotificationAction } from "../user/actions";

export const getNumOfPatients = () => (dispatch) => {
  axios
    .post(`${BASE_URL}/patient/search`, {
      q: "",
      offset: 0,
      limit: 0,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load number of patients",
          })
        );
      } else {
        dispatch(setNumOfPatientsAction(response.data.data.total));
      }
    });
};

export const searchPatients =
  (searchInput, page, rows, loader = true, onSuccess = () => {}) =>
  (dispatch) => {
    if (loader) dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/search`, {
        q: searchInput,
        offset: (page - 1) * rows,
        limit: rows,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load patients",
            })
          );
        } else {
          dispatch(setPatientsAction(response.data.data.patients));
          dispatch(setNumOfFilteredPatientsAction(response.data.data.total));
          onSuccess();
        }
        dispatch(setPatientLoaderAction(false));
      });
  };

export const createPatient =
  (patientData, page, rows, searchValue, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios.post(`${BASE_URL}/patient/create`, patientData).then((response) => {
      if (response.data.error) {
        if (response.data.error.message.includes("Patient already exists")) {
          dispatch(setPatientExistsErrorAction(true));
        } else {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to create patient",
            })
          );
        }
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully add patient",
            desc: "Your changes have been saved and your profile is live. Your team can make edits.",
          })
        );
        onSuccess();
      }
      dispatch(searchPatients(searchValue, page, rows));
      dispatch(getNumOfPatients());
      dispatch(setPatientLoaderAction(false));
    });
  };

export const updatePatient =
  (patientData, page, rows, searchValue, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios.post(`${BASE_URL}/patient/update`, patientData).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to update patient",
          })
        );
      } else {
        dispatch(searchPatients(searchValue, page, rows));
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully update patient",
            desc: "Your changes have been saved and your profile is live. Your team can make edits.",
          })
        );
        onSuccess();
      }
      dispatch(setPatientLoaderAction(true));
    });
  };

export const removePatients =
  (patientIds, page, rows, searchValue, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/remove`, { ids: patientIds })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete patients",
            })
          );
        } else {
          onSuccess();
          dispatch(getNumOfPatients());
          dispatch(searchPatients(searchValue, page, rows));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully delete patients",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            })
          );
        }
        dispatch(setPatientLoaderAction(false));
      });
  };

export const importPatients = (file, page, rows, searchValue) => (dispatch) => {
  dispatch(setPatientLoaderAction(true));
  axios
    .post(
      `${BASE_URL}/patient/import-csv`,
      { csv: file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to import patients",
          })
        );
      } else {
        dispatch(searchPatients(searchValue, page, rows));
        dispatch(getNumOfPatients());
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully import patients",
            desc: "Your changes have been saved and your profile is live. Your team can make edits.",
          })
        );
      }
      dispatch(setPatientLoaderAction(false));
    });
};

export const pausePatient =
  (patientId, page, rows, searchValue) => (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios.post(`${BASE_URL}/patient/pause/${patientId}`).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to pause patient",
          })
        );
      } else {
        dispatch(searchPatients(searchValue, page, rows));
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully pause patient",
            desc: "Your changes have been saved and your profile is live. Your team can make edits.",
          })
        );
      }
      dispatch(setPatientLoaderAction(false));
    });
  };

export const unpausePatient =
  (patientId, page, rows, searchValue) => (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios.post(`${BASE_URL}/patient/unpause/${patientId}`).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to unpause patient",
          })
        );
      } else {
        dispatch(searchPatients(searchValue, page, rows));
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully unpause patient",
            desc: "Your changes have been saved and your profile is live. Your team can make edits.",
          })
        );
      }
      dispatch(setPatientLoaderAction(false));
    });
  };

export const getPatientInfo = (patientId, patientProfile) => (dispatch) => {
  dispatch(setProfileLoadingAction(true));
  axios.get(`${BASE_URL}/patient/general/${patientId}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to get patient info",
        })
      );
    } else {
      dispatch(
        setSelectedPatientProfileAction({
          ...patientProfile,
          info: response.data.data.patient,
        })
      );
    }
    dispatch(setProfileLoadingAction(false));
  });
};

export const getPatientEnrollments =
  (patientId, patientProfile) => (dispatch) => {
    dispatch(setProfileLoadingAction(true));
    axios
      .get(`${BASE_URL}/patient/enrollments/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to get patient enrollments",
            })
          );
        } else {
          dispatch(
            setSelectedPatientProfileAction({
              ...patientProfile,
              enrollments: response.data.data.enrollments,
            })
          );
        }
        dispatch(setProfileLoadingAction(false));
      });
  };

export const getPatientNotes =
  (patientId, patientProfile, onEnd = () => {}) =>
  (dispatch) => {
    axios.get(`${BASE_URL}/patient/notes/${patientId}`).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to get patient notes",
          })
        );
      } else {
        dispatch(
          setSelectedPatientProfileAction({
            ...patientProfile,
            notes: response.data.data.notes,
          })
        );
      }
      onEnd();
    });
  };

export const getPatientRecordings =
  (patientId, patientProfile) => (dispatch) => {
    dispatch(setProfileLoadingAction(true));
    axios
      .get(`${BASE_URL}/patient/recordings/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to get patient recordings",
            })
          );
        } else {
          dispatch(
            setSelectedPatientProfileAction({
              ...patientProfile,
              recordings: response.data.data.recordings,
            })
          );
        }
        dispatch(setProfileLoadingAction(false));
      });
  };
