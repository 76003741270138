import { configureStore } from "@reduxjs/toolkit";
import conversationReducer from "./conversation/conversationReducer";
import userReducer from "./user/userReducer";
import patientReducer from "./patient/patientReducer";
import campaignReducer from "./campaign/campaignReducer";
import templateReducer from "./template/templateReducer";
import metricsReducer from "./metrics/metricsReducer";
import voiceRecorderReducer from "./voiceRecorder/voiceRecorderReducer";
import billingMetricsReducer from "./billingMetrics/billingMetricsReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    conversation: conversationReducer,
    patient: patientReducer,
    campaign: campaignReducer,
    template: templateReducer,
    metrics: metricsReducer,
    voiceRecorder: voiceRecorderReducer,
    billingMetrics: billingMetricsReducer,
  },
});
