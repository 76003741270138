import React from "react";

const radioButtonVariants = {
  primary: "border-primary-blue",
  dark: "border-blue-1000 bg-blue-1000",
};

const circleColor = {
  primary: "bg-primary-blue",
  dark: "bg-white border border-blue-1000",
};

const RadioButton = ({
  label,
  id,
  value,
  checked,
  onChange = () => {},
  onClick,
  variant = "primary",
  className = "text-xs text-gray-500",
  ...props
}) => {
  return (
    <label
      htmlFor={id}
      className={`flex items-center cursor-pointer ${className}`}
      onClick={onClick}
    >
      <input
        type="radio"
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        className="hidden"
        {...props}
      />
      <div
        className={`w-4 h-4 rounded-full border flex items-center justify-center 
          ${checked ? radioButtonVariants[variant] : "border-gray-300"}`}
      >
        {checked && (
          <span
            className={`w-1.5 h-1.5 rounded-full ${circleColor[variant]}`}
          />
        )}
      </div>
      {label && <p className="ml-2">{label}</p>}
    </label>
  );
};

export default RadioButton;
