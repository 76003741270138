import React, { useState } from "react";
import TopBar from "./TopBar";
import Chat from "./Chat";
import InputArea from "./InputArea";

const Conversation = ({ messages, setMessages, scrolledUp, setScrolledUp }) => {
  const [showResponse, setShowResponse] = useState(false);

  return (
    <div className="h-full w-full bg-gray-background grid grid-rows-chat-layout">
      <div className="md:pt-4 md:px-4">
        <TopBar />
      </div>
      <Chat
        scrolledUp={scrolledUp}
        setScrolledUp={setScrolledUp}
        showResponse={showResponse}
      />
      <InputArea
        setScrolledUp={setScrolledUp}
        messages={messages}
        setMessages={setMessages}
        showResponse={showResponse}
        setShowResponse={setShowResponse}
      />
    </div>
  );
};

export default Conversation;
