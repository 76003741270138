import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getConversation } from "../../../store/conversation/thunks";
import {
  collapseSidebarAction,
  expandPanelAction,
  selectConversationAction,
  setConversationAction,
} from "../../../store/conversation/actions";
import { getFormattedTimeOrDate } from "../../../helpers/helpers";
import ProfilePicture from "../../Basic/ProfilePicture";
import ConversationContextMenu from "./ConversationContextMenu";

const ConversationPreview = ({
  conversation,
  messages,
  containerRef,
  clickedConversation,
  setClickedConversation,
}) => {
  const { patient, lastMessage } = conversation;
  const {
    currentConversationId,
    collapsePatientList,
    conversationsCompletedTemp,
    nextConversationId,
  } = useSelector((state) => state.conversation);
  const [fadedOutConversations, setFadedOutConversations] = useState([]);
  const [hiddenConversations, setHiddenConversations] = useState([]);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
    bottom: "auto",
    right: "auto",
  });
  const dispatch = useDispatch();
  const lastMessageDate = new Date(
    lastMessage.send_at.replace(/-/g, "/") + " GMT+0"
  );
  const isDesktop = useMediaQuery({ minWidth: 1280 });

  useEffect(() => {
    if (
      conversationsCompletedTemp.length > 0 &&
      conversationsCompletedTemp.includes(conversation.conversation_id)
    ) {
      setFadedOutConversations([
        ...fadedOutConversations,
        conversation.conversation_id,
      ]);
    }
  }, [conversationsCompletedTemp]);

  const selectConversation = () => {
    dispatch(getConversation(lastMessage.conversation_id));
    dispatch(selectConversationAction(lastMessage.conversation_id));

    if (collapsePatientList || !isDesktop) {
      dispatch(collapseSidebarAction(true));
      dispatch(expandPanelAction(true));
    }
  };

  const hideAndSelectNext = () => {
    setTimeout(() => {
      setHiddenConversations([
        ...hiddenConversations,
        conversation.conversation_id,
      ]);
      if (currentConversationId === conversation.conversation_id) {
        if (nextConversationId) {
          dispatch(
            getConversation(nextConversationId, () =>
              dispatch(selectConversationAction(nextConversationId))
            )
          );
        } else {
          dispatch(selectConversationAction(""));
          dispatch(setConversationAction(null));
        }
      }
    }, 400);
  };

  const onRightClick = (e) => {
    e.preventDefault();
    setOpenContextMenu(true);
    setClickedConversation(conversation);
    const bounds = e.currentTarget.getBoundingClientRect();
    const listBounds = containerRef.current.getBoundingClientRect();
    const isItemAtBottom = bounds.bottom > listBounds.bottom - 50;
    const isClickAtRight = e.clientX > listBounds.right - 170;
    setContextMenuPosition({
      left: isClickAtRight ? "auto" : e.clientX - bounds.left,
      top: isItemAtBottom ? "auto" : e.clientY - bounds.top,
      right: isClickAtRight ? "-6px" : "auto",
      bottom: isItemAtBottom ? bounds.bottom - e.clientY : "auto",
    });
  };

  return (
    <div className="relative select-none">
      <div
        onContextMenu={onRightClick}
        onClick={selectConversation}
        onAnimationStart={hideAndSelectNext}
        className={`cursor-pointer overflow-hidden rounded-xl
        ${
          currentConversationId === lastMessage.conversation_id
            ? "bg-primary-blue-light text-primary-blue"
            : "hover:bg-gray-50"
        } 
        ${
          fadedOutConversations.includes(lastMessage.conversation_id)
            ? "animate-fade-out"
            : ""
        }
        ${
          hiddenConversations.includes(lastMessage.conversation_id)
            ? "h-0 p-0"
            : "pr-3 pl-2 py-2.5"
        }`}
      >
        <div className="flex space-x-2.5 overflow-hidden">
          <ProfilePicture
            src={patient.profile_picture}
            firstName={
              patient.preffered_name
                ? patient.preffered_name
                : patient.first_name
            }
            lastName={patient.last_name}
            flag={conversation.flag}
          />

          <div className="w-full">
            <div className="w-full flex items-center justify-between grid grid-cols-conv-layout gap-1">
              <p className="font-semibold text-sm truncate w-full">
                {(patient.preffered_name
                  ? patient.preffered_name
                  : patient.first_name) +
                  " " +
                  patient.last_name}
              </p>
              <p
                className={`text-xs whitespace-nowrap ml-auto 
              ${
                currentConversationId === lastMessage.conversation_id
                  ? "text-primary-blue"
                  : "text-tertiary"
              }`}
              >
                {getFormattedTimeOrDate(lastMessageDate)}
              </p>
            </div>

            <div className="flex mt-2 text-sm text-tertiary h-10 w-full space-x-2 justify-between ">
              <p className="line-clamp-2 leading-5">
                {messages &&
                messages[lastMessage.conversation_id] &&
                messages[lastMessage.conversation_id].trim() ? (
                  <span>
                    <span
                      className={
                        currentConversationId === lastMessage.conversation_id
                          ? "text-red-300"
                          : "text-red-600"
                      }
                    >
                      Draft:{" "}
                    </span>
                    {messages[lastMessage.conversation_id]}
                  </span>
                ) : (
                  lastMessage.message
                )}
              </p>
              {conversation.unread > 0 && (
                <div
                  className="h-5 min-w-5 bg-blue-1000 rounded-full flex-none text-white px-1.5
                    flex items-center justify-center"
                >
                  {conversation.unread}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {openContextMenu &&
        clickedConversation.conversation_id ===
          conversation.conversation_id && (
          <ConversationContextMenu
            onClose={() => setOpenContextMenu(false)}
            contextMenuPosition={contextMenuPosition}
            conversation={conversation}
          />
        )}
    </div>
  );
};

export default ConversationPreview;
