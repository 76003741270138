import React from "react";

const RoundIcon = ({ icon, size = "14", className }) => {
  return (
    <div
      className={`w-${size} h-${size} rounded-full flex-none
        flex items-center justify-center flex-none ${className}`}
    >
      {icon}
    </div>
  );
};

export default RoundIcon;
