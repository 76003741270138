import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Pages } from "../../helpers/constants";
import { logoutAction } from "../../store/user/actions";
import ProfilePicture from "../Basic/ProfilePicture";
import { ReactComponent as LogoIcon } from "../../assets/logo-quadrant-text.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as MessagingIcon } from "../../assets/icons/inbox-new.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/patients-new.svg";
import { ReactComponent as BillingMetricsIcon } from "../../assets/icons/billing-new.svg";
import { ReactComponent as CampaignsIcon } from "../../assets/icons/campaigns.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-new.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-simple.svg";

//styles
const navBtn =
  "h-10 rounded-2.5 flex items-center justify-start space-x-4 whitespace-nowrap cursor-pointer px-2.5 text-gray-650 ";
const activeNavBtn = "text-primary-blue ";
const iconStroke = "#404654";
const activeIconStroke = "#2970FF";
const iconSize = 24;

const MobileMenu = () => {
  const { user } = useSelector((state) => state.user);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const logout = () => {
    dispatch(logoutAction());
  };

  useEffect(() => {
    if (showMobileMenu) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [showMobileMenu]);

  const getHeader = () => {
    switch (pathname) {
      case Pages.MESSAGING:
        return "Inbox";
      case Pages.PATIENTS:
        return "Patients";
      case Pages.BILLING_METRICS:
        return "Time Tracking";
      case Pages.CAMPAIGNS:
        return "Enroll";
      case Pages.SETTINGS:
        return "Settings";
      default:
        return "";
    }
  };

  return (
    <nav className="fixed top-0 left-0 flex items-center justify-between w-screen h-16 py-3 px-5 flex-none bg-white z-50">
      <NavLink aria-label="Inbox" to={Pages.MESSAGING} className="z-50">
        <LogoIcon width="128" height="40" />
      </NavLink>

      <button
        aria-label="Menu"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        className="z-50"
      >
        {showMobileMenu ? (
          <CloseIcon stroke="#121212" width="24" height="24" />
        ) : (
          <MenuIcon stroke="#121212" width="24" height="24" />
        )}
      </button>

      <div className="fixed top-0 left-0 h-screen pt-16 z-10">
        <div
          className={`h-full bg-white transition-all duration-300 overflow-x-hidden flex flex-col justify-between pb-8 
            ${showMobileMenu ? "w-screen px-5 " : "w-0"}`}
        >
          <div
            className="flex flex-col space-y-4 border-t border-white/20 pt-7"
            onClick={() => setShowMobileMenu(false)}
          >
            <NavLink
              to={Pages.MESSAGING}
              className={({ isActive }) =>
                isActive ? navBtn + activeNavBtn : navBtn
              }
            >
              {({ isActive }) => (
                <>
                  <MessagingIcon
                    stroke={isActive ? activeIconStroke : iconStroke}
                    width={iconSize}
                    height={iconSize}
                  />
                  <p>Inbox</p>
                </>
              )}
            </NavLink>
            <NavLink
              to={Pages.PATIENTS}
              className={({ isActive }) =>
                isActive ? navBtn + activeNavBtn : navBtn
              }
            >
              {({ isActive }) => (
                <>
                  <PatientIcon
                    stroke={isActive ? activeIconStroke : iconStroke}
                    width={iconSize}
                    height={iconSize}
                  />
                  <p>Patients</p>
                </>
              )}
            </NavLink>
            <NavLink
              to={Pages.CAMPAIGNS}
              className={({ isActive }) =>
                isActive ? navBtn + activeNavBtn : navBtn
              }
            >
              {({ isActive }) => (
                <>
                  <CampaignsIcon
                    stroke={isActive ? activeIconStroke : iconStroke}
                    width={iconSize}
                    height="22"
                  />
                  <p>Enroll</p>
                </>
              )}
            </NavLink>
            <NavLink
              to={Pages.BILLING_METRICS}
              className={({ isActive }) =>
                isActive ? navBtn + activeNavBtn : navBtn
              }
            >
              {({ isActive }) => (
                <>
                  <BillingMetricsIcon
                    stroke={isActive ? activeIconStroke : iconStroke}
                    width={iconSize}
                    height={iconSize}
                  />
                  <p>Time Tracking</p>
                </>
              )}
            </NavLink>
            <NavLink
              to={Pages.SETTINGS}
              className={({ isActive }) =>
                isActive ? navBtn + activeNavBtn : navBtn
              }
            >
              {({ isActive }) => (
                <>
                  <SettingsIcon
                    stroke={isActive ? activeIconStroke : iconStroke}
                    width={iconSize}
                    height={iconSize}
                  />
                  <p>Settings</p>
                </>
              )}
            </NavLink>
          </div>

          <div className="flex items-center">
            <ProfilePicture
              size={11}
              fontSize="base"
              className="cursor-pointer"
              firstName={user.first_name}
              lastName={user.last_name}
            />
            <div className="ml-3 space-y-1">
              <p>
                {user.first_name} {user.last_name}
              </p>
              <p className="text-zinc-500 text-xs">{user.email}</p>
            </div>
            <button aria-label="Logout" className="ml-auto" onClick={logout}>
              <LogoutIcon width="24" height="24" stroke={iconStroke} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
