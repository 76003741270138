import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { scheduleCampaign } from "../../store/campaign/thunks";
import { setSelectedStartDateAction } from "../../store/campaign/actions";
import {
  getPatientEnrollments,
  searchPatients,
} from "../../store/patient/thunks";
import {
  chronicCcmPlaceholder,
  chronicPostDischargePlaceholder,
  frequency,
  frequencyCCM,
  goalsCcmPlaceholder,
  goalsPostDischargePlaceholder,
  medicalCcmPlaceholder,
  medicalPostDischargePlaceholder,
  twoConditionsRegex,
} from "../../helpers/constants";
import {
  getEventDotBgColor,
  getFormattedTime,
  getFrequencyByInterval,
} from "../../helpers/helpers";
import PatientInfo from "../Basic/PatientInfo";
import TextareaOrderedLines from "../Basic/TextareaOrderedLines";
import TextareaAutosizeForm from "../Basic/TextareaAutosizeForm";
import DateTimeShortcutsInput from "../Basic/DateTimeShortcutsInput";
import Select from "../Basic/Select";
import Button from "../Basic/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const CampaignEditPopup = ({ campaign, patient, onClose, popoverActions }) => {
  const { rowsPerPage, searchValue, currPage, selectedPatientProfile } =
    useSelector((state) => state.patient);
  const { campaignTags } = useSelector((state) => state.campaign);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      chronicConditions: campaign.outreach_focus || "",
      goals: campaign.outreach_goals || "",
      pertinentMedicalHistory: campaign.ehr_information || "",
      frequency:
        campaign && campaign.interval
          ? getFrequencyByInterval(campaign.interval)
          : "",
      start: dayjs(campaign.next_checkin.replace(/-/g, "/") + " GMT+0"),
    },
  });
  const tag = campaignTags.find(
    (tag) => tag.tag_id === campaign.campaign?.tag_id
  );

  useEffect(() => {
    if (popoverActions.current) {
      popoverActions.current.updatePosition();
    }
  }, [getValues(["chronicConditions", "goals", "pertinentMedicalHistory"])]);

  const onSubmit = (data) => {
    if (
      campaign.campaign.campaign_type === "Outreach" &&
      !twoConditionsRegex.test(getValues("chronicConditions"))
    ) {
      setError("chronicConditions", {
        type: "pattern",
        message: "Please provide at least two conditions",
      });
      return;
    }

    let isNow = false;
    if (data.start) {
      data = {
        ...data,
        start: data.start.toDate().toISOString(),
      };
      const currentDateTime = dayjs();
      const selectedDateTime = dayjs(data.start);
      isNow = selectedDateTime.isSameOrBefore(currentDateTime);
    }
    dispatch(
      scheduleCampaign(
        campaign.campaign_id,
        [patient.patient_id],
        isNow ? "now" : data.start,
        data.frequency,
        data.chronicConditions,
        data.goals,
        data.pertinentMedicalHistory,
        true,
        () => {
          dispatch(searchPatients(searchValue, currPage, rowsPerPage));
          dispatch(
            getPatientEnrollments(
              selectedPatientProfile.info.patient_id,
              selectedPatientProfile
            )
          );
        }
      )
    );
    onClose();
  };

  const onCancel = (e) => {
    e.preventDefault();
    reset();
  };

  return (
    <div className="py-2 pr-1 overflow-hidden w-full h-full">
      <div
        className="bg-white rounded-xl py-3 pl-5 pr-4 shadow-popup w-full font-Mulish h-full md:max-h-[65vh]
          overflow-y-auto scrollbar"
      >
        <div className="flex items-center justify-between space-x-3 mb-6">
          <div className="flex items-center space-x-3 truncate">
            <span
              className={`w-4 h-4 rounded flex-none  
                ${getEventDotBgColor(tag?.tag)}`}
            />
            <p className="text-lg font-semibold truncate">
              {campaign.campaign.campaign}
            </p>
          </div>
          <button
            onClick={onClose}
            className="w-5 h-5 rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center"
          >
            <CloseIcon width="13" height="13 " />
          </button>
        </div>

        <div className="flex items-end justify-between space-x-2 mb-4">
          {campaign && <PatientInfo patient={patient} />}
          <div className="text-xs text-zinc-450 font-normal whitespace-nowrap">
            {getFormattedTime(
              new Date(campaign.next_checkin.replace(/-/g, "/") + " GMT+0")
            )}
          </div>
        </div>

        <form
          id="upcoming-form"
          className="space-y-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          {campaign &&
            campaign.campaign?.campaign_type.includes("Outreach") && (
              <>
                <TextareaOrderedLines
                  name="chronicConditions"
                  label="Conditions"
                  register={register}
                  setValue={setValue}
                  required={
                    campaign.campaign.campaign_type === "Outreach"
                      ? "Please provide at least two conditions"
                      : "Please provide at least one condition"
                  }
                  error={errors.chronicConditions}
                  placeholder={
                    campaign.campaign.campaign === "Post-Discharge Care"
                      ? chronicPostDischargePlaceholder
                      : chronicCcmPlaceholder
                  }
                />

                <div className="space-y-1.5">
                  <p className="font-semibold text-sm">Goals</p>

                  <TextareaAutosizeForm
                    name="goals"
                    register={register}
                    placeholder={
                      campaign.campaign.campaign === "Post-Discharge Care"
                        ? goalsPostDischargePlaceholder
                        : goalsCcmPlaceholder
                    }
                  />
                </div>
                <div className="space-y-1.5">
                  <p className="font-semibold text-sm">
                    Pertinent Past Medical History
                  </p>
                  <TextareaAutosizeForm
                    name="pertinentMedicalHistory"
                    register={register}
                    placeholder={
                      campaign.campaign.campaign === "Post-Discharge Care"
                        ? medicalPostDischargePlaceholder
                        : medicalCcmPlaceholder
                    }
                  />
                </div>
              </>
            )}

          <Select
            register={register}
            label="Frequency"
            name="frequency"
            placeholder="Select frequency"
            options={
              campaign.campaign.campaign_type.includes("Outreach")
                ? frequencyCCM
                : frequency
            }
          />

          <DateTimeShortcutsInput
            label="Reschedule Start"
            name="start"
            withTime
            withShortcuts
            validateFunc={(value) => {
              const currentDate = dayjs();
              const selectedDate = dayjs(value);
              if (selectedDate.isBefore(currentDate, "minute")) {
                return "Please choose a date in the future";
              }
              return true;
            }}
            control={control}
            error={errors.start}
            minDateTime={dayjs()}
            onSelectDate={(date) => {
              const dateString = date.toDate().toLocaleString("en-us");
              dispatch(setSelectedStartDateAction(dateString));
            }}
          />
        </form>

        <div className="flex space-x-4 mt-6 font-semibold">
          <Button className="w-full" variant="cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="w-full" form="upcoming-form" type="submit">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CampaignEditPopup;
