import React from "react";
import { getFormattedTime } from "../../../helpers/helpers";
import ProfilePicture from "../../Basic/ProfilePicture";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";

const buttonStyle =
  "absolute top-1/2 -translate-y-1/2 w-5 h-5 rounded-full border flex items-center justify-center";

const MessageCloud = ({
  type,
  combineWithPrevious,
  profilePicture,
  namePrefix,
  firstName,
  lastName,
  message,
  time,
  failed,
  onDelete,
  onResend,
}) => {
  const outgoing = type === "outgoing";

  return (
    <div
      className={`flex max-w-4/5 xl:max-w-2/3 relative
        ${outgoing ? "flex-row-reverse ml-auto" : "space-x-2"}
        ${combineWithPrevious ? "mt-2" : "mt-3"} `}
    >
      {combineWithPrevious ? (
        <div className="h-0 w-8 flex-none" />
      ) : (
        <ProfilePicture
          src={profilePicture}
          firstName={firstName}
          lastName={lastName}
        />
      )}

      <div className={outgoing ? "mr-2" : ""}>
        {!combineWithPrevious && (
          <p
            className={`h-8 flex items-center text-sm font-semibold 
            ${outgoing ? "justify-end" : ""}`}
          >
            {namePrefix + " " + firstName + " " + lastName}
          </p>
        )}
        <div
          className={`text-xs py-3 px-3 md:px-5 font-medium w-fit flex relative
            ${
              outgoing
                ? "bg-primary-blue-light rounded-outgoing-message ml-auto"
                : "bg-gray-forground rounded-incoming-message"
            }`}
        >
          <div className="whitespace-pre-wrap">
            {message}
            <div className="text-xxs text-zinc-450 whitespace-nowrap flex float-right items-end -mb-1 -mr-1 mt-1 ml-1.5">
              {time ? (
                getFormattedTime(new Date(time.replace(/-/g, "/") + " GMT+0"))
              ) : failed ? (
                <span className="text-red-500 font-semibold">failed</span>
              ) : (
                getFormattedTime(new Date())
              )}
            </div>
          </div>

          {failed && (
            <>
              <button
                onClick={onDelete}
                className={`${buttonStyle} left-full ml-2 border-red-400`}
              >
                <CloseIcon stroke="#ef4444" height="12" width="12" />
              </button>
              <button
                onClick={onResend}
                className={`${buttonStyle} right-full mr-2 border-primary-blue`}
              >
                <RefreshIcon
                  fill="#2970FF"
                  height="13"
                  width="13"
                  className="rotate-45"
                />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCloud;
