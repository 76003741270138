import {
  SET_BILLING_LOADING,
  SET_BILLING_PATIENTS,
  SET_BILLING_TABLE_SCROLL_POSITION,
  SET_CURR_BILLING_PAGE,
  SET_CURRENT_BILLING_PATIENT,
  SET_CURRENT_DATE_STAMP,
  SET_EXPORT_LOADING,
  SET_NUM_BILLING_PATIENTS,
  SET_SELECTED_BILLING_TYPE,
  SET_SELECTED_CPT_CODE,
  SET_SORTED_BY,
  SET_TIME_ADJUST_LOADING,
} from "../types";

export function setBillingLoadingAction(loading) {
  return {
    type: SET_BILLING_LOADING,
    payload: {
      loading: loading,
    },
  };
}

export function setTimeAdjustLoadingAction(timeAdjustLoading) {
  return {
    type: SET_TIME_ADJUST_LOADING,
    payload: {
      timeAdjustLoading: timeAdjustLoading,
    },
  };
}

export function setExportLoadingAction(exportLoading) {
  return {
    type: SET_EXPORT_LOADING,
    payload: {
      exportLoading: exportLoading,
    },
  };
}

export function setBillingPatientsAction(billingPatients) {
  return {
    type: SET_BILLING_PATIENTS,
    payload: {
      billingPatients: billingPatients,
    },
  };
}

export function setNumBillingPatientsAction(numberOfFilteredPatients) {
  return {
    type: SET_NUM_BILLING_PATIENTS,
    payload: {
      numberOfFilteredPatients: numberOfFilteredPatients,
    },
  };
}

export function setCurrentBillingPatientAction(currentBillingPatient) {
  return {
    type: SET_CURRENT_BILLING_PATIENT,
    payload: {
      currentBillingPatient: currentBillingPatient,
    },
  };
}

export function setCurrentDateStampAction(currentDateStamp) {
  return {
    type: SET_CURRENT_DATE_STAMP,
    payload: {
      currentDateStamp: currentDateStamp,
    },
  };
}
export function setSelectedBillingTypeAction(selectedBillingType) {
  return {
    type: SET_SELECTED_BILLING_TYPE,
    payload: {
      selectedBillingType: selectedBillingType,
    },
  };
}

export function setSelectedCptCodeAction(selectedCptCode) {
  return {
    type: SET_SELECTED_CPT_CODE,
    payload: {
      selectedCptCode: selectedCptCode,
    },
  };
}

export function setSortedByAction(sortedBy) {
  return {
    type: SET_SORTED_BY,
    payload: {
      sortedBy: sortedBy,
    },
  };
}

export function setTableScrollPositionAction(tableScrollPosition) {
  return {
    type: SET_BILLING_TABLE_SCROLL_POSITION,
    payload: {
      tableScrollPosition: tableScrollPosition,
    },
  };
}

export function setCurrPageAction(currPage) {
  return {
    type: SET_CURR_BILLING_PAGE,
    payload: {
      currPage: currPage,
    },
  };
}
