import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import {
  setBillingLoadingAction,
  setBillingPatientsAction,
  setCurrentBillingPatientAction,
  setExportLoadingAction,
  setNumBillingPatientsAction,
  setTimeAdjustLoadingAction,
} from "./actions";
import { setNotificationAction } from "../user/actions";

export const openConversationEvent = async (patientId, campaignType) => {
  axios
    .post(`${BASE_URL}/track/event`, {
      tracking_event_type_id: 1,
      patientId: patientId,
      campaignType: campaignType === "CCM" ? "Outreach" : campaignType,
    })
    .then((response) => {
      //
    })
    .catch((e) => {
      console.error(e);
    });
};

export const trackTime = async (seconds) => {
  axios
    .post(`${BASE_URL}/track/time-log`, {
      seconds: seconds,
    })
    .then((response) => {
      //
    })
    .catch((e) => {
      console.error(e);
    });
};

export const getBillingPatients =
  (
    dateStamp,
    rows,
    search = "",
    campaignType = "all",
    cptCode = "all",
    sortColumn = null,
    onSuccess = () => {}
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/billing/patients`, {
        "month-stamp": dateStamp, // "yyyy-mm-01"
        offset: 0,
        limit: rows,
        searchQuery: search,
        campaignType: campaignType === "all" ? null : campaignType === "CCM" ? "Outreach" : campaignType,
        cptCode: cptCode === "all" ? null : cptCode,
        sortColumn: sortColumn,
      })
      .then((response) => {
        dispatch(setNumBillingPatientsAction(response.data.totalCount));

        const results = response.data.results.map(item => ({
          ...item,
          billingType: item.billingType === "Outreach" ? "CCM" : item.billingType === "Outreach_PCM" ? "PCM" : item.billingType === "Outreach_BHI" ? "BHI" : item.billingType
        }));

        dispatch(setBillingPatientsAction(results));
        dispatch(setBillingLoadingAction(false));
        onSuccess();
      })
      .catch((e) => {
        console.error(e);
      });
  };

export const getBillingPatient =
  (patientId, dateStamp, campaignType) => (dispatch) => {
    axios
      .post(`${BASE_URL}/billing/patient`, {
        patientId: patientId,
        "month-stamp": dateStamp, // "yyyy-mm-01"
        campaignType: campaignType === "CCM" ? "Outreach" : campaignType,
      })
      .then((response) => {
        dispatch(setCurrentBillingPatientAction(response.data));
      })
      .catch((e) => {
        console.error(e);
      });
  };

export const adjustBillingPatient =
  (monthlyBillId, totalSecondsDiff, staff, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setTimeAdjustLoadingAction(true));
    axios
      .post(`${BASE_URL}/billing/patient-adjust`, {
        monthlyBillId: monthlyBillId,
        totalSecondsAdjusted: totalSecondsDiff,
        staff: staff,
      })
      .then((response) => {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully adjusted time",
            desc: "Time has been successfully adjusted",
          })
        );
        onSuccess();
        dispatch(setTimeAdjustLoadingAction(false));
      })
      .catch((e) => {
        console.error(e);
      });
  };

export const exportBillingData =
  (dateStamp, rows = 1000, search = "") =>
  (dispatch) => {
    dispatch(setExportLoadingAction(true));
    const token = sessionStorage.token || localStorage.token;
    axios
      .get(
        `${BASE_URL}/billing/export?token=${token}&month-stamp=${dateStamp}&searchQuery=${search}&limit=${rows}&offset=0`
      )
      .then((response) => {
        const csvContent = "data:text/csv;charset=utf-8," + response.data;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `billing-${dateStamp}.csv`);
        document.body.appendChild(link);
        link.click();
        dispatch(setExportLoadingAction(false));
      })
      .catch((e) => {
        console.error(e);
      });
  };
