import dayjs from "dayjs";

export const Pages = {
  MESSAGING: "/messaging",
  PATIENTS: "/patients",
  PATIENT_PROFILE: "/patients/:id",
  BILLING_METRICS: "/billing-metrics",
  CAMPAIGNS: "/campaigns",
  SETTINGS: "/settings",
  LOGIN: "/login",
  SIGNUP: "/sign-up/:token",
  FORGOT_PASS: "/forgot-password",
  RESET_PASS: "/reset-password/:token",
  CHECK_EMAIL: "/check-email",
  CREATE_PASS: "/create-password",
  RESET_PASS_CONFIRM: "/reset-password-confirmation",
  METRICS: "/metrics",
};

export const TabsOrientation = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const CampaignTabs = {
  ENROLL: "Enroll",
  UPCOMING: "Calendar",
  DETAILS: "Details",
  CONTROLS: "Controls",
};

export const availableTags = [
  { name: "Name", value: "{{NAME}}" },
  { name: "Doctor", value: "{{DOCTOR}}" },
  { name: "Organization", value: "{{ORGANIZATION}}" },
];

export const InboxTabsInit = [
  { name: "active", conversations: 0 },
  { name: "review", conversations: 0 },
  { name: "complete", conversations: 0 },
];

export const passwordValidationRegEx =
  /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/;

export const twoConditionsRegex = /\S+\s*\n2\.\s*\S+/m;

export const genderOptions = [
  { value: "", text: "-" },
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "transgender male", text: "Transgender Male" },
  { value: "transgender female", text: "Transgender Female" },
  { value: "non-binary", text: "Non-binary" },
  { value: "notListed", text: "Not Listed: Please specify" },
];

export const sexOptions = [
  { value: "", text: "-" },
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "notListed", text: "Not Listed: Please specify" },
];

export const pronounsOptions = [
  { value: "", text: "-" },
  { value: "he/him/his", text: "He/Him/His" },
  { value: "she/her/hers", text: "She/Her/Hers" },
  { value: "they/them/theirs", text: "They/Them/Theirs" },
];

export const genders = [
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "other", text: "Other" },
];

export const InsuranceOptions = [
  { value: "medicare", text: "Medicare" },
  { value: "medicaid", text: "Medicaid" },
  { value: "private", text: "Private" },
  { value: "other", text: "Other" },
  { value: "none", text: "None" },
];

// launch campaign
export const frequency = [
  { text: "Once", value: "once" },
  { text: "Daily", value: "daily" },
  { text: "Every 3 days", value: "every_3_days" },
  { text: "Weekly", value: "weekly" },
  { text: "Biweekly", value: "bi_weekly" },
  { text: "Monthly", value: "monthly" },
  { text: "Every 2 months", value: "every_2_months" },
  { text: "Every 3 months", value: "every_3_months" },
  { text: "Every 6 months", value: "every_6_months" },
  { text: "Yearly", value: "yearly" },
];

export const frequencyCCM = [
  { text: "Weekly", value: "weekly" },
  { text: "Biweekly", value: "bi_weekly" },
  { text: "Monthly", value: "monthly" },
];

export const chronicPostDischargePlaceholder = `1. Heart failure with reduced ejection fraction - Week 3 post discharge
2. Aspiration pneumonia (developed in the same admission as above)`;
export const chronicCcmPlaceholder = `1. insulin-dependent Type 2 diabetes
2. severe GERD`;
export const goalsPostDischargePlaceholder =
  "Assess adherence to post-discharge medications, Exercise 15 minutes a day, less than 2g/day salt diet, avoid alcohol and stay upright when eating";
export const goalsCcmPlaceholder =
  "Exercise 20 minutes a day, measure blood sugar daily, 4 grams sodium diet, reduce alcohol consumption to below 5 drinks a week.";
export const medicalPostDischargePlaceholder =
  "Stenting for double vessel coronary artery disease was performed 5 weeks ago, 40 pack year history smoking";
export const medicalCcmPlaceholder =
  "10 years esophageal ulcers, diabetic foot ulcers, obesity with bmi 35";

export const shortcutsItems = [
  {
    label: "Now",
    getValue: () => {
      return dayjs();
    },
  },
  {
    label: "Tomorrow",
    getValue: () => {
      return dayjs().add(1, "day");
    },
  },
  {
    label: "Next week",
    getValue: () => {
      return dayjs().add(1, "week");
    },
  },
  {
    label: "Next month",
    getValue: () => {
      return dayjs(new Date()).add(1, "month");
    },
  },
];

// settings
export const occupations = [
  { text: "Physician", value: "Physician" },
  { text: "Registered Nurse", value: "Registered Nurse" },
  { text: "Nurse Practitioner", value: "Nurse Practitioner" },
  { text: "Medical Assistant", value: "Medical Assistant" },
  { text: "Occupational Therapist", value: "Occupational Therapist" },
  { text: "Physical Therapist", value: "Physical Therapist" },
  { text: "Physician Assistant", value: "Physician Assistant" },
  { text: "Social Worker", value: "Social Worker" },
];

//conversations
export const convPerPage = 30;

export const inboxes = {
  REVIEW: "review",
  ACTIVE: "active",
  COMPLETE: "complete",
};

export const statusReviewOptions = [
  { value: "urgent", text: "Urgent" },
  { value: "clinical", text: "Clinical" },
  { value: "administrative", text: "Administrative" },
  { value: "other", text: "Other" },
];

export const statusActiveOptions = [
  { value: "inProgress", text: "In Progress" },
  { value: "noResponse", text: "No Response" },
];

export const statusCompleteOptions = [
  { value: "all", text: "Finalized" },
  { value: "paused", text: "Paused" },
  { value: "noResponse", text: "No Response" },
];

export const timeOptions = [
  { value: "all", text: "All" },
  { value: 1, text: "Today" },
  { value: 7, text: "Last 7 days" },
  { value: 30, text: "Last 30 days" },
];

export const flags = [
  { value: "grey", color: "#848488" },
  { value: "red", color: "#E24636" },
  { value: "orange", color: "#FD9423" },
  { value: "purple", color: "#8F5AFF" },
  { value: "blue", color: "#4CBFDE" },
  { value: "yellow", color: "#F4C01D" },
  { value: "green", color: "#12B76A" },
];

export const commonCampaigns = [
  { value: "patientInitiated", text: "Patient Initiated" },
  // { value: "staffInitiated", text: "Staff Initiated" },
];

export const filtersClear = {
  campaigns: [],
  status: {
    [inboxes.REVIEW]: [],
    [inboxes.ACTIVE]: [],
    [inboxes.COMPLETE]: [],
  },
  time: "all",
  other: {
    unread: false,
    staffEngaging: false,
    flag: [],
  },
};

const getOptionValues = (options) => {
  return options.map((item) => item.value);
};

export const getFiltersInit = (campaignOptions) => {
  return {
    campaigns: getOptionValues(campaignOptions),
    status: {
      [inboxes.REVIEW]: getOptionValues(statusReviewOptions),
      [inboxes.ACTIVE]: getOptionValues(statusActiveOptions),
      [inboxes.COMPLETE]: getOptionValues(statusCompleteOptions),
    },
    time: "all",
    other: {
      unread: false,
      staffEngaging: false,
      flag: [],
    },
  };
};
