import React, { useEffect, useState } from "react";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { getCountry } from "../../store/user/thunks";
import { useDispatch, useSelector } from "react-redux";
import ErrorRoundExclamation from "./ErrorRoundExclamation";
import "react-phone-number-input/style.css";

const CountrySelectComponent = ({
  value,
  onChange,
  options,
  iconComponent,
  disabled,
  ...props
}) => {
  return (
    <select
      {...props}
      disabled={disabled}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
      className={`text-base text-gray-900 font-normal w-15 outline-none py-1 px-2 
        ${disabled ? "bg-gray-150" : "bg-white"}`}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

const PhoneInput = ({
  control,
  name,
  label,
  placeholder = "Enter phone number",
  defaultCountry = "US",
  countries = ["US"],
  required,
  withoutAsterisk,
  error,
  className,
  disabled,
  ...props
}) => {
  const { country } = useSelector((state) => state.user);
  const [currentCountry, setCurrentCountry] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  useEffect(() => {
    if (countries.includes(country)) {
      setCurrentCountry(country);
    }
  }, [country]);

  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <div className="relative">
        <PhoneInputWithCountry
          name={name}
          control={control}
          rules={{ required: required, minLength: 9 }}
          defaultCountry={currentCountry || defaultCountry}
          placeholder={placeholder}
          countries={countries}
          international
          limitMaxLength
          addInternationalOption={false}
          countrySelectComponent={CountrySelectComponent}
          countryCallingCodeEditable={false}
          className={`rounded-lg border h-10 py-2 pl-1 pr-3 space-x-1 outline-none shadow-sm shadow-gray-900/5
            ${error ? "border-red-400" : "border-gray-300"}
            ${disabled ? "bg-gray-150" : ""}`}
          disabled={disabled}
          {...props}
        />
        {error && (
          <ErrorRoundExclamation className="absolute top-1/2 -translate-y-1/2 right-4" />
        )}
      </div>
      {error && error.message && (
        <p className="text-red-400 font-normal mt-1.5">{error.message}</p>
      )}
    </div>
  );
};

export default PhoneInput;
