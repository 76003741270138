import {
  LOGOUT,
  SET_BILLING_LOADING,
  SET_BILLING_PATIENTS,
  SET_BILLING_TABLE_SCROLL_POSITION,
  SET_CURR_BILLING_PAGE,
  SET_CURRENT_BILLING_PATIENT,
  SET_CURRENT_DATE_STAMP,
  SET_EXPORT_LOADING,
  SET_NUM_BILLING_PATIENTS,
  SET_SELECTED_BILLING_TYPE,
  SET_SELECTED_CPT_CODE,
  SET_SORTED_BY,
  SET_TIME_ADJUST_LOADING,
} from "../types";

const init = {
  billingPatients: null,
  numberOfFilteredPatients: 0,
  currentBillingPatient: null,
  currentDateStamp: sessionStorage.trackingDateStamp || null,
  selectedBillingType: sessionStorage.trackingBillingType || "all",
  selectedCptCode: sessionStorage.trackingCptCode || "all",
  sortedBy: sessionStorage.trackingSortedBy || null,
  rowsPerPage: 50,
  currPage: 1,
  loading: false,
  timeAdjustLoading: false,
  exportLoading: false,
  tableScrollPosition: 0,
};

export default function billingMetricsReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      sessionStorage.removeItem("trackingDateStamp");
      sessionStorage.removeItem("trackingBillingType");
      sessionStorage.removeItem("trackingCptCode");
      sessionStorage.removeItem("trackingSortedBy");
      return init;
    case SET_BILLING_PATIENTS:
      return { ...state, billingPatients: payload.billingPatients };
    case SET_NUM_BILLING_PATIENTS:
      return {
        ...state,
        numberOfFilteredPatients: payload.numberOfFilteredPatients,
      };
    case SET_CURRENT_BILLING_PATIENT:
      return { ...state, currentBillingPatient: payload.currentBillingPatient };
    case SET_CURRENT_DATE_STAMP:
      sessionStorage.setItem("trackingDateStamp", payload.currentDateStamp);
      return { ...state, currentDateStamp: payload.currentDateStamp };
    case SET_SELECTED_BILLING_TYPE:
      sessionStorage.setItem(
        "trackingBillingType",
        payload.selectedBillingType
      );
      return { ...state, selectedBillingType: payload.selectedBillingType };
    case SET_SELECTED_CPT_CODE:
      sessionStorage.setItem("trackingCptCode", payload.selectedCptCode);
      return { ...state, selectedCptCode: payload.selectedCptCode };
    case SET_SORTED_BY:
      sessionStorage.setItem("trackingSortedBy", payload.sortedBy);
      return { ...state, sortedBy: payload.sortedBy };
    case SET_BILLING_LOADING:
      return { ...state, loading: payload.loading };
    case SET_TIME_ADJUST_LOADING:
      return { ...state, timeAdjustLoading: payload.timeAdjustLoading };
    case SET_EXPORT_LOADING:
      return { ...state, exportLoading: payload.exportLoading };
    case SET_BILLING_TABLE_SCROLL_POSITION:
      return { ...state, tableScrollPosition: payload.tableScrollPosition };
    case SET_CURR_BILLING_PAGE:
      return { ...state, currPage: payload.currPage };
    default:
      return state;
  }
}
