import React, { useEffect, useRef, useState } from "react";
import { inboxes } from "../../../helpers/constants";

const InboxTabsBorder = ({
  tabs,
  activeTab,
  setActiveTab,
  className,
  tabClassName,
}) => {
  const tabRefs = useRef([]);
  const [translate, setTranslate] = useState(0);
  const [tabSizes, setTabSizes] = useState(null);
  const [transition, setTransition] = useState("");
  const tabSizesRef = useRef(tabSizes);
  const activeTabRef = useRef(activeTab);

  const handleResize = () => {
    updateTabSizes(tabRefs.current);
    updateTranslate(tabSizesRef.current, activeTabRef.current);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setTransition("transition-all");
    }, 100);
    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(id);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    tabSizesRef.current = tabSizes;
  }, [tabSizes]);

  useEffect(() => {
    updateTabSizes(tabRefs.current);
  }, [tabRefs.current]);

  useEffect(() => {
    updateTranslate(tabSizes, activeTab);
  }, [activeTab, tabSizes]);

  const updateTabSizes = (tabs) => {
    tabs.forEach((ref, index) =>
      setTabSizes((tabSizes) => ({
        ...tabSizes,
        [index]: ref.offsetWidth,
      }))
    );
  };

  const updateTranslate = (sizes, activeTab) => {
    const activeIndex = tabs.findIndex((tab) => tab.name === activeTab);
    let shift = 0;
    if (sizes) {
      shift = Object.values(sizes)
        .slice(0, activeIndex)
        .reduce((a, b) => a + b, 0);
    }
    setTranslate(shift);
  };

  const getTab = (tab, index) => {
    return (
      <li
        key={tab.name}
        ref={(el) => (tabRefs.current[index] = el)}
        onClick={() => {
          setActiveTab(tab.name);
          activeTabRef.current = tab.name;
        }}
        className={`cursor-pointer font-semibold relative transition-all pb-2.5 px-2 text-sm select-none
          flex items-center justify-center space-x-1 capitalize ${tabClassName}
          ${activeTab === tab.name ? `text-primary-blue` : "text-slate-400"} `}
      >
        <p>{tab.name}</p>
        {tab.name !== inboxes.COMPLETE && (
          <span
            className={`h-5 min-w-[20px] text-white text-xs font-medium rounded-full flex items-center justify-center px-1.5
              ${activeTab === tab.name ? `bg-primary-blue` : "bg-slate-400"}
              `}
          >
            {tab.conversations}
          </span>
        )}
      </li>
    );
  };

  return (
    <ul className={`flex w-fit min-w-full md:w-full relative ${className}`}>
      <div
        aria-hidden="true"
        style={{
          transform: `translateX(${translate}px)`,
          width: tabSizes
            ? tabSizes[tabs.findIndex((tab) => tab.name === activeTab)] + "px"
            : "33px",
          height: "2px",
        }}
        className={`absolute rounded-lg z-0 bg-primary-blue -bottom-0.5 left-0 ${transition}`}
      />
      {tabs.map((tab, index) => getTab(tab, index))}
    </ul>
  );
};

export default InboxTabsBorder;
