import React from "react";

const Loader = ({ className, size = 8, borderWidth = 4 }) => {
  return (
    <div
      className={`inline-block animate-spin rounded-full border-solid border-primary-blue border-r-transparent 
        align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] border-${borderWidth} h-${size} w-${size} 
        ${className}`}
      role="status"
    />
  );
};

export default Loader;
