import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  generateNote,
  saveEncounterNote,
} from "../../store/conversation/thunks";
import { setNotificationAction } from "../../store/user/actions";
import {
  addPatientDetails,
  generatePdf,
} from "../../helpers/generatePdf/generateHtmlPdf";
import { convertToHtmlList, copyText } from "../../helpers/helpers";
import PreviousRecording from "./VoiceRecord/PreviousRecording";
import Button from "./Button";
import Modal from "./Modal";
import TextEditor from "./TextEditor";
import TabsBorder from "./TabsBorder";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as NoteIcon } from "../../assets/icons/note.svg";
import { ReactComponent as GenerateIcon } from "../../assets/icons/generating.svg";
import { ReactComponent as ConversationIcon } from "../../assets/icons/conversation.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/arrow-down-circle.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-pencil-square.svg";
import { ReactComponent as MicIcon } from "../../assets/icons/microphone-scribe.svg";
import { ReactComponent as OngoingIcon } from "../../assets/icons/in-progress.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";

const ConversationTabs = {
  TRANSCRIPT: "Chat",
  SCRIBE: "Scribe",
};

const NoteCard = ({ note, patient, onSaveNote, mobile }) => {
  const { TRANSCRIPT, SCRIBE } = ConversationTabs;
  const [openEditor, setOpenEditor] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editedNote, setEditedNote] = useState(note.note);
  const [openConversation, setOpenConversation] = useState(false);
  const [conversationActiveTab, setConversationActiveTab] = useState(
    TRANSCRIPT
    // note.type === "Scribe" ? SCRIBE : TRANSCRIPT
  );
  const dispatch = useDispatch();
  const summaryRef = useRef(null);

  useEffect(() => {
    if (note.summary && summaryRef.current) {
      summaryRef.current.innerHTML = convertToHtmlList(note.summary);
    }
  }, [note.summary, summaryRef]);

  useEffect(() => {
    if (note.type === SCRIBE) {
      setConversationActiveTab(SCRIBE);
    } else {
      setConversationActiveTab(TRANSCRIPT);
    }
  }, [note.type]);

  useEffect(() => {
    if (note.voice_conversations.length === 0 && note.type !== SCRIBE) {
      setConversationActiveTab(TRANSCRIPT);
    }
  }, [note.voice_conversations]);

  const getStartDate = (date) => {
    const dateObj = new Date(date.replace(/-/g, "/") + " GMT+0");
    const monthDay = dateObj.toLocaleString("en-us", {
      month: "short",
      day: "2-digit",
    });
    const year = dateObj.getFullYear();
    return `${monthDay} ${year}`;
  };

  const getDateTime = (date) => {
    const dateObj = new Date(date.replace(/-/g, "/") + " GMT+0");
    return dateObj.toLocaleString("en-us", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const getDate = (date) => {
    const dateObj = new Date(date.replace(/-/g, "/") + " GMT+0");
    return dateObj.toLocaleString("en-us", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
  };

  const getTime = (date) => {
    const dateObj = new Date(date.replace(/-/g, "/") + " GMT+0");
    return dateObj.toLocaleString("en-us", {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const combineMessages = () => {
    return note.conversation.messages
      .map((message) => {
        let sender = "";
        if (message.is_automatic === 1) {
          sender = "Quadrant AI";
        } else if (message.from === "doctor") {
          sender =
            "Dr. " + message.sender.first_name + " " + message.sender.last_name;
        } else if (message.from === "patient") {
          sender =
            (patient.preffered_name
              ? patient.preffered_name
              : patient.first_name) +
            " " +
            patient.last_name;
        }
        return `<div style="padding: 0; margin: 0; display: block">
          <div style="width: 1px; height: 1px"></div>
          <div style="display: flex; width: 100%; flex: none; justify-content: space-between; padding: 0; margin: 0">
            <p style="font-weight: 600; padding: 0; margin: 0">${sender}:</p> 
            <p style="font-size: 12px; padding: 0; margin: 0">
              ${getDateTime(message.send_at)}
            </p>
          </div>
          <p style="padding: 0; margin: 0">${message.message}</p>
        </div>`;
      })
      .join("<br>");
  };

  const getMessagesPreview = () => {
    let currentDate = null;

    return note.conversation.messages.map((message, index) => {
      let sender = "";
      if (message.is_automatic === 1) {
        sender = "Quadrant AI";
      } else if (message.from === "doctor") {
        sender =
          "Dr. " + message.sender.first_name + " " + message.sender.last_name;
      } else if (message.from === "patient") {
        sender =
          (patient.preffered_name
            ? patient.preffered_name
            : patient.first_name) +
          " " +
          patient.last_name;
      }

      const messageDate = getDate(message.send_at);
      const displayDate =
        currentDate !== messageDate ? (
          <div className="flex items-center space-x-4 mb-6">
            <p className="font-medium text-xs text-zinc-450">{messageDate}</p>
            <div className="bg-gray-150 h-0.5 w-full" />
          </div>
        ) : null;
      currentDate = messageDate;

      return (
        <div key={index} className="w-full text-sm">
          {displayDate}
          <div className="flex space-x-3">
            <div className="w-32 flex-none space-y-1">
              <p className="font-semibold truncate">{sender}</p>
              <p className="font-medium text-xs text-zinc-450">
                {getTime(message.send_at)}
              </p>
            </div>
            <p className="whitespace-pre-wrap">{message.message}</p>
          </div>
        </div>
      );
    });
  };

  const handleSaveNote = () => {
    dispatch(
      saveEncounterNote(
        note.conversation_history_id,
        editedNote,
        null,
        null,
        null,
        onSaveNote
      )
    );
  };

  const handleCopyNote = () => {
    copyText(note.note, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied note content",
          desc: "Note content has been successfully copied to the clipboard",
        })
      )
    );
  };

  const onDeleteScribeRecording = () => {
    if (note.voice_conversations.length === 1 && note.type === SCRIBE) {
      setOpenConversation(false);
    }
    onSaveNote();
  };

  return (
    <div
      className={`border border-gray-300 rounded-xl p-5 relative 
        ${note.conversation?.messages.length > 0 || note.note ? "" : "hidden"}`}
    >
      <div className="absolute left-0 top-5 w-0.5 h-6 bg-primary-blue" />
      <div className="flex items-center justify-between space-x-2 h-7 mb-1">
        <p className="text-base lg:text-xl font-semibold">{note.name}</p>
        <p className="text-sm text-right font-medium">
          {getStartDate(note.started_at)}
        </p>
      </div>
      <p className="text-sm text-right font-medium text-zinc-450 mb-5">
        {getTime(note.started_at)}
      </p>

      <div className="flex gap-2 text-sm font-semibold justify-between">
        <div className="flex gap-2">
          {note.status === "active" && (
            <div
              className={`bg-primary-blue/10 text-primary-blue rounded-full flex items-center justify-center
                h-10 w-10 text-sm whitespace-nowrap 
                ${!mobile ? "md:px-3 md:w-fit" : ""}`}
            >
              <OngoingIcon width="22" height="22" stroke="#2970FF" />
              {!mobile && <p className="ml-1 hidden md:flex">In progress</p>}
            </div>
          )}
          {note.voice_conversations.length > 0 && (
            <div
              className={`bg-system-green/10 text-system-green rounded-full flex items-center justify-center
                h-10 w-10 text-sm ${!mobile ? "md:px-3 md:w-fit" : ""}`}
            >
              <MicIcon width="22" height="22" stroke="#03C366" />
              {!mobile && <p className="ml-1 hidden md:flex">Scribe</p>}
            </div>
          )}
        </div>

        <div className="ml-auto flex gap-2 text-sm font-semibold">
          {note.status === "active" && !note.note ? (
            <Button
              variant="gray-blue"
              size={mobile ? "unset" : "medium"}
              className={mobile ? "w-10 h-10" : ""}
              onClick={() => dispatch(generateNote(note.conversation_id))}
            >
              <GenerateIcon width="22" height="22" stroke="#2970FF" />
              {!mobile && <p className="ml-2 hidden md:flex">Generate Note</p>}
            </Button>
          ) : (
            <Button
              variant="gray-filled"
              size={mobile ? "unset" : "medium"}
              className={mobile ? "w-10 h-10" : ""}
              onClick={() => setOpenEditor(true)}
              disabled={!note.note}
            >
              <NoteIcon width="22" height="22" stroke="#121212" />
              {!mobile && <p className="ml-2 hidden md:flex">Note</p>}
            </Button>
          )}

          {note.conversation?.messages.length > 0 && (
            <Button
              variant="gray-filled"
              size={mobile ? "unset" : "medium"}
              className={mobile ? "w-10 h-10" : ""}
              onClick={() => setOpenConversation(true)}
              disabled={
                (!note.conversation ||
                  note.conversation?.messages.length === 0) &&
                (!note.voice_conversations ||
                  note.voice_conversations.length === 0)
              }
            >
              <ConversationIcon width="22" height="22" stroke="#121212" />
              {!mobile && <p className="ml-2 hidden md:flex">Conversation</p>}
            </Button>
          )}
        </div>
      </div>
      {note.summary && (
        <div className="mt-2 border rounded-xl p-2.5 pr-1 text-sm relative flex max-h-52 overflow-hidden">
          <div className="flex min-h-0 overflow-y-auto scrollbar px-1.5 whitespace-pre-wrap">
            <p ref={summaryRef} />
          </div>
        </div>
      )}

      {openEditor && (
        <Modal
          onClickAway={() => {
            setOpenEditor(false);
            setEditNote(false);
          }}
        >
          <div className="w-213 h-fit max-h-full gap-4 text-sm overflow-hidden grid grid-rows-tab-layout">
            <div className="flex items-center justify-between">
              <div className="space-y-1">
                <p className="text-xs font-semibold text-zinc-450">Note</p>
                <p className="text-base font-semibold">{note.name}</p>
              </div>
              <div className="flex items-center space-x-6">
                <button
                  className="text-primary-blue text-xs"
                  onClick={handleCopyNote}
                >
                  <CopyIcon width="17" height="17" fill="#2970FF" />
                </button>
                {!note.signed && (
                  <button onClick={() => setEditNote(!editNote)}>
                    <EditIcon width="20" height="20" />
                  </button>
                )}
                <button
                  className="text-sm font-semibold text-primary-blue"
                  onClick={() =>
                    generatePdf(
                      `Note_${note.conversation_history_id}`,
                      addPatientDetails(patient, note.note),
                      false
                    )
                  }
                >
                  <DownloadIcon width="20" height="20" stroke="#2970FF" />
                </button>
                <button
                  onClick={() => {
                    setOpenEditor(false);
                    setEditNote(false);
                  }}
                >
                  <CloseIcon stroke="#737373" width="24" height="24" />
                </button>
              </div>
            </div>

            <div className="h-full grid grid-rows-note-layout gap-4 overflow-hidden">
              <TextEditor
                htmlMarkup={note.note}
                onChange={(htmlMarkup) => setEditedNote(htmlMarkup)}
                className="h-[65vh] max-h-full"
                disabled={!editNote}
                rerenderDeps={[]}
              />

              {editNote && (
                <Button
                  className="ml-auto w-full max-w-[144px] font-semibold text-sm lg:text-base whitespace-nowrap"
                  onClick={handleSaveNote}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </div>
        </Modal>
      )}

      {openConversation && (
        <Modal onClickAway={() => setOpenConversation(false)}>
          <div className="w-213 h-[80vh] max-h-full gap-6 text-sm overflow-hidden grid grid-rows-tab-layout">
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <p className="text-xs font-semibold text-zinc-450">
                    Conversation
                  </p>
                  <p className="text-base font-semibold">{note.name}</p>
                </div>
                <div className="flex items-center space-x-6">
                  {conversationActiveTab === TRANSCRIPT &&
                    note.conversation?.messages.length > 0 && (
                      <button
                        className="text-sm font-semibold text-primary-blue"
                        onClick={() =>
                          generatePdf(
                            `${note.name}_${note.conversation_history_id}`,
                            addPatientDetails(
                              patient,
                              `<p style="width: 1px; height: 14px"></p>` +
                                combineMessages()
                            ),
                            false
                          )
                        }
                      >
                        <DownloadIcon width="20" height="20" stroke="#2970FF" />
                      </button>
                    )}
                  <button onClick={() => setOpenConversation(false)}>
                    <CloseIcon stroke="#737373" width="24" height="24" />
                  </button>
                </div>
              </div>

              {note.type !== "Scribe" && (
                <TabsBorder
                  tabs={
                    // note.voice_conversations.length > 0
                    //   ? Object.values(ConversationTabs)
                    //   :
                    ["Chat"]
                  }
                  activeTab={conversationActiveTab}
                  setActiveTab={setConversationActiveTab}
                  activeTextColor="primary-blue"
                  className="text-sm border-b-2"
                />
              )}
            </div>

            <div className="h-full max-h-full overflow-y-hidden">
              <div className="h-full overflow-y-auto scrollbar pr-1 space-y-6">
                {conversationActiveTab === TRANSCRIPT &&
                  note.conversation?.messages.length > 0 &&
                  getMessagesPreview()}
                {conversationActiveTab === SCRIBE &&
                  note.voice_conversations.length > 0 && (
                    <div className="space-y-3">
                      {note.voice_conversations.map((record) => (
                        <PreviousRecording
                          key={record.voice_conversation_id}
                          record={record}
                          withTime
                          onDelete={onDeleteScribeRecording}
                        />
                      ))}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NoteCard;
