import React, { useEffect, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "@mui/material/utils";
import { searchPatients } from "../../store/patient/thunks";
import {
  setCurrPageAction,
  setSearchAction,
} from "../../store/patient/actions";
import {
  getFormattedPhoneNumber,
  getTagVariant,
  selectInputText,
} from "../../helpers/helpers";
import Tag from "../Basic/Tag";
import Loader from "../Basic/Loader";
import Button from "../Basic/Button";
import TableData from "./TableData";
import { ReactComponent as EmptyTable } from "../../assets/empty-conversation.svg";
import { ReactComponent as CloudIcon } from "../../assets/icons/upload-cloud.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-small.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-square.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

export const DateCell = ({ value }) => {
  return value ? (
    <p>
      {new Date(value.replace(/-/g, "/")).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })}
    </p>
  ) : (
    <></>
  );
};

export const PhoneCell = ({ value }) => {
  return value ? getFormattedPhoneNumber(value) : <></>;
};

export const TagCell = ({ value }) => {
  if (value && value.length > 0) {
    return (
      <div className="flex space-x-2">
        {value.map((tag) => (
          <Tag
            key={tag.tag_id}
            text={tag.tag}
            variant={getTagVariant(tag.tag)}
          />
        ))}
      </div>
    );
  } else return <></>;
};

const PatientsTable = ({
  columns,
  data,
  selectedPatients,
  setSelectedPatients,
  canselSelection,
  setCanselSelection,
  onEdit,
  onAdd,
  onImport,
  onDelete,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );
  const {
    patientsPage,
    numOfPatients,
    loader,
    searchValue,
    rowsPerPage,
    currPage,
  } = useSelector((state) => state.patient);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canselSelection) {
      toggleAllRowsSelected(false);
      setSelectedPatients([]);
      setCanselSelection(false);
    }
  }, [canselSelection]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        dispatch(searchPatients(searchValue, 1, rowsPerPage * currPage));
      }, 300),
    []
  );

  const handleSearchInput = (e) => {
    dispatch(setCurrPageAction(1));
    dispatch(setSearchAction(e.target.value));
    debouncedSearch(e.target.value);
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div className="min-h-0 h-full w-full">
      {(loader || patientsPage === null) && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
          <Loader />
        </div>
      )}
      {numOfPatients > 0 ? (
        <div className="h-full w-full overflow-hidden grid grid-rows-tab-layout">
          <div className="pb-5.5 px-4 md:px-6 grid md:grid-cols-2 gap-4">
            <div className="relative w-full md:max-w-lg">
              <input
                className="h-10 text-xs outline-none pl-10.5 pr-3.5 border w-full rounded-lg"
                placeholder="Search patient"
                value={searchValue}
                onClick={selectInputText}
                onChange={handleSearchInput}
              />
              <SearchIcon
                width="20"
                height="20"
                className="absolute top-2.5 left-3.5"
                stroke="#667085"
              />
            </div>
            <div className="flex items-center md:justify-end text-sm">
              {(selectedFlatRows.length > 0 || selectedPatients.length > 0) && (
                <Button
                  aria-label="Delete patients"
                  className="mr-3 w-10 h-10 flex-none hidden md:flex"
                  size="unset"
                  variant="red"
                  onClick={handleDelete}
                >
                  <TrashIcon width="18" height="18" />
                </Button>
              )}

              <div className="flex items-center">
                <Button
                  className="space-x-2 font-semibold mr-3"
                  variant="gray"
                  onClick={onImport}
                >
                  <CloudIcon width="20" height="20" />
                  <p>Import</p>
                </Button>
                <Button
                  className="space-x-2 font-semibold whitespace-nowrap"
                  onClick={onAdd}
                >
                  <PlusIcon stroke="white" width="12" height="12" />{" "}
                  <p>Add patient</p>
                </Button>
              </div>

              {(selectedFlatRows.length > 0 || selectedPatients.length > 0) && (
                <Button
                  aria-label="Delete patients"
                  className="ml-auto w-10 h-10 flex-none md:hidden"
                  size="unset"
                  variant="red"
                  onClick={handleDelete}
                >
                  <TrashIcon width="18" height="18" />
                </Button>
              )}
            </div>
          </div>

          {data.length > 0 ? (
            <TableData
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              onEdit={onEdit}
              onDelete={onDelete}
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              prepareRow={prepareRow}
              selectedPatients={selectedPatients}
            />
          ) : (
            !loader && (
              <div className="w-full h-full flex items-start border-t p-6 text-sm">
                No patients matching the given filters
              </div>
            )
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full border-b border-t space-y-10.5 py-8">
          <EmptyTable width="228" height="166" />
          <p className="text-sm md:text-xl text-center font-semibold">
            Please Add New Patient to start a message
          </p>
        </div>
      )}
    </div>
  );
};

export default PatientsTable;
