import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { importPatients } from "../../store/patient/thunks";
import Modal from "../Basic/Modal";
import Button from "../Basic/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-cloud.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";

const ImportModal = ({ onClose }) => {
  const { rowsPerPage, searchValue, currPage } = useSelector(
    (state) => state.patient
  );
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    accept: {
      "text/*": [".csv"],
    },
  });

  const onSave = () => {
    dispatch(importPatients(files[0], 1, rowsPerPage * currPage, searchValue));
    onClose();
  };

  const deleteFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const humanReadableFileSize = (size) => {
    let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "KB", "MB", "GB", "TB"][i]
    );
  };

  return (
    <Modal onClickAway={onClose}>
      <div className="w-128">
        <div className="flex items-center justify-between mb-2">
          <p className="text-base md:text-xl font-semibold">
            Import new patient
          </p>
          <button onClick={onClose}>
            <CloseIcon stroke="#737373" width="24" height="24" />
          </button>
        </div>
        <p className="text-xs md:text-sm text-neutral-500 mb-6">
          Upload a CSV to import new patients to your patient list
        </p>

        <div
          {...getRootProps()}
          className="rounded-xl border border-gray-200 p-4 text-sm font-normal text-slate-600
            flex flex-col items-center cursor-pointer"
        >
          <input {...getInputProps()} />
          <div
            className="h-10 w-10 bg-gray-100 border-[6px] border-gray-50 rounded-full
            flex items-center justify-center mb-3"
          >
            <UploadIcon width="16" height="16" stroke="#475467" />
          </div>
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              <span className="text-primary-blue font-semibold">
                Click to upload
              </span>{" "}
              or drag and drop CSV
            </p>
          )}
        </div>

        {files.length > 0 &&
          files.map((file) => (
            <div
              key={file.name}
              className="rounded-xl border border-gray-200 p-4 text-sm flex items-start space-x-4 mt-2"
            >
              <div
                className="w-8 h-8 rounded-full border-[5px] border-indigo-50 bg-blue-100 flex-none
                flex items-center justify-center"
              >
                <FileIcon width="16" height="16" />
              </div>
              <div className="flex items-start justify-between w-full">
                <div>
                  <p className="text-slate-700 font-medium">{file.name}</p>
                  <p className="text-slate-600 font-normal">
                    {humanReadableFileSize(file.size)}
                  </p>
                </div>
                <button onClick={() => deleteFile(file.name)}>
                  <TrashIcon width="20" height="20" />
                </button>
              </div>
            </div>
          ))}

        <Button className="w-full font-semibold mt-6" onClick={onSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default ImportModal;
