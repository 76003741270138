import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import {
  setActiveFiltersAction,
  setLoaderAction,
} from "../../../store/conversation/actions";
import { flags } from "../../../helpers/constants";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as FlagIcon } from "../../../assets/icons/flag-outlined.svg";
import { ReactComponent as FlagFilledIcon } from "../../../assets/icons/flag.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/close.svg";

const flagButtonStyles =
  "w-6 h-6 rounded flex items-center justify-center border";

const FilterFlags = ({
  filterName,
  arrow,
  openedDropdown,
  setOpenedDropdown,
}) => {
  const { activeFilters } = useSelector((state) => state.conversation);
  const dispatch = useDispatch();

  const isOpened = openedDropdown === filterName;

  const onApplyFilters = (filters) => {
    dispatch(setLoaderAction(true));
    dispatch(setActiveFiltersAction(filters));
  };

  const isFlagsSelected = () => {
    return activeFilters.other.flag.length > 0;
  };

  const isFilterApplied = () => {
    return isFlagsSelected();
  };

  const onResetFlags = () => {
    onApplyFilters({
      ...activeFilters,
      other: {
        ...activeFilters.other,
        flag: [],
      },
    });
  };

  const onClickFlag = (flag) => {
    if (activeFilters.other.flag.includes(flag.value)) {
      onApplyFilters({
        ...activeFilters,
        other: {
          ...activeFilters.other,
          flag: activeFilters.other.flag.filter((f) => f !== flag.value),
        },
      });
    } else {
      onApplyFilters({
        ...activeFilters,
        other: {
          ...activeFilters.other,
          flag: [...activeFilters.other.flag, flag.value],
        },
      });
    }
  };

  return (
    <div
      onClick={() => setOpenedDropdown(filterName)}
      className={`flex items-center justify-center rounded-full capitalize select-none cursor-pointer
        p-1.5`}
    >
      <div
        className={`text-xs leading-none font-medium flex-none relative
              ${isFilterApplied() ? "text-blue-1000" : "text-primary"}`}
      >
        <FlagIcon width="20" height="20" stroke="#3C3C47" />
        {isFilterApplied() && (
          <div className="absolute -top-0.5 -right-0.5 bg-primary-blue w-2.5 h-2.5 rounded-full" />
        )}
      </div>
      {arrow && (
        <ArrowDownIcon
          stroke={isFilterApplied() ? "#122C62" : "#121212"}
          className={`mt-0.5 ml-1 transition-all duration-300
                  ${isOpened ? "-rotate-180" : ""}`}
          width="10"
          height="6"
        />
      )}

      {isOpened && (
        <ClickAwayListener onClickAway={() => setOpenedDropdown("")}>
          <div
            className="absolute left-0 top-9 w-full bg-white rounded-2.5 shadow-md overflow-hidden
              leading-tight text-sm font-medium"
          >
            <div className="w-full flex items-center justify-between p-2.5">
              <button
                onClick={onResetFlags}
                className={`${flagButtonStyles} 
                        ${
                          !isFlagsSelected()
                            ? "border-primary-blue hover:border-primary-blue"
                            : "border-transparent hover:border-gray-200"
                        }`}
              >
                <CrossIcon width="14" height="14" />
              </button>
              {flags.map((flag) => (
                <button
                  onClick={() => onClickFlag(flag)}
                  key={flag.value}
                  className={`${flagButtonStyles} ${
                    isFlagsSelected() &&
                    activeFilters.other.flag.includes(flag.value)
                      ? "border-primary-blue hover:border-primary-blue"
                      : "border-transparent hover:border-gray-200"
                  }`}
                >
                  <div
                    className="w-4 h-4 rounded-full flex items-center justify-center"
                    style={{ backgroundColor: flag.color }}
                  >
                    <FlagFilledIcon width="11" height="10" />
                  </div>
                </button>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default FilterFlags;
