import React from "react";
import { frequency } from "../../helpers/constants";
import Button from "../Basic/Button";

const EnrollmentCard = ({ enrollment, onEdit, onPause, onDelete }) => {
  const getFrequencyLabel = () => {
    const frequencyItem = frequency.find(
      (fr) => fr.value === enrollment.frequency
    );
    return frequencyItem ? frequencyItem.text : enrollment.frequency;
  };

  return (
    <div className="border border-gray-300 rounded-xl p-5 relative">
      <div className="absolute left-0 top-5 w-0.5 h-6 bg-primary-blue" />
      <div className="flex items-start justify-between mb-5 space-x-2 ">
        <p className="text-base md:text-xl font-semibold">
          {enrollment.campaign.campaign}
        </p>

        <div className="flex flex-col items-end space-y-1 md:space-y-2 text-xs md:text-sm font-medium">
          <p className="text-gray-700">
            Start Date:{" "}
            {new Date(
              enrollment.next_checkin.replace(/-/g, "/") + " GMT+0"
            ).toLocaleDateString("en-us", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </p>
          <p className="text-gray-500 capitalize">
            Frequency: {getFrequencyLabel()}
          </p>
        </div>
      </div>

      <div className="flex space-x-3 text-base font-semibold justify-end">
        <Button
          variant="gray-filled-dark"
          onClick={(e) => {
            e.preventDefault();
            onEdit(e.currentTarget.parentElement.parentElement, enrollment);
          }}
        >
          Edit
        </Button>
        {/*<Button*/}
        {/*  variant="gray-filled-dark"*/}
        {/*  onClick={(e) => {*/}
        {/*    e.preventDefault();*/}
        {/*    onPause(enrollment);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Pause*/}
        {/*</Button>*/}
        <Button
          variant="red-filled-light"
          onClick={(e) => {
            e.preventDefault();
            onDelete(enrollment);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default EnrollmentCard;
