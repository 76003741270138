import React, { useEffect, useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.css";

const TextEditor = ({
  htmlMarkup,
  onChange,
  rerenderDeps,
  className,
  disabled,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  let editorClassName = `editor-class ${disabled ? "disabled" : ""}`;
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      editorClassName += " hidePlaceholder";
    }
  }

  useEffect(() => {
    if (htmlMarkup) {
      const hasBR = /<br\s*\/?>/i.test(htmlMarkup);
      if (!hasBR) htmlMarkup = htmlMarkup.replaceAll(/\n/g, "<br/>");
      htmlMarkup = htmlMarkup.replaceAll(
        /<br\s*\/?><br\s*\/?>/g,
        "</p><p></p><p>"
      );

      const blocksFromHtml = htmlToDraft(htmlMarkup);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    } else setEditorState(EditorState.createEmpty());
  }, [...rerenderDeps]);

  const onStateChange = (editorState) => {
    setEditorState(editorState);

    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let markup = draftToHtml(rawContent);

    markup = markup.replaceAll(/<p><\/p>/g, "<br/>");
    onChange(markup);
  };

  return (
    <div className={className}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onStateChange}
        placeholder="Enter your notes here..."
        wrapperClassName={disabled ? "wrapper-disabled-class" : "wrapper-class"}
        editorClassName={editorClassName}
        readOnly={disabled}
        toolbarHidden={disabled}
        toolbar={{
          inline: {
            options: ["bold", "italic", "underline"],
          },
          options: ["fontSize", "inline", "list"],
        }}
      />
    </div>
  );
};

export default TextEditor;
