import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Popover from "@mui/material/Popover";
import InfiniteScroll from "react-infinite-scroller";
import {
  setCurrPageAction,
  setTableScrollPositionAction,
} from "../../store/patient/actions";
import {
  searchPatients,
  pausePatient,
  unpausePatient,
} from "../../store/patient/thunks";
import LoaderDots from "../Basic/LoaderDots";
import TagsPopup from "./TagsPopup";

const TableData = ({
  getTableProps,
  headerGroups,
  onEdit,
  onDelete,
  getTableBodyProps,
  rows,
  prepareRow,
  selectedPatients,
}) => {
  const {
    numOfFilteredPatients,
    rowsPerPage,
    searchValue,
    currPage,
    tableScrollPosition,
  } = useSelector((state) => state.patient);
  const [showMore, setShowMore] = useState(false);
  const [currentRow, setCurrentRow] = useState("");
  const [currentPatient, setCurrentPatient] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(Boolean(anchorEl));
  const dispatch = useDispatch();
  const tableRef = useRef();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const ref = tableRef.current;
    if (ref) {
      ref.scroll(0, tableScrollPosition);
    }
    return () => {
      if (ref) {
        dispatch(setTableScrollPositionAction(ref.scrollTop));
      }
    };
  }, []);

  useEffect(() => {
    rows.forEach((row) => {
      if (
        selectedPatients &&
        selectedPatients.find(
          (patient) => patient.patient_id === row.original.patient_id
        )
      ) {
        row.toggleRowSelected(true);
      }
    });
  }, [rows]);

  useEffect(() => {
    setOpenPopup(Boolean(anchorEl));
  }, [anchorEl]);

  const onClickTags = (target, row) => {
    setAnchorEl(target);
    setCurrentPatient(row.original);
  };

  const onClosePopup = () => {
    setOpenPopup(false);
    setAnchorEl(null);
  };

  const onClickMore = (row) => {
    setShowMore(!showMore);
    setCurrentRow(row.original.patient_id);
  };

  const onNext = () => {
    dispatch(
      searchPatients(searchValue, 1, rowsPerPage * (currPage + 1), false, () =>
        dispatch(setCurrPageAction(currPage + 1))
      )
    );
  };

  const onPause = (patientId, paused) => {
    if (paused) {
      dispatch(
        unpausePatient(patientId, 1, rowsPerPage * currPage, searchValue)
      );
    } else {
      dispatch(pausePatient(patientId, 1, rowsPerPage * currPage, searchValue));
    }
  };

  return (
    <div
      className="overflow-auto h-full w-full border-t scrollbar"
      ref={tableRef}
    >
      <InfiniteScroll
        pageStart={0}
        loadMore={onNext}
        hasMore={rows.length < numOfFilteredPatients}
        useWindow={false}
        loader={
          <div key={0} className="w-full h-10 flex items-center justify-center">
            <LoaderDots />
          </div>
        }
      >
        <div className="min-w-fit w-full h-fit">
          <table {...getTableProps()} className="min-w-full">
            <thead className="bg-gray-50 sticky top-0 z-[9]">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps()}
                      key={index}
                      scope="col"
                      className="group h-11 text-left text-xs font-medium text-slate-600 uppercase p-0"
                    >
                      <div
                        className={`flex items-center justify-between border-b border-gray-200 h-full w-full pl-4 lg:pl-6 
                        ${
                          column.id === "selection"
                            ? "w-10 lg:pr-0"
                            : "pr-4 lg:pr-6"
                        }`}
                      >
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                  <th scope="col" className="group h-11 w-16 p-0">
                    <div className="h-full w-full border-b border-gray-200" />
                  </th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white">
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    key={i}
                    {...row.getRowProps()}
                    className={`border-b  border-gray-200 cursor-pointer 
                      ${row.isSelected ? "bg-gray-50" : "bg-white"}`}
                    onClick={() => {
                      navigate(`/patients/${row.original.patient_id}`);
                      sessionStorage.setItem("patientProfileTab", "Scribe");
                    }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className={`pl-4 lg:pl-6 h-14 whitespace-nowrap text-xs lg:text-sm text-gray-900 
                        ${
                          cell.column.id === "selection"
                            ? "w-10 lg:pr-0"
                            : "pr-4 lg:pr-6"
                        }`}
                        role="cell"
                        onClick={(e) => {
                          if (cell.column.id === "selection") {
                            e.stopPropagation();
                          }
                        }}
                      >
                        {cell.column.id === "tags" ? (
                          <div
                            className="flex w-fit cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onClickTags(e.currentTarget, row);
                            }}
                          >
                            {cell.render("Cell")}
                          </div>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}

                    <td className="px-2 h-14 relative" role="cell">
                      <button
                        aria-label="More"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          onClickMore(row);
                        }}
                        className="text-sm flex items-center justify-center
                          mx-auto font-semibold w-10 h-10"
                      >
                        ...
                      </button>

                      {showMore && currentRow === row.original.patient_id && (
                        <ClickAwayListener
                          onClickAway={() => setShowMore(false)}
                        >
                          <div
                            className={`absolute w-24 right-10 p-2 bg-white rounded-lg shadow-filter z-10 flex
                              flex-col items text-xs font-semibold space-y-1 ${
                                row.id === "0" ? "top-2" : "bottom-2"
                              }`}
                          >
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onEdit(row);
                              }}
                              className="text-primary-blue py-2 px-4 hover:bg-blue-50 rounded-lg text-left"
                            >
                              Edit
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onPause(
                                  row.original.patient_id,
                                  row.original.is_paused === 1
                                );
                              }}
                              className={`py-2 px-4 hover:bg-blue-50 rounded-lg text-left 
                                ${
                                  row.original.is_paused === 0
                                    ? "text-red-500"
                                    : "text-primary-blue"
                                }`}
                            >
                              {row.original.is_paused === 0
                                ? "Pause"
                                : "Unpause"}
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onDelete(row.original);
                              }}
                              className="text-red-500 py-2 px-4 hover:bg-blue-50 rounded-lg text-left"
                            >
                              Delete
                            </button>
                          </div>
                        </ClickAwayListener>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>

      <Popover
        open={openPopup}
        onClose={onClosePopup}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            width: "340px",
            boxShadow:
              "0px 32px 64px -12px rgba(16, 24, 40, 0.14), 0px 4px 4px rgba(0, 0, 0, 0.05), 10px 10px 20px rgba(0, 0, 0, 0.02)",
          },
        }}
      >
        <TagsPopup onClose={onClosePopup} patient={currentPatient} />
      </Popover>
    </div>
  );
};

export default TableData;
