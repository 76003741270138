import {
  SET_CONVERSATION_ID_GENERATING,
  SET_CONVERSATION_RECORDS,
  SET_OPEN_ATTACH_MODAL,
  SET_OPEN_ENCOUNTER_MODAL,
  SET_PATIENT_ID_GENERATING,
  SET_SAVED_AUDIO_ID,
  SET_TRANSCRIBING,
} from "../types";

export function setConversationRecordsAction(conversationRecords) {
  return {
    type: SET_CONVERSATION_RECORDS,
    payload: {
      conversationRecords: conversationRecords,
    },
  };
}

export function setTranscribingAction(transcribing) {
  return {
    type: SET_TRANSCRIBING,
    payload: {
      transcribing: transcribing,
    },
  };
}

export function setSavedAudioIdAction(savedAudioId) {
  return {
    type: SET_SAVED_AUDIO_ID,
    payload: {
      savedAudioId: savedAudioId,
    },
  };
}

export function setOpenAttachModalAction(openAttachModal) {
  return {
    type: SET_OPEN_ATTACH_MODAL,
    payload: {
      openAttachModal: openAttachModal,
    },
  };
}

export function setOpenEncounterModalAction(openEncounterModal) {
  return {
    type: SET_OPEN_ENCOUNTER_MODAL,
    payload: {
      openEncounterModal: openEncounterModal,
    },
  };
}

export function setConversationIdGeneratingAction(conversationIdGenerating) {
  return {
    type: SET_CONVERSATION_ID_GENERATING,
    payload: {
      conversationIdGenerating: conversationIdGenerating,
    },
  };
}

export function setPatientIdGeneratingAction(patientIdGenerating) {
  return {
    type: SET_PATIENT_ID_GENERATING,
    payload: {
      patientIdGenerating: patientIdGenerating,
    },
  };
}
