import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenAttachModalAction,
  setOpenEncounterModalAction,
} from "../../../store/voiceRecorder/actions";
import {
  discardRecord,
  saveRecordToEncounter,
} from "../../../store/voiceRecorder/thunks";
import RadioButton from "../RadioButton";
import Button from "../Button";
import Modal from "../Modal";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-left-simple.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

const ScribeEncounterModal = ({
  encounters,
  onGenerating = () => {},
  onEnd = () => {},
}) => {
  const { savedAudioId } = useSelector((state) => state.voiceRecorder);
  const [selectedEncounter, setSelectedEncounter] = useState(null);
  const dispatch = useDispatch();

  const getEndDate = (date) => {
    const dateObj = new Date(date.replace(/-/g, "/") + " GMT+0");
    const monthDay = dateObj.toLocaleString("en-us", {
      month: "short",
      day: "2-digit",
    });
    const year = dateObj.getFullYear();
    return `${monthDay} ${year}`;
  };

  const onSelectEncounter = (encounter) => {
    setSelectedEncounter(encounter.conversation_history_id);
  };

  const onAttach = () => {
    onGenerating();
    dispatch(saveRecordToEncounter(savedAudioId, selectedEncounter, onEnd));
    dispatch(setOpenEncounterModalAction(false));
  };

  const onBack = () => {
    dispatch(setOpenEncounterModalAction(false));
    dispatch(setOpenAttachModalAction(true));
  };

  const onClose = () => {
    dispatch(discardRecord(savedAudioId));
    dispatch(setOpenEncounterModalAction(false));
  };

  return (
    <Modal onClickAway={onClose}>
      <div className="w-134 font-semibold overflow-y-auto scrollbar pr-1">
        <div className="flex items-center justify-between space-x-3 mb-6">
          <button
            onClick={onBack}
            className="w-7 h-7 flex items-center justify-center"
          >
            <ArrowIcon stroke="#121212" width="8" height="15" />
          </button>
          <p className="text-xl">Choose an encounter</p>
          <button
            onClick={onClose}
            className="w-7 h-7 flex items-center justify-center"
          >
            <CloseIcon stroke="#121212" width="20" height="20" />
          </button>
        </div>

        <div className="space-y-3 mb-6">
          {encounters.map((encounter, index) => {
            if (index < 5) {
              return (
                <button
                  key={index}
                  className="flex items-center justify-between space-x-2 w-full border py-5 px-6 rounded-xl"
                  onClick={() => onSelectEncounter(encounter)}
                >
                  <div className="flex items-center space-x-3">
                    <RadioButton
                      checked={
                        encounter.conversation_history_id === selectedEncounter
                      }
                      onClick={() => onSelectEncounter(encounter)}
                    />
                    <p className="text-xl font-semibold">{encounter.name}</p>
                  </div>
                  <div className="flex flex-col items-end">
                    <p className="text-sm font-medium">
                      {getEndDate(encounter.finished_at)}
                    </p>
                    <p className="text-sm text-right font-medium text-zinc-450">
                      Encounter #{encounter.conversation_history_id}
                    </p>
                  </div>
                </button>
              );
            }
          })}
        </div>

        <Button
          className="w-full mb-4 h-12"
          onClick={onAttach}
          disabled={!selectedEncounter}
        >
          Attach recording
        </Button>
      </div>
    </Modal>
  );
};

export default ScribeEncounterModal;
