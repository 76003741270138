import React from "react";
import { useSelector } from "react-redux";
import { getFormattedTimeWithSeconds } from "../../../helpers/helpers";
import RecordEmpty from "./RecordEmpty";
import Button from "../Button";

const RecorderControls = ({ recorderState, handlers }) => {
  const { transcribing } = useSelector((state) => state.voiceRecorder);
  const { recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording } = handlers;

  return (
    <div className="flex items-center space-x-3 xl:space-x-6 text-sm xl:text-base">
      {initRecording ? (
        <>
          <Button
            variant="red-filled"
            disabled={recordingSeconds === 0}
            onClick={saveRecording}
            className="font-semibold w-32 xl:w-35 flex-none whitespace-nowrap"
          >
            Stop recording
          </Button>

          <RecordEmpty
            recording
            time={getFormattedTimeWithSeconds(recordingSeconds)}
          />
        </>
      ) : (
        <>
          <Button
            disabled={transcribing}
            onClick={startRecording}
            className="font-semibold w-32 xl:w-35 flex-none whitespace-nowrap"
          >
            Start recording
          </Button>
          <RecordEmpty />
        </>
      )}
    </div>
  );
};

export default RecorderControls;
