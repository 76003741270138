import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import { setNotificationAction } from "../user/actions";
import { setConversationRecordsAction, setTranscribingAction } from "./actions";

export const getConversationRecords =
  (conversationId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/voice/records/${conversationId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load recordings",
            })
          );
        } else {
          dispatch(
            setConversationRecordsAction(response.data.data.voice_conversations)
          );
          onSuccess();
        }
      });
  };

export const sendRecord =
  (conversationId, record, recordId, status, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setTranscribingAction(true));
    axios
      .post(
        `${BASE_URL}/voice/send-audio`,
        {
          file: record,
          conversation_id: conversationId,
          audio_id: recordId,
          audio_status: status,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save recording",
            })
          );
        } else {
          onSuccess();
        }
      })
      .finally(() => {
        dispatch(setTranscribingAction(false));
      });
  };

export const sendPatientRecord =
  (patientProfile, record, recordId, status) => (dispatch) => {
    dispatch(setTranscribingAction(true));
    axios
      .post(
        `${BASE_URL}/voice/send-audio`,
        {
          file: record,
          patient_id: patientProfile.info.patient_id,
          audio_id: recordId,
          audio_status: status,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save recording",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setTranscribingAction(false));
      });
  };

export const saveRecordIndependent =
  (recordId, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/voice/${recordId}/independent-scribe`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save recording",
            })
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully saved recording",
              desc: "Recording has been successfully saved",
            })
          );
        }
      })
      .finally(() => {
        onEnd();
      });
  };

export const saveRecordToEncounter =
  (recordId, conversationHistoryId, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/voice/${recordId}/${conversationHistoryId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save recording",
            })
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully saved recording",
              desc: "Recording has been successfully saved",
            })
          );
        }
      })
      .finally(() => {
        onEnd();
      });
  };

export const discardRecord = (recordId) => (dispatch) => {
  axios.post(`${BASE_URL}/voice/discard/${recordId}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to discard recording",
        })
      );
    } else {
      // dispatch(
      //   setNotificationAction({
      //     status: "success",
      //     title: "Successfully discarded recording",
      //     desc: "Recording has been successfully discarded",
      //   })
      // );
    }
  });
};

export const deleteRecord =
  (voiceConversationId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/voice/delete/${voiceConversationId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete recording",
            })
          );
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully deleted recording",
              desc: "Recording has been successfully deleted",
            })
          );
        }
      });
  };
