import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { deleteRecord } from "../../../store/voiceRecorder/thunks";
import ProfilePicture from "../ProfilePicture";
import DeleteModal from "../DeleteModal";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-accordion.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-square.svg";

const PreviousRecording = ({ record, withTime, onDelete }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const doctor = record.doctor;
  const createdDate = new Date(record.created_at.replace(/-/g, "/") + " GMT+0");
  const dispatch = useDispatch();

  const onDeleteRecording = () => {
    dispatch(deleteRecord(record.voice_conversation_id, onDelete));
  };

  return (
    <div>
      <Accordion
        disableGutters
        elevation={0}
        square
        defaultExpanded
        className="rounded-lg border border-gray-200 text-sm xl:text-base"
      >
        <AccordionSummary
          expandIcon={
            <ArrowIcon width="13" height="7" className="rotate-180" />
          }
        >
          <div className="flex items-center w-full">
            <ProfilePicture
              firstName={doctor.first_name}
              lastName={doctor.last_name}
              flagPadding={false}
            />
            <p className="text-normal font-semibold ml-3">
              Dr. {doctor.first_name} {doctor.last_name}
            </p>
            {withTime && (
              <p className="ml-auto mr-4">
                {createdDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}{" "}
                {createdDate.toLocaleDateString("en-US", {
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                })}
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="border-t pt-4 whitespace-pre-wrap">
            {record.transcription ? record.transcription : "No transcription"}
            <button
              className="block mt-4 ml-auto"
              onClick={() => setOpenDeleteModal(true)}
            >
              <TrashIcon width="20" height="20" />
            </button>
          </div>
        </AccordionDetails>
      </Accordion>

      {openDeleteModal && (
        <DeleteModal
          title="Delete Scribe"
          description="Are you sure you want to delete this scribe? This action cannot be undone."
          onClose={() => setOpenDeleteModal(false)}
          onSubmit={onDeleteRecording}
        />
      )}
    </div>
  );
};

export default PreviousRecording;
