import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { suggestAnswer } from "../../../store/conversation/thunks";
import VerticalDivider from "../../Basic/VerticalDivider";
import LoaderDots from "../../Basic/LoaderDots";
import { ReactComponent as SendIcon } from "../../../assets/icons/send.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-small.svg";
import { ReactComponent as WandIcon } from "../../../assets/icons/wand.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

const MobileInputArea = ({
  messages,
  setMessages,
  onSendMessage,
  suggestedAnswer,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const { currentConversation, currentConversationId } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (suggestedAnswer && showResponse) {
      setMessages({
        ...messages,
        [currentConversationId]: suggestedAnswer,
      });
    }
  }, [suggestedAnswer, showResponse]);

  return (
    <ClickAwayListener onClickAway={() => setIsActive(false)}>
      <div className="w-full bg-white py-2">
        <div
          className={`flex items-end relative ${isActive ? "pl-3" : "px-2"}`}
        >
          {!isActive && (
            <div className="flex items-center mb-2">
              <button
                aria-label="Show additional options"
                className="w-6 h-6 flex-none bg-gray-200 rounded-full flex items-center justify-center"
                onClick={() => setIsActive(true)}
              >
                <PlusIcon stroke="#888888" width="12" height="12" />
              </button>
              <VerticalDivider className="h-4 mx-2" />
            </div>
          )}
          <TextareaAutosize
            value={messages[currentConversationId]}
            onChange={(e) =>
              setMessages({
                ...messages,
                [currentConversationId]: e.target.value,
              })
            }
            placeholder="Type a message"
            minRows={isActive ? 1 : 2}
            maxRows={10}
            className={`w-full resize-none scrollbar border-r-8 border-white pr-2 outline-none
              focus:outline-none focus:ring-0 text-xs bg-transparent ${
                isActive ? "pb-3 pt-1" : "-mb-1"
              }`}
          />
          {!isActive && (
            <button
              aria-label="Send message"
              className="w-10 h-10 flex-none bg-primary-blue rounded-2.5 flex items-center justify-center"
              onClick={onSendMessage}
            >
              <SendIcon width="18" height="18" />
            </button>
          )}

          {showResponse && !suggestedAnswer && (
            <div className="w-full h-full bg-white flex items-center justify-center space-x-4 text-xs absolute top-0 left-0">
              <LoaderDots />
            </div>
          )}
        </div>
        {isActive && (
          <div className="pt-2 px-2 border-t flex justify-between">
            <button
              aria-label="Suggest answer"
              onClick={() => {
                if (!showResponse) {
                  dispatch(suggestAnswer(currentConversationId));
                }
                setShowResponse(!showResponse);
              }}
              disabled={!currentConversation.show_response}
            >
              <WandIcon
                width="16"
                height="16"
                stroke={
                  currentConversation.show_response ? "#2970FF" : "#71717A"
                }
              />
            </button>

            <div className="flex items-center space-x-3">
              {showResponse && (
                <button
                  aria-label="Remove suggested answer"
                  onClick={() => {
                    setShowResponse(false);
                    setMessages({
                      ...messages,
                      [currentConversationId]: "",
                    });
                  }}
                >
                  <CloseIcon stroke="#E74444" width="16" height="17" />
                </button>
              )}
              <button
                aria-label="Send message"
                className="w-10 h-10 flex-none bg-primary-blue rounded-2.5 flex items-center justify-center"
                onClick={onSendMessage}
              >
                <SendIcon width="18" height="18" />
              </button>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default MobileInputArea;
