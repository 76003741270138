import React from "react";
import TextareaAutosize from "react-textarea-autosize";

const TextareaAutosizeForm = ({
  className,
  label,
  placeholder,
  name,
  required,
  withoutAsterisk,
  register = () => {},
  registerOptions = {},
  error,
  ...props
}) => {
  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <TextareaAutosize
        {...register(name, {
          required: required,
          ...registerOptions,
        })}
        name={name}
        className={`w-full resize-none px-3 py-2.5 outline-none focus:outline-none focus:ring-0 text-sm rounded-lg 
          border align-top scrollbar 
          ${error ? "border-red-400" : "border-gray-300"}
        `}
        placeholder={placeholder}
        {...props}
      />
      {error && (
        <p className="text-red-400 text-sm font-normal mt-1.5">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default TextareaAutosizeForm;
