import {useState, useRef, useEffect} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { trackTime } from "../store/billingMetrics/thunks";

// should both be positive integers, representing ms
const MIN_TIME_LOG = 1000; // increase this to reduce load
const MAX_TIME_LOG = 5 * 60 * 1000; // synonymous with max inactive time before timer resets

export const useActivityTracker = (doTracking = true) => {
    const [startTimestamp, setStartTimestamp] = useState(Date.now());
    const startTimestampRef = useRef(startTimestamp); // to avoid re-rendering with the parent

    useEffect(() => {
        startTimestampRef.current = startTimestamp;
    }, [startTimestamp]);

    const handleActivity = async () => {
        const elapsed = Date.now() - startTimestampRef.current;

        if (!doTracking || elapsed < MIN_TIME_LOG) {
            return;
        }

        restartTimer();

        if (elapsed < MAX_TIME_LOG) {
            await trackTime(Math.round(elapsed / 1000));
        }
    };

    useIdleTimer({
        onAction: handleActivity,
        timeout: MIN_TIME_LOG
    });

    const restartTimer = () => {
        setStartTimestamp(Date.now());
    };

    return { restartTimer };
};
