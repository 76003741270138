import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getPatientInfo } from "../store/patient/thunks";
import { setSelectedPatientProfileAction } from "../store/patient/actions";
import { getFormattedPhoneNumber } from "../helpers/helpers";
import Layout from "../components/Layout";
import Button from "../components/Basic/Button";
import ProfilePicture from "../components/Basic/ProfilePicture";
import TabsBorder from "../components/Basic/TabsBorder";
import PatientShortInfo from "../components/PatientProfile/PatientShortInfo";
import InformationTab from "../components/PatientProfile/InformationTab";
import EnrollmentsTab from "../components/PatientProfile/EnrollmentsTab";
import NotesTab from "../components/PatientProfile/NotesTab";
import ScribeTab from "../components/PatientProfile/ScribeTab";
import Loader from "../components/Basic/Loader";
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow-left-simple.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit2.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/phone.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/mail.svg";

const patientProfileTabs = {
  SCRIBE: "Scribe",
  NOTES: "Notes",
  ENROLLMENTS: "Enrollments",
  INFORMATION: "Information",
};

const ContactInfo = ({ icon, text }) => {
  return (
    <div
      className="flex items-center space-x-2 text-sm md:text-base text-gray-700 h-8 md:h-10 px-4 rounded-lg
        border border-gray-300 w-fit"
    >
      {icon} <span>{text}</span>
    </div>
  );
};

const PatientProfile = () => {
  const { id } = useParams();
  const { selectedPatientProfile, profileLoading } = useSelector(
    (state) => state.patient
  );
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const { INFORMATION, ENROLLMENTS, NOTES, SCRIBE } = patientProfileTabs;
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("patientProfileTab") || SCRIBE
  );
  const [editMode, setEditMode] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPatientInfo(id, selectedPatientProfile));

    return () => {
      dispatch(setSelectedPatientProfileAction(null));
    };
  }, [id]);

  useEffect(() => {
    if (selectedPatientProfile && !profileLoading) {
      setPatientInfo(selectedPatientProfile.info);
    }
  }, [selectedPatientProfile]);

  const setActiveTabPatientProfile = (tab) => {
    setActiveTab(tab);
    sessionStorage.setItem("patientProfileTab", tab);
  };

  return (
    <Layout className="bg-gray-background p-4 md:p-6 relative grid grid-rows-tab-layout">
      <div
        className="flex items-center space-x-2 font-semibold text-sm md:text-base w-full bg-white px-4 md:px-6 py-5
          rounded-t-xl border-t border-x border-gray-200"
      >
        <button
          onClick={() => navigate(-1)}
          className="text-gray-500 w-10 h-6 flex items-center justify-center"
        >
          <ArrowIcon stroke="#121212" width="8" height="15" />
        </button>
        <p>
          {patientInfo.preffered_name
            ? patientInfo.preffered_name
            : patientInfo.first_name}{" "}
          {patientInfo.last_name}
        </p>
      </div>

      <div
        className="bg-white w-full h-full overflow-y-auto scrollbar rounded-b-xl border-b border-x border-gray-200
          px-4 md:px-6 py-5"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3 md:space-x-6">
            <ProfilePicture
              firstName={
                patientInfo.preffered_name
                  ? patientInfo.preffered_name
                  : patientInfo.first_name
              }
              lastName={patientInfo.last_name}
              src={patientInfo.profile_picture}
              size={isDesktop ? 20 : 12}
              fontSize={isDesktop ? "3xl" : "lg"}
              className={`bg-[#FF9800] 
              ${isDesktop ? "w-20 h-20 text-3xl" : "w-12 h-12 text-lg"}`}
              bgColor="[#FF9800]"
              textColor="white"
              flagPadding={false}
            />
            <div>
              <p className="font-semibold text-xl md:text-2xl">
                {patientInfo.preffered_name
                  ? patientInfo.preffered_name
                  : patientInfo.first_name}{" "}
                {patientInfo.last_name}
              </p>
              {isDesktop && (
                <PatientShortInfo patient={patientInfo} className="mt-3" />
              )}
            </div>
          </div>

          <Button
            variant="secondary"
            size="unset"
            className={`p-1.5 md:px-4 
              ${activeTab === INFORMATION ? "" : "hidden"}`}
            onClick={() => setEditMode(true)}
          >
            <EditIcon
              stroke="#2970FF"
              width={isDesktop ? 16 : 20}
              height={isDesktop ? 16 : 20}
            />
            {isDesktop && <p className="ml-2 text-sm font-semibold">Edit</p>}
          </Button>
        </div>

        {!isDesktop && (
          <PatientShortInfo patient={patientInfo} className="mt-5" />
        )}

        <div className="flex flex-wrap gap-3 md:gap-4 md:ml-[104px] mt-5 md:mt-3 md:min-h-[40px]">
          {patientInfo.phone_number && (
            <ContactInfo
              icon={
                <PhoneIcon
                  width={isDesktop ? 20 : 16}
                  height={isDesktop ? 20 : 16}
                  stroke="#404654"
                />
              }
              text={getFormattedPhoneNumber(patientInfo.phone_number)}
            />
          )}
          {patientInfo.email && (
            <ContactInfo
              icon={
                <EmailIcon
                  width={isDesktop ? 20 : 16}
                  height={isDesktop ? 20 : 16}
                  stroke="#404654"
                />
              }
              text={patientInfo.email}
            />
          )}
        </div>

        <TabsBorder
          tabs={Object.values(patientProfileTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTabPatientProfile}
          activeTextColor="primary-blue"
          className="text-sm font-semibold border-b-2 mb-5 mt-8 md:mb-8 md:mt-9"
        />

        {activeTab === SCRIBE && <ScribeTab />}
        {activeTab === NOTES && <NotesTab />}
        {activeTab === ENROLLMENTS && <EnrollmentsTab />}
        {activeTab === INFORMATION && (
          <InformationTab
            patientInfo={patientInfo}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        )}
      </div>

      {profileLoading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2">
          <Loader />
        </div>
      )}
    </Layout>
  );
};

export default PatientProfile;
