import React from "react";
import PatientInfo from "../Basic/PatientInfo";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const TagsPopup = ({ onClose, patient }) => {
  return (
    <div className="py-2 pr-1 overflow-hidden">
      <div className="rounded-xl py-3 pl-5 pr-4 shadow-popup w-full font-Mulish max-h-[65vh] overflow-y-auto scrollbar">
        <button
          onClick={onClose}
          className="w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center mb-3 ml-auto"
        >
          <CloseIcon width="13" height="13" />
        </button>
        {patient && <PatientInfo patient={patient} />}
        {patient &&
          patient.current_campaigns &&
          patient.current_campaigns.length > 0 && (
            <div className="mt-6 text-sm font-semibold space-y-4">
              {patient.current_campaigns.map((campaign) => (
                <div key={campaign.campaign_id}>
                  <div className="flex items-center justify-between space-x-2">
                    <p className="truncate">{campaign.campaign.campaign}</p>
                    <p className="text-xs text-zinc-450 font-medium">
                      {new Date(
                        campaign.next_checkin.replace(/-/g, "/") + " GMT+0"
                      ).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit",
                      })}
                    </p>
                  </div>
                  {campaign.outreach_focus && (
                    <div
                      className="mt-2 rounded-xl bg-gray-50 px-2 py-2.5 text-zinc-700 text-xs font-medium
                        whitespace-pre-wrap"
                    >
                      {campaign.outreach_focus}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default TagsPopup;
