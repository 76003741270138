import {
  LOGOUT,
  SET_USER,
  SET_USERS,
  SET_ROLES,
  SET_ERROR,
  SET_COUNTRY,
  SET_NOTIFICATION,
  OPEN_SUCCESS_MODAL,
  SET_ASSISTANTS,
  SET_SIGNUP_INFO,
} from "../types";

export function logoutAction() {
  return {
    type: LOGOUT,
  };
}

export function setUserAction(user) {
  return {
    type: SET_USER,
    payload: {
      user: user,
    },
  };
}

export function setUsersAction(users) {
  return {
    type: SET_USERS,
    payload: {
      users: users,
    },
  };
}

export function setRolesAction(roles) {
  return {
    type: SET_ROLES,
    payload: {
      roles: roles,
    },
  };
}

export function setErrorAction(errorMessage) {
  return {
    type: SET_ERROR,
    payload: {
      errorMessage: errorMessage,
    },
  };
}

export function setNotificationAction(notification) {
  return {
    type: SET_NOTIFICATION,
    payload: {
      notification: notification,
    },
  };
}

export function setOpenSuccessModalAction(successModal) {
  return {
    type: OPEN_SUCCESS_MODAL,
    payload: {
      successModal: successModal,
    },
  };
}

export function setCountryAction(country) {
  return {
    type: SET_COUNTRY,
    payload: {
      country: country,
    },
  };
}

export function setAssistantsAction(assistants) {
  return {
    type: SET_ASSISTANTS,
    payload: {
      assistants: assistants,
    },
  };
}

export function setSignupInfoAction(signupInfo) {
  return {
    type: SET_SIGNUP_INFO,
    payload: {
      signupInfo: signupInfo,
    },
  };
}
