import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import {
  getConversationRecords,
  sendPatientRecord,
  sendRecord,
} from "../store/voiceRecorder/thunks";
import { getConversation } from "../store/conversation/thunks";
import { getPatientNotes, getPatientRecordings } from "../store/patient/thunks";
import {
  setOpenAttachModalAction,
  setSavedAudioIdAction,
} from "../store/voiceRecorder/actions";

const initialState = {
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audioId: null,
};

const UseRecorder = (conversationId, patientId, patientProfile) => {
  const [recorderState, setRecorderState] = useState(initialState);
  const dispatch = useDispatch();

  const sendAudioPart = (audioId, blob, status) => {
    if (!conversationId) {
      dispatch(sendPatientRecord(patientProfile, blob, audioId, status));
    } else {
      dispatch(
        sendRecord(conversationId, blob, audioId, status, () => {
          if (patientProfile) {
            dispatch(getPatientRecordings(patientId, patientProfile));
          } else {
            dispatch(getConversationRecords(conversationId));
          }
        })
      );
    }
  };

  const startRecording = async (setRecorderState) => {
    try {
      const audioId = uuid();

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { noiseSuppression: false, echoCancellation: false },
      });

      setRecorderState((prevState) => {
        return {
          ...prevState,
          initRecording: true,
          mediaStream: stream,
          audioId: audioId,
        };
      });
    } catch (err) {
      console.error(err);
    }
  };

  const saveRecording = (recorder) => {
    if (recorder.state !== "inactive") recorder.stop();
  };

  useEffect(() => {
    let recordingInterval = null;

    if (recorderState.initRecording)
      recordingInterval = setInterval(() => {
        setRecorderState((prevState) => ({
          ...prevState,
          recordingSeconds: prevState.recordingSeconds + 1,
        }));
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  }, [recorderState.initRecording]);

  useEffect(() => {
    if (recorderState.mediaStream)
      setRecorderState((prevState) => {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream, {
            mimeType: MediaRecorder.isTypeSupported("audio/webm;codecs=opus")
              ? "audio/webm;codecs=opus"
              : "audio/mp4;codecs=mp4a",
          }),
        };
      });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;

    if (recorder && recorder.state === "inactive") {
      recorder.start(2000);

      recorder.ondataavailable = (e) => {
        const blob = new Blob([e.data], { type: "audio/mp4" });
        sendAudioPart(recorderState.audioId, blob, "progress");
      };

      recorder.onstop = () => {
        const blob = new Blob([], { type: "audio/mp4" });
        sendAudioPart(recorderState.audioId, blob, "end");
        dispatch(setSavedAudioIdAction(recorderState.audioId));
        dispatch(setOpenAttachModalAction(true));
        setRecorderState(initialState);
      };
    }

    return () => {
      if (recorder)
        recorder.stream.getAudioTracks().forEach((track) => track.stop());
    };
  }, [recorderState.mediaRecorder]);

  useEffect(() => {
    if (recorderState.mediaRecorder) saveRecording(recorderState.mediaRecorder);
  }, [conversationId, patientId]);

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
  };
};

export default UseRecorder;
