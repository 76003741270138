import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import { inboxes } from "../../helpers/constants";
import {
  addFailedMessageAction,
  addSentMessageAction,
  removeSentMessageAction,
  setConversationAction,
  setConversationsAction,
  setConversationsCompletedAction,
  setConversationsCountsAction,
  setConversationsReviewAction,
  setEncountersAction,
  setLoaderAction,
} from "./actions";
import { setNotificationAction } from "../user/actions";

export const getConversation =
  (id, onSuccess = () => {}) =>
  (dispatch) => {
    axios.get(`${BASE_URL}/conversation/${id}`).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load conversation",
          })
        );
      } else {
        onSuccess();
        dispatch(removeSentMessageAction(id));
        dispatch(setConversationAction(response.data.data.conversation));
      }
    });
  };

const splitCampaignIdsAndCommon = (campaigns) => {
  return campaigns.reduce(
    (result, value) => {
      if (typeof value === "number" && !isNaN(value)) {
        result.campaignIds.push(value);
      } else if (typeof value === "string") {
        result.commonCampaigns.push(value);
      }
      return result;
    },
    { campaignIds: [], commonCampaigns: [] }
  );
};

export const searchConversationsWithFilters =
  (searchInput, filters, page, rows, inboxType, onSuccess = () => {}) =>
  (dispatch) => {
    const { campaignIds, commonCampaigns } = splitCampaignIdsAndCommon(
      filters && filters.campaigns ? filters.campaigns : []
    );
    const statuses =
      filters && filters.status && filters.status[inboxType]
        ? filters.status[inboxType]
        : [];
    const flags =
      filters && filters.other && filters.other.flag ? filters.other.flag : [];
    const isUnread =
      filters && filters.other && filters.other.unread ? true : null;
    const isStaffEngaging =
      filters && filters.other && filters.other.staffEngaging ? true : null;
    const from =
      filters && filters.time && filters.time !== "all"
        ? filters.time - 1
        : null;

    axios
      .post(`${BASE_URL}/conversation/search`, {
        q: searchInput,
        inbox: inboxType,
        campaignIds: campaignIds,
        commonCampaigns: commonCampaigns,
        statuses: statuses,
        flags: flags,
        isUnread: isUnread,
        staffEngaging: isStaffEngaging,
        from: from,
        to: 0,
        offset: (page - 1) * rows,
        limit: rows,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load conversations",
            })
          );
        } else {
          switch (inboxType) {
            case inboxes.ACTIVE:
              dispatch(setConversationsAction(response.data.data));
              break;
            case inboxes.REVIEW:
              dispatch(setConversationsReviewAction(response.data.data));
              break;
            case inboxes.COMPLETE:
              dispatch(setConversationsCompletedAction(response.data.data));
              break;
          }
          onSuccess();
        }
        dispatch(
          getConversationCounts(
            from,
            inboxType,
            campaignIds,
            commonCampaigns,
            statuses,
            flags,
            isUnread
          )
        );
      });
  };

export const getConversationCounts =
  (from, inboxType, campaignIds, commonCampaigns, statuses, flags, isUnread) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/conversation/count`, {
        from: from,
        to: 0,
        inbox: inboxType,
        campaignIds: campaignIds,
        commonCampaigns: commonCampaigns,
        statuses: statuses,
        flags: flags,
        isUnread: isUnread,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load counts",
            })
          );
        } else {
          dispatch(setConversationsCountsAction(inboxType, response.data.data));
        }
        dispatch(setLoaderAction(false));
      });
  };

export const completeConversation =
  (
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/conversation/${conversationId}/complete`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Conversation is not completed",
              desc: "Failed to complete conversation",
            })
          );
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType
            )
          );
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxes.COMPLETE
            )
          );
        }
      });
  };

export const markAsUnread =
  (
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/conversation/mark-as-unread`, {
        conversation_id: conversationId,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to mark conversation as unread",
            })
          );
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType
            )
          );
        }
      });
  };

export const changeFlag =
  (
    conversationId,
    flag,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/conversation/change-flag`, {
        conversation_id: conversationId,
        flag: flag,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to change conversation flag",
            })
          );
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType
            )
          );
        }
      });
  };

export const suggestAnswer = (id) => (dispatch) => {
  axios
    .get(`${BASE_URL}/conversation/${id}/suggested-answer`)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load suggested answer",
          })
        );
      }
      dispatch(getConversation(id));
    });
};

export const sendMessage =
  (
    message,
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType
  ) =>
  (dispatch) => {
    dispatch(addSentMessageAction(message, conversationId));
    axios
      .post(`${BASE_URL}/message/send`, {
        conversation_id: conversationId,
        message: message,
      })
      .then((response) => {
        dispatch(
          searchConversationsWithFilters(
            conversationSearchInput,
            activeFilters,
            page,
            rows,
            inboxType
          )
        );
        if (response.data.error) {
          dispatch(
            getConversation(conversationId, () =>
              dispatch(addFailedMessageAction(message, conversationId))
            )
          );
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to send message",
            })
          );
        } else {
          dispatch(getConversation(conversationId));
        }
      });
  };

export const removeDiagnoses = (diagnoses, conversationId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/conversation/remove-diagnoses`, {
      conversation_id: conversationId,
      diagnoses: diagnoses,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Diagnoses are not removed",
            desc: "Failed to remove diagnoses",
          })
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully remove diagnosis",
            desc: "Diagnosis was successfully removed",
          })
        );
        dispatch(getConversation(conversationId));
      }
    });
};

export const saveNote = (conversationId, note) => (dispatch) => {
  axios
    .post(`${BASE_URL}/conversation/${conversationId}/save-note`, {
      note: note,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to save note",
          })
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully save note",
            desc: "Note has been successfully saved",
          })
        );
      }
    });
};

export const generateNote =
  (conversationId, onSuccess = () => {}, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/conversation/${conversationId}/note`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to generate note",
            })
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Note generating",
              desc: "Note will be generated",
            })
          );
          dispatch(getConversation(conversationId));
        }
        onEnd();
      });
  };

export const searchEncounters =
  (conversationId, type, description, onEnd = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${conversationId}/search`, {
        type: type === "All" ? null : type,
        description: description,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load encounters",
            })
          );
        } else {
          dispatch(setEncountersAction(response.data.data.encounters));
        }
        onEnd();
      });
  };

export const saveEncounterNote =
  (
    conversationHistoryId,
    note,
    conversationId,
    type,
    description,
    onSuccess = () => {}
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${conversationHistoryId}/save-note`, {
        note: note,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save encounter note",
            })
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully save note",
              desc: "Note has been successfully saved",
            })
          );
          onSuccess();
        }
        if (conversationId) {
          dispatch(searchEncounters(conversationId, type, description));
        }
      });
  };

export const generateEncounterNote =
  (encounterId, conversationId, type, description, onSuccess, onEnd) =>
  (dispatch) => {
    axios.get(`${BASE_URL}/encounter/${encounterId}/note`).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to generate encounter note",
          })
        );
      } else if (response.data.data.note) {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully generate note",
            desc: "Note has been successfully generated",
          })
        );
        onSuccess(response.data.data.note);
      } else {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Generated note is empty",
          })
        );
      }
      onEnd();
    });
  };
