import React, { useState, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getAge, getFormattedPhoneNumber } from "../../../helpers/helpers";
import ProfilePicture from "../../Basic/ProfilePicture";
import CircleDivider from "../../Basic/CircleDivider";
import TabsBorder from "../../Basic/TabsBorder";
import Loader from "../../Basic/Loader";

const InsightTab = lazy(() => import("./InsightTab"));
const ScribeTab = lazy(() => import("./ScribeTab"));
const EncounterTab = lazy(() => import("./EncounterTab"));

const InsightPanelTabs = {
  INSIGHT: "Insight",
  SCRIBE: "Scribe",
  NOTES: "Notes",
};

const InsightPanel = () => {
  const { INSIGHT, SCRIBE, NOTES } = InsightPanelTabs;
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("insightPanelTab") || INSIGHT
  );
  const { currentConversation } = useSelector((state) => state.conversation);
  const { patient } = currentConversation;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const setActiveTabInsightPanel = (tab) => {
    setActiveTab(tab);
    sessionStorage.setItem("insightPanelTab", tab);
  };

  return (
    <div
      className="md:border border-gray-200 bg-white md:rounded-lg h-full flex-none grid grid-rows-tab-layout
        transition-all w-full md:w-72 lg:w-88 xl:w-106 relative"
    >
      <div className="h-fit md:h-13 flex items-end px-5 md:px-4 pb-px relative">
        {isMobile ? (
          <p className="w-full text-center font-semibold py-6">Detail</p>
        ) : (
          <TabsBorder
            tabs={Object.values(InsightPanelTabs)}
            activeTab={activeTab}
            setActiveTab={setActiveTabInsightPanel}
            className="text-sm border-b-2"
          />
        )}
      </div>

      <div className="h-full overflow-y-auto scrollbar">
        {isMobile && (
          <div className="px-5">
            <div className="rounded-xl bg-neutral-100 flex flex-col items-center p-3 mx-5">
              <ProfilePicture
                src={patient.profile_picture}
                firstName={
                  patient.preffered_name
                    ? patient.preffered_name
                    : patient.first_name
                }
                lastName={patient.last_name}
                size={11}
                fontSize="base"
              />
              <p className="text-sm md:text-base font-semibold mb-1 mt-3">
                {(patient.preffered_name
                  ? patient.preffered_name
                  : patient.first_name) +
                  " " +
                  patient.last_name}
              </p>
              <div className="flex text-xxs text-neutral-500 space-x-2 items-center">
                {patient.birthdate && (
                  <>
                    <p>Age {getAge(patient.birthdate)}</p>
                    <CircleDivider />
                  </>
                )}
                <p className="capitalize">
                  {patient.gender ? patient.gender : patient.sex}
                </p>
                <CircleDivider />
                <div>{getFormattedPhoneNumber(patient.phone_number)}</div>
              </div>
            </div>
            <TabsBorder
              tabs={Object.values(InsightPanelTabs)}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              className="text-sm 2xl:text-base border-b-2 2xl:border-0 my-6"
            />
          </div>
        )}
        <Suspense
          fallback={
            <div className="w-full h-full flex items-center justify-center">
              <Loader />
            </div>
          }
        >
          {activeTab === INSIGHT && <InsightTab />}
          {activeTab === SCRIBE && <ScribeTab />}
          {activeTab === NOTES && <EncounterTab />}
        </Suspense>
      </div>
    </div>
  );
};

export default InsightPanel;
