import React from "react";

const RecordEmpty = ({ recording = false, time = "0:00" }) => {
  const circles = Array.from({ length: 60 }, (_, index) => (
    <div
      key={index}
      style={{ animationDelay: `${index * 100}ms` }}
      className={`w-1 h-1 flex-none rounded-full bg-zinc-450 ${
        recording ? "animate-recording" : ""
      }`}
    />
  ));

  return (
    <div className="text-sm w-full grid grid-cols-middle items-center gap-2.5">
      <div
        className={`w-2 h-2 flex-none rounded-full 
          ${recording ? "bg-red-500" : "bg-zinc-450"}`}
      />
      <div className="h-5 w-full overflow-hidden flex items-center space-x-1.5">
        {circles}
      </div>
      <span>{time}</span>
    </div>
  );
};

export default RecordEmpty;
