import { v4 as uuid } from "uuid";
import {
  EXPAND_PANEL,
  COLLAPSE_SIDEBAR,
  SELECT_CONVERSATION,
  SET_CONVERSATIONS,
  SET_CONVERSATIONS_COMPLETED,
  SET_CONVERSATIONS_COMPLETED_TEMP,
  SET_CONVERSATION,
  SET_LOADER,
  SET_COLLAPSE_LIST,
  SET_SEARCH_INPUT,
  ADD_SENT_MESSAGE,
  REMOVE_SENT_MESSAGE,
  SET_NEXT_CONVERSATION_ID,
  ADD_FAILED_MESSAGE,
  REMOVE_FAILED_MESSAGE,
  SET_CLOSED_RESPONSES,
  SET_NOTE_MARKUP,
  SET_ENCOUNTER_NOTE_MARKUP,
  SET_ENCOUNTERS,
  SET_ENCOUNTER_ACTIVE_TAG,
  SET_ENCOUNTER_SEARCH_INPUT,
  SET_CONVERSATIONS_SCROLL_POSITION,
  SET_CONV_CURR_PAGE,
  LOGOUT,
  SET_ACTIVE_FILTERS,
  SET_CONVERSATIONS_REVIEW,
  SET_ACTIVE_INBOX,
  SET_INIT_FILTERS,
  SET_CAMPAIGN_OPTIONS,
  SET_CONVERSATIONS_UPDATED,
  SET_ON_MESSAGE_CONVERSATION_ID,
  SET_CONVERSATIONS_COUNTS,
  SET_FILTERS_UPDATED,
  SET_OPEN_PATIENT_DETAILS,
} from "../types";
import { filtersClear, inboxes } from "../../helpers/constants";

const init = {
  panelExpanded: false,
  sidebarCollapsed: false,
  currentConversationId: sessionStorage.currentConversationId
    ? JSON.parse(sessionStorage.currentConversationId)
    : "",
  nextConversationId: "",
  currentConversation: sessionStorage.currentConversation
    ? JSON.parse(sessionStorage.currentConversation)
    : null,
  conversationsReview: null,
  conversations: null,
  conversationsCompleted: null,
  activeInbox: sessionStorage.inboxTab || inboxes.REVIEW,
  conversationsCompletedTemp: [],
  convCurrPage: 1,
  loader: true,
  collapsePatientList: localStorage.collapsePatientList
    ? JSON.parse(localStorage.collapsePatientList)
    : false,
  activeFilters: sessionStorage.inboxFilters
    ? JSON.parse(sessionStorage.inboxFilters)
    : filtersClear,
  initFilters: null,
  campaignFilterOptions: [],
  conversationSearchInput: sessionStorage.conversationSearchInput || "",
  conversationsScrollPosition: 0,
  sentMessages: {},
  failedMessages: localStorage.failedMessages
    ? JSON.parse(localStorage.failedMessages)
    : {},
  closedResponses: localStorage.closedResponses
    ? JSON.parse(localStorage.closedResponses)
    : [],
  noteHtmlMarkups: {},
  encounterNoteHtmlMarkups: {},
  encounters: [],
  encounterSearchInput: "",
  encounterActiveTag: "All",
  conversationsUpdated: true,
  onMessageConversationId: null,
  conversationsCounts: null,
  filtersUpdated: true,
  openPatientDetails: false,
};

export default function conversationReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      localStorage.removeItem("failedMessages");
      localStorage.removeItem("closedResponses");
      localStorage.removeItem("collapsePatientList");
      sessionStorage.removeItem("inboxFilters");
      sessionStorage.removeItem("conversationSearchInput");
      sessionStorage.removeItem("currentConversation");
      sessionStorage.removeItem("currentConversationId");
      sessionStorage.removeItem("inboxTab");
      sessionStorage.removeItem("insightPanelTab");
      return init;
    case SET_CONVERSATIONS_REVIEW:
      return { ...state, conversationsReview: payload.conversationsReview };
    case SET_CONVERSATIONS:
      return { ...state, conversations: payload.conversations };
    case SET_CONVERSATIONS_COMPLETED:
      return {
        ...state,
        conversationsCompleted: payload.conversationsCompleted,
      };
    case SET_CONVERSATIONS_SCROLL_POSITION:
      return {
        ...state,
        conversationsScrollPosition: payload.conversationsScrollPosition,
      };
    case SET_ACTIVE_INBOX:
      sessionStorage.setItem("inboxTab", payload.activeInbox);
      return { ...state, activeInbox: payload.activeInbox };
    case SET_CONV_CURR_PAGE:
      return { ...state, convCurrPage: payload.convCurrPage };
    case SET_CONVERSATIONS_COMPLETED_TEMP:
      return {
        ...state,
        conversationsCompletedTemp: payload.conversationsCompletedTemp,
      };
    case SET_CONVERSATION:
      sessionStorage.setItem(
        "currentConversation",
        JSON.stringify(payload.conversation)
      );
      return { ...state, currentConversation: payload.conversation };
    case SET_ENCOUNTERS:
      return { ...state, encounters: payload.encounters };
    case SELECT_CONVERSATION:
      sessionStorage.setItem("currentConversationId", payload.conversationId);
      return { ...state, currentConversationId: payload.conversationId };
    case SET_NEXT_CONVERSATION_ID:
      return { ...state, nextConversationId: payload.conversationId };
    case EXPAND_PANEL:
      return { ...state, panelExpanded: payload.state };
    case COLLAPSE_SIDEBAR:
      return { ...state, sidebarCollapsed: payload.state };
    case SET_LOADER:
      return { ...state, loader: payload.loader };
    case SET_COLLAPSE_LIST:
      localStorage.setItem(
        "collapsePatientList",
        JSON.stringify(!state.collapsePatientList)
      );
      return { ...state, collapsePatientList: payload.state };
    case SET_ACTIVE_FILTERS:
      sessionStorage.setItem(
        "inboxFilters",
        JSON.stringify(payload.activeFilters)
      );
      return { ...state, activeFilters: payload.activeFilters };
    case SET_INIT_FILTERS:
      return { ...state, initFilters: payload.initFilters };
    case SET_CAMPAIGN_OPTIONS:
      return { ...state, campaignFilterOptions: payload.campaignFilterOptions };
    case SET_ENCOUNTER_ACTIVE_TAG:
      return { ...state, encounterActiveTag: payload.encounterActiveTag };
    case SET_SEARCH_INPUT:
      sessionStorage.setItem(
        "conversationSearchInput",
        payload.conversationSearchInput
      );
      return {
        ...state,
        conversationSearchInput: payload.conversationSearchInput,
      };
    case SET_ENCOUNTER_SEARCH_INPUT:
      return {
        ...state,
        encounterSearchInput: payload.encounterSearchInput,
      };
    case SET_NOTE_MARKUP:
      return {
        ...state,
        noteHtmlMarkups: {
          ...state.noteHtmlMarkups,
          [payload.conversationId]: payload.noteMarkup,
        },
      };
    case SET_ENCOUNTER_NOTE_MARKUP:
      return {
        ...state,
        encounterNoteHtmlMarkups: {
          ...state.encounterNoteHtmlMarkups,
          [payload.conversationId]: {
            ...state.encounterNoteHtmlMarkups[payload.conversationId],
            [payload.encounterId]: {
              openEditor: payload.openEditor,
              markup: payload.noteMarkup,
            },
          },
        },
      };
    case ADD_SENT_MESSAGE:
      return addSentMessage(state, payload);
    case REMOVE_SENT_MESSAGE:
      return removeSentMessage(state, payload);
    case ADD_FAILED_MESSAGE:
      return addFailedMessage(state, payload);
    case REMOVE_FAILED_MESSAGE:
      return removeFailedMessage(state, payload);
    case SET_CLOSED_RESPONSES:
      return setClosedResponses(state, payload);
    case SET_CONVERSATIONS_UPDATED:
      return { ...state, conversationsUpdated: payload.conversationsUpdated };
    case SET_ON_MESSAGE_CONVERSATION_ID:
      return {
        ...state,
        onMessageConversationId: payload.onMessageConversationId,
      };
    case SET_CONVERSATIONS_COUNTS:
      return {
        ...state,
        conversationsCounts: {
          ...state.conversationsCounts,
          [payload.inbox]: payload.conversationsCounts,
        },
      };
    case SET_FILTERS_UPDATED:
      return { ...state, filtersUpdated: payload.filtersUpdated };
    case SET_OPEN_PATIENT_DETAILS:
      return { ...state, openPatientDetails: payload.openPatientDetails };
    default:
      return state;
  }
}

const addSentMessage = (state, payload) => {
  return state.sentMessages[payload.conversationId]
    ? {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]: [
            ...state.sentMessages[payload.conversationId],
            payload.message,
          ],
        },
      }
    : {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]: [payload.message],
        },
      };
};

const removeSentMessage = (state, payload) => {
  return state.sentMessages[payload.conversationId] &&
    state.sentMessages[payload.conversationId].length > 0
    ? {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]:
            state.sentMessages[payload.conversationId].slice(1),
        },
      }
    : state;
};

const addFailedMessage = (state, payload) => {
  const newState = state.failedMessages[payload.conversationId]
    ? {
        ...state,
        failedMessages: {
          ...state.failedMessages,
          [payload.conversationId]: [
            ...state.failedMessages[payload.conversationId],
            { id: uuid(), message: payload.message },
          ],
        },
      }
    : {
        ...state,
        failedMessages: {
          ...state.failedMessages,
          [payload.conversationId]: [{ id: uuid(), message: payload.message }],
        },
      };
  localStorage.setItem(
    "failedMessages",
    JSON.stringify(newState.failedMessages)
  );
  return newState;
};

const removeFailedMessage = (state, payload) => {
  const newState = {
    ...state,
    failedMessages: {
      ...state.failedMessages,
      [payload.conversationId]: state.failedMessages[
        payload.conversationId
      ].filter((message) => message.id !== payload.messageId),
    },
  };
  localStorage.setItem(
    "failedMessages",
    JSON.stringify(newState.failedMessages)
  );
  return newState;
};

const setClosedResponses = (state, payload) => {
  const newState = {
    ...state,
    closedResponses: [...state.closedResponses, payload.conversationId],
  };
  localStorage.setItem(
    "closedResponses",
    JSON.stringify(newState.closedResponses)
  );
  return newState;
};
