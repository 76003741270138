import React, { useEffect, lazy, Suspense } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import { setSelectedCampaignAction } from "./store/campaign/actions";
import { getCampaigns, getCampaignTags } from "./store/campaign/thunks";
import { getNumOfPatients } from "./store/patient/thunks";
import {
  setActiveFiltersAction,
  setCampaignOptionsAction,
  setConversationsUpdatedAction,
  setInitFiltersAction,
  setOnMessageConversationIdAction,
} from "./store/conversation/actions";
import { history } from "./helpers/history";
import {
  commonCampaigns,
  filtersClear,
  getFiltersInit,
  Pages,
} from "./helpers/constants";
import { WS_URL } from "./helpers/config";
import { deepEqual } from "./helpers/helpers";
import Patients from "./pages/Patients";
import Messaging from "./pages/Messaging";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import BillingMetrics from "./pages/BillingMetrics";
import Campaigns from "./pages/Campaigns";
import Settings from "./pages/Settings";
import Loader from "./components/Basic/Loader";
import PatientProfile from "./pages/PatientProfile";

const Metrics = lazy(() => import("./pages/Metrics"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const CheckEmail = lazy(() => import("./pages/CheckEmail"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ResetPasswordConfirmation = lazy(() =>
  import("./pages/ResetPasswordConfirmation")
);
const PasswordCreation = lazy(() => import("./pages/PasswordCreation"));

const App = () => {
  const { user } = useSelector((state) => state.user);
  const { campaigns } = useSelector((state) => state.campaign);
  const { campaignFilterOptions, initFilters } = useSelector(
    (state) => state.conversation
  );
  const dispatch = useDispatch();
  history.navigate = useNavigate();
  history.location = useLocation();

  const token = sessionStorage.token || localStorage.token;

  const connect = () => {
    const websocket = new WebSocket(`${WS_URL}?token=${token}`);

    websocket.onopen = (event) => {
      console.log("Websocket is connected");
    };

    websocket.onerror = (event) => {
      websocket.close();
    };

    websocket.onclose = (event) => {
      console.log(
        "Websocket is closed. Reconnect will be attempted in 1 second"
      );
      dispatch(setConversationsUpdatedAction(false));
      setTimeout(() => {
        connect();
      }, 1000);
    };

    websocket.onmessage = (event) => {
      const id = JSON.parse(event.data).conversation_id;
      dispatch(setOnMessageConversationIdAction(id));
      dispatch(setConversationsUpdatedAction(false));
    };
  };

  useEffect(() => {
    if (user) {
      connect();
      dispatch(getNumOfPatients());
      dispatch(getCampaigns());
      dispatch(getCampaignTags());
    }
  }, [user]);

  useEffect(() => {
    if (user && campaigns.length > 0) {
      dispatch(
        setSelectedCampaignAction(
          sessionStorage.selectedCampaign
            ? JSON.parse(sessionStorage.selectedCampaign)
            : campaigns[0]
        )
      );

      const customerCampaigns = campaigns.map((campaign) => ({
        value: campaign.campaign_id,
        text: campaign.campaign,
      }));

      dispatch(
        setCampaignOptionsAction([...customerCampaigns, ...commonCampaigns])
      );
    }
  }, [campaigns.length, user]);

  useEffect(() => {
    if (campaignFilterOptions.length > 0)
      dispatch(setInitFiltersAction(getFiltersInit(campaignFilterOptions)));
  }, [campaignFilterOptions]);

  useEffect(() => {
    if (initFilters && !deepEqual(initFilters, filtersClear)) {
      dispatch(
        setActiveFiltersAction(
          sessionStorage.inboxFilters
            ? JSON.parse(sessionStorage.inboxFilters)
            : initFilters
        )
      );
    }
  }, [initFilters]);

  return (
    <IntercomProvider appId="qu4axgoz" autoBoot>
      <Suspense
        fallback={
          <div className="w-screen h-screen flex items-center justify-center">
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route path={Pages.METRICS} element={<Metrics />} />
          <Route path={Pages.LOGIN} element={<Login />} />
          <Route path={Pages.SIGNUP} element={<Login signup />} />
          <Route path={Pages.FORGOT_PASS} element={<ForgotPassword />} />
          <Route path={Pages.CHECK_EMAIL} element={<CheckEmail />} />
          <Route path={Pages.RESET_PASS} element={<ResetPassword />} />
          <Route
            path={Pages.RESET_PASS_CONFIRM}
            element={<ResetPasswordConfirmation />}
          />
          <Route path={Pages.CREATE_PASS} element={<PasswordCreation />} />

          <Route element={<ProtectedRoute />}>
            <Route path={Pages.MESSAGING} element={<Messaging />} />
            <Route path={Pages.PATIENTS} element={<Patients />} />
            <Route path={Pages.PATIENT_PROFILE} element={<PatientProfile />} />
            <Route path={Pages.BILLING_METRICS} element={<BillingMetrics />} />
            <Route path={Pages.CAMPAIGNS} element={<Campaigns />} />
            <Route path={Pages.SETTINGS} element={<Settings />} />
          </Route>

          <Route path="*" element={<Navigate to={Pages.MESSAGING} replace />} />
        </Routes>
      </Suspense>
    </IntercomProvider>
  );
};

export default App;
