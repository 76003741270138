import React from "react";
import { getAge } from "../../helpers/helpers";
import CircleDivider from "../Basic/CircleDivider";

const PatientShortInfo = ({ patient, className }) => {
  const isHasAddress =
    patient.address_line_1 ||
    patient.address_line_2 ||
    patient.city ||
    patient.state ||
    patient.country ||
    patient.zip_code;

  return (
    <div
      className={`text-gray-500 font-medium text-sm md:text-base flex flex-wrap gap-2 items-center ${className}`}
    >
      {patient.birthdate && (
        <>
          <p>Age {getAge(patient.birthdate)}</p>
          <CircleDivider />
          <p>
            {new Date(patient.birthdate.replace(/-/g, "/")).toLocaleDateString(
              "en-us",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            )}
          </p>
          {(!!patient.sex || isHasAddress) && <CircleDivider />}
        </>
      )}
      {(patient.gender || patient.sex) && (
        <>
          <p className="capitalize">
            {patient.gender ? patient.gender : patient.sex}
          </p>
          {patient.pronouns && <CircleDivider />}
        </>
      )}
      {patient.pronouns && (
        <>
          <p className="capitalize">{patient.pronouns}</p>
          {isHasAddress && <CircleDivider />}
        </>
      )}
      <p>
        {patient.address_line_1} {patient.address_line_2} {patient.city}{" "}
        {patient.state} {patient.country} {patient.zip_code}
      </p>
    </div>
  );
};

export default PatientShortInfo;
