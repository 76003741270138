import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenAttachModalAction,
  setOpenEncounterModalAction,
} from "../../../store/voiceRecorder/actions";
import {
  discardRecord,
  saveRecordIndependent,
} from "../../../store/voiceRecorder/thunks";
import Modal from "../Modal";
import Button from "../Button";
import Loader from "../Loader";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-circle.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

const ScribeAttachModal = ({
  hasEncounters,
  onGenerating = () => {},
  onEnd = () => {},
}) => {
  const { transcribing, savedAudioId } = useSelector(
    (state) => state.voiceRecorder
  );
  const dispatch = useDispatch();

  const onSaveAsIndependent = () => {
    if (!transcribing) {
      onGenerating();
      dispatch(saveRecordIndependent(savedAudioId, onEnd));
      dispatch(setOpenAttachModalAction(false));
    }
  };

  const onAttachToEncounter = () => {
    dispatch(setOpenAttachModalAction(false));
    dispatch(setOpenEncounterModalAction(true));
  };

  const onClose = () => {
    dispatch(discardRecord(savedAudioId));
    dispatch(setOpenAttachModalAction(false));
  };

  return (
    <Modal onClickAway={onClose}>
      <div className="w-134 flex flex-col items-center font-semibold relative">
        <button
          onClick={onClose}
          className="w-7 h-7 flex items-center justify-center ml-auto"
        >
          <CloseIcon stroke="#121212" width="20" height="20" />
        </button>
        <div
          className="rounded-full bg-system-green w-14 h-14 flex items-center justify-center
            box-content border-[12px] border-system-light-green mb-6"
        >
          <CheckIcon stroke="white" width="40" height="40" />
        </div>
        <p className="text-xl text-center mb-8">Recording saved successfully</p>
        <Button
          className="w-full h-12"
          onClick={onSaveAsIndependent}
          disabled={transcribing}
        >
          Generate note and save as encounter
        </Button>
        {hasEncounters && (
          <button
            className="text-primary-blue flex items-center space-x-2 py-3 mt-4 disabled:opacity-70"
            onClick={onAttachToEncounter}
            disabled={transcribing}
          >
            <p>Generate note and attach to existing encounter</p>
            <ArrowIcon
              className="rotate-180"
              stroke="#2970FF"
              width="14"
              height="12"
            />
          </button>
        )}

        {transcribing && (
          <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <Loader />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ScribeAttachModal;
