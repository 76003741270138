import React from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const Modal = ({
  onClickAway = () => {},
  children,
  className = "p-6 rounded-2xl",
}) => {
  return (
    <div className="fixed h-screen w-screen inset-0 bg-black/50 flex items-center justify-center z-50">
      <ClickAwayListener onClickAway={onClickAway}>
        <div
          className={`bg-white max-w-[90vw] max-h-[90vh] flex grow sm:grow-0 ${className}`}
        >
          {children}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Modal;
