import {
  SET_PATIENTS,
  SET_NUM_PATIENTS,
  SET_NUM_FILTERED_PATIENTS,
  SET_PATIENT_LOADER,
  SET_CURR_PAGE,
  SET_SEARCH,
  SET_SELECTED_PATIENTS,
  SET_TABLE_SCROLL_POSITION,
  LOGOUT,
  SET_PATIENT_EXISTS_ERROR,
  SET_SELECTED_PATIENT_PROFILE,
  SET_PROFILE_LOADING,
} from "../types";

const init = {
  patientsPage: null,
  currPage: 1,
  numOfPatients: 0,
  numOfFilteredPatients: 0,
  rowsPerPage: 50,
  tableScrollPosition: 0,
  loader: false,
  searchValue: sessionStorage.patientSearchInput || "",
  selectedPatients: [],
  patientExistsError: false,
  selectedPatientProfile: null,
  profileLoading: false,
};

export default function patientReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      sessionStorage.removeItem("patientSearchInput");
      sessionStorage.removeItem("patientProfileTab");
      return init;
    case SET_PATIENTS:
      return { ...state, patientsPage: payload.patients };
    case SET_SELECTED_PATIENTS:
      return { ...state, selectedPatients: payload.patients };
    case SET_SELECTED_PATIENT_PROFILE:
      return { ...state, selectedPatientProfile: payload.patient };
    case SET_PROFILE_LOADING:
      return { ...state, profileLoading: payload.profileLoading };
    case SET_CURR_PAGE:
      return { ...state, currPage: payload.currPage };
    case SET_SEARCH:
      sessionStorage.setItem("patientSearchInput", payload.searchValue);
      return { ...state, searchValue: payload.searchValue };
    case SET_NUM_PATIENTS:
      return { ...state, numOfPatients: payload.numOfPatients };
    case SET_NUM_FILTERED_PATIENTS:
      return { ...state, numOfFilteredPatients: payload.numOfFilteredPatients };
    case SET_PATIENT_LOADER:
      return { ...state, loader: payload.loader };
    case SET_TABLE_SCROLL_POSITION:
      return { ...state, tableScrollPosition: payload.tableScrollPosition };
    case SET_PATIENT_EXISTS_ERROR:
      return { ...state, patientExistsError: payload.patientExistsError };
    default:
      return state;
  }
}
