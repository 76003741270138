import React, { lazy, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBillingPatients } from "../store/billingMetrics/thunks";
import {
  setBillingLoadingAction,
  setCurrentDateStampAction,
} from "../store/billingMetrics/actions";
import Layout from "../components/Layout";
import Loader from "../components/Basic/Loader";
import dayjs from "dayjs";

const MetricsTable = lazy(() =>
  import("../components/BillingMetrics/MetricsTable")
);

const BillingMetrics = () => {
  const {
    billingPatients,
    rowsPerPage,
    currPage,
    currentDateStamp,
    selectedBillingType,
    selectedCptCode,
    sortedBy,
  } = useSelector((state) => state.billingMetrics);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!billingPatients) {
      dispatch(setBillingLoadingAction(true));
    }
    if (!currentDateStamp) {
      dispatch(setBillingLoadingAction(true));
      const dateStamp = dayjs().startOf("month").format("YYYY-MM-DD");
      dispatch(setCurrentDateStampAction(dateStamp));
    }
  }, []);

  useEffect(() => {
    if (currentDateStamp) {
      dispatch(
        getBillingPatients(
          currentDateStamp,
          rowsPerPage * currPage,
          "",
          selectedBillingType,
          selectedCptCode,
          sortedBy
        )
      );
    }
  }, [currentDateStamp]);

  return (
    <Layout>
      <div className="w-full h-full overflow-hidden grid">
        <div className="p-4 md:p-6 max-h-full overflow-hidden md:bg-gray-background">
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            }
          >
            <MetricsTable />
          </Suspense>
        </div>
      </div>
    </Layout>
  );
};

export default BillingMetrics;
