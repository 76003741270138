import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          fontFamily: "Mulish, sans-serif",
          "&.MuiTooltip-tooltipArrow": {
            marginBottom: "8px !important",
          },
        },
      },
    },
  },
});

const TooltipMui = ({ children, title, placement = "top", arrow }) => {
  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={title} placement={placement} arrow={arrow}>
        {children}
      </Tooltip>
    </ThemeProvider>
  );
};

export default TooltipMui;
