import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Popover from "@mui/material/Popover";
import { cancelUpcomingMessage } from "../../store/campaign/thunks";
import { getPatientEnrollments } from "../../store/patient/thunks";
import DeleteModal from "../Basic/DeleteModal";
import Modal from "../Basic/Modal";
import EnrollmentCard from "./EnrollmentCard";
import CampaignEditPopup from "./CampaignEditPopup";

const EnrollmentsTab = () => {
  const { selectedPatientProfile } = useSelector((state) => state.patient);
  const [openCampaignEdit, setOpenCampaignEdit] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [anchorCampaign, setAnchorCampaign] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [enrollments, setEnrollments] = useState([]);
  const popoverActions = useRef();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    if (selectedPatientProfile) {
      dispatch(
        getPatientEnrollments(
          selectedPatientProfile.info.patient_id,
          selectedPatientProfile
        )
      );
    }
  }, [selectedPatientProfile?.info.patient_id]);

  useEffect(() => {
    if (selectedPatientProfile) {
      setEnrollments(selectedPatientProfile.enrollments);
    }
  }, [selectedPatientProfile?.enrollments]);

  const onCloseCampaignEdit = () => {
    setOpenCampaignEdit(false);
    setSelectedCampaign(null);
    setAnchorCampaign(null);
    setCampaignToDelete(null);
  };

  const onEditCampaign = (target, campaign) => {
    setOpenCampaignEdit(true);
    setSelectedCampaign(campaign);
    setAnchorCampaign(target);
  };

  const onPauseCampaign = (campaign) => {
    // todo pause campaign
  };

  const onDeleteCampaign = (campaign) => {
    setOpenDeleteModal(true);
    setCampaignToDelete(campaign);
  };

  const deleteCampaign = () => {
    dispatch(
      cancelUpcomingMessage(
        campaignToDelete.campaign_id,
        campaignToDelete.campaign_patient_id,
        () => {
          dispatch(
            getPatientEnrollments(
              selectedPatientProfile.info.patient_id,
              selectedPatientProfile
            )
          );
        }
      )
    );
    setCampaignToDelete(null);
  };

  return (
    <div>
      <div className="md:max-w-2xl">
        {enrollments && enrollments.length > 0 ? (
          enrollments.map((campaign) => (
            <EnrollmentCard
              key={campaign.campaign_id}
              enrollment={campaign}
              onEdit={onEditCampaign}
              onPause={onPauseCampaign}
              onDelete={onDeleteCampaign}
            />
          ))
        ) : (
          <p className="text-sm font-semibold">No enrollments</p>
        )}
      </div>

      {selectedCampaign && anchorCampaign && openCampaignEdit && isDesktop && (
        <Popover
          action={popoverActions}
          open={openCampaignEdit}
          onClose={onCloseCampaignEdit}
          anchorEl={anchorCampaign}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              marginLeft: isDesktop ? "8px" : "0px",
              borderRadius: "12px",
              width: 356,
              boxShadow:
                "0px 32px 64px -12px rgba(16, 24, 40, 0.14), 0px 4px 4px rgba(0, 0, 0, 0.05), 10px 10px 20px rgba(0, 0, 0, 0.02)",
            },
          }}
        >
          <CampaignEditPopup
            campaign={selectedCampaign}
            patient={selectedPatientProfile.info}
            onClose={onCloseCampaignEdit}
            popoverActions={popoverActions}
          />
        </Popover>
      )}

      {selectedCampaign && openCampaignEdit && !isDesktop && (
        <Modal
          className="rounded-2xl overflow-hidden"
          onClickAway={onCloseCampaignEdit}
        >
          <div className="w-full sm:w-134">
            <CampaignEditPopup
              campaign={selectedCampaign}
              patient={selectedPatientProfile.info}
              onClose={onCloseCampaignEdit}
              popoverActions={popoverActions}
            />
          </div>
        </Modal>
      )}

      {openDeleteModal && campaignToDelete && (
        <DeleteModal
          title="Delete Campaign"
          description="Are you sure you want to stop this campaign? This action cannot be undone."
          onClose={() => {
            setOpenDeleteModal(false);
            setCampaignToDelete(null);
          }}
          onSubmit={deleteCampaign}
        />
      )}
    </div>
  );
};

export default EnrollmentsTab;
