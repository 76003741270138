import React, { useState, Suspense, lazy } from "react";
import { useMediaQuery } from "react-responsive";
import { TabsOrientation } from "../helpers/constants";
import Layout from "../components/Layout";
import TabsBorder from "../components/Basic/TabsBorder";
import Loader from "../components/Basic/Loader";

const Profile = lazy(() => import("../components/Settings/Profile"));
const General = lazy(() => import("../components/Settings/General"));
const Language = lazy(() => import("../components/Settings/Language"));
const Organization = lazy(() => import("../components/Settings/Organization"));
const Assistants = lazy(() => import("../components/Settings/Assistants"));

const SettingsTabs = {
  ACCOUNT: "Account",
  MESSAGES: "Messages",
  LANGUAGE: "Language",
  ORGANIZATION: "Organization",
  ASSISTANTS: "Assistants",
};

const Settings = () => {
  const { ACCOUNT, MESSAGES, LANGUAGE, ORGANIZATION, ASSISTANTS } =
    SettingsTabs;
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("settingsTab") || ACCOUNT
  );
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const setActiveTabSettings = (tab) => {
    setActiveTab(tab);
    sessionStorage.setItem("settingsTab", tab);
  };

  return (
    <Layout>
      <div className="pb-5 md:p-6 h-full w-full md:grid overflow-y-auto md:bg-gray-background">
        <div
          className="grid grid-rows-tab-layout md:flex md:flex-row md:gap-2 xl:gap-4 w-full h-full
            md:overflow-y-hidden px-4 md:p-0 md:pb-1"
        >
          <div
            className="h-16 md:h-full overflow-x-auto scrollbar mb-6 md:mb-0 w-full md:max-w-[165px] xl:max-w-[270px]
              md:bg-white md:pl-5 md:py-5 rounded-lg md:border border-gray-forground"
          >
            <TabsBorder
              tabs={Object.values(SettingsTabs)}
              orientation={
                isDesktop
                  ? TabsOrientation.VERTICAL
                  : TabsOrientation.HORIZONTAL
              }
              activeTab={activeTab}
              setActiveTab={setActiveTabSettings}
              className="border-b-2 md:border-b-0 md:border-l-2 border-gray-200 h-fit"
              tabClassName="py-4 md:px-5 text-sm md:text-base px-2"
            />
          </div>

          <div className="rounded-lg border border-gray-forground py-2 pr-px w-full h-full overflow-hidden bg-white">
            <div className="h-full overflow-y-auto scrollbar py-3 xl:py-9 pl-5 xl:pl-11 pr-4.5 xl:pr-10.5">
              <Suspense
                fallback={
                  <div className="w-full h-full flex items-center justify-center">
                    <Loader />
                  </div>
                }
              >
                {activeTab === ACCOUNT && <Profile />}
                {activeTab === MESSAGES && <General />}
                {activeTab === LANGUAGE && <Language />}
                {activeTab === ORGANIZATION && <Organization />}
                {activeTab === ASSISTANTS && <Assistants />}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
