import React, { useEffect, useMemo, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPatient,
  searchPatients,
  removePatients,
  updatePatient,
} from "../store/patient/thunks";
import { setSelectedPatientsAction } from "../store/patient/actions";
import {
  selectConversationAction,
  setConversationAction,
} from "../store/conversation/actions";
import {
  getConversation,
  searchConversationsWithFilters,
} from "../store/conversation/thunks";
import { convPerPage } from "../helpers/constants";
import IndeterminateCheckbox from "../components/Patients/IndeterminateCheckbox";
import {
  DateCell,
  PhoneCell,
  TagCell,
} from "../components/Patients/PatientsTable";
import Layout from "../components/Layout";
import PatientModal from "../components/Patients/PatientModal";
import ImportModal from "../components/Patients/ImportModal";
import DeleteModal from "../components/Patients/DeleteModal";
import TableHeader from "../components/Patients/TableHeader";
import Loader from "../components/Basic/Loader";

const PatientsTable = lazy(() =>
  import("../components/Patients/PatientsTable")
);

const Patients = () => {
  const { patientsPage, rowsPerPage, searchValue, selectedPatients, currPage } =
    useSelector((state) => state.patient);
  const {
    currentConversation,
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
  } = useSelector((state) => state.conversation);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [selectedPatientsState, setSelectedPatientsState] =
    useState(selectedPatients);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [canselSelection, setCanselSelection] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      searchPatients(
        searchValue,
        1,
        rowsPerPage * currPage,
        patientsPage === null
      )
    );
  }, []);

  useEffect(() => {
    dispatch(setSelectedPatientsAction(selectedPatientsState));
  }, [selectedPatientsState]);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps, flatRows }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              onClick={(e) => {
                const rows = flatRows.map((row) => row.original);
                const unselectedFlatRows = flatRows.filter(
                  (flatRow) => !flatRow.isSelected
                );
                const unselectedRows = unselectedFlatRows.map(
                  (row) => row.original
                );

                if (e.target.checked) {
                  setSelectedPatientsState((selectedPatientsState) => [
                    ...selectedPatientsState,
                    ...unselectedRows,
                  ]);
                } else {
                  setSelectedPatientsState((selectedPatientsState) =>
                    selectedPatientsState.filter(
                      (selectedPatient) =>
                        !rows.some(
                          (row) => selectedPatient.patient_id === row.patient_id
                        )
                    )
                  );
                }
              }}
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              onClick={() => {
                if (row.isSelected) {
                  setSelectedPatientsState((selectedPatientsState) =>
                    selectedPatientsState.filter(
                      (patient) =>
                        patient.patient_id !== row.original.patient_id
                    )
                  );
                } else {
                  setSelectedPatientsState((selectedPatientsState) => [
                    ...selectedPatientsState,
                    row.original,
                  ]);
                }
              }}
            />
          </div>
        ),
      },
      {
        Header: "Preferred Name",
        accessor: "preffered_name",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Date of Birth",
        accessor: "birthdate",
        Cell: DateCell,
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
        Cell: PhoneCell,
      },
      {
        Header: "Enrollments",
        accessor: "tags",
        Cell: TagCell,
      },
    ],
    []
  );

  const onAddPatient = () => {
    setOpenPatientModal(true);
  };

  const onImportPatients = () => {
    setOpenImportModal(true);
  };

  const onDeletePatients = (patient) => {
    if (patient) setSelectedPatient(patient);
    setOpenDeleteModal(true);
  };

  const onEditPatient = (row) => {
    setPatientData(row.original);
    setOpenPatientModal(true);
  };

  const deletePatients = () => {
    const patientIds = selectedPatient
      ? [selectedPatient.patient_id]
      : selectedPatientsState.map((patient) => patient.patient_id);
    dispatch(
      removePatients(patientIds, 1, rowsPerPage * currPage, searchValue, () => {
        setCanselSelection(true);
        setSelectedPatient(null);

        if (
          currentConversation &&
          patientIds.includes(currentConversation.patient.patient_id)
        ) {
          dispatch(selectConversationAction(""));
          dispatch(setConversationAction(null));
        }
      })
    );
  };

  const addUpdatePatient = (newData) => {
    if (patientData) {
      dispatch(
        updatePatient(newData, 1, rowsPerPage * currPage, searchValue, () => {
          setOpenPatientModal(false);
          dispatch(getConversation(currentConversation.conversation_id));
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              1,
              convPerPage * convCurrPage,
              activeInbox
            )
          );
        })
      );
    } else {
      dispatch(
        createPatient(newData, 1, rowsPerPage * currPage, searchValue, () =>
          setOpenPatientModal(false)
        )
      );
    }
  };

  return (
    <Layout>
      <div className="p-4 md:p-6 w-full h-full overflow-hidden md:bg-gray-background">
        <div className="rounded-lg border border-gray-forground h-full w-full grid grid-rows-tab-layout bg-white overflow-hidden">
          <TableHeader
            selectedPatients={selectedPatientsState}
            setCanselSelection={setCanselSelection}
            onAddPatient={onAddPatient}
            onImportPatients={onImportPatients}
          />

          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            }
          >
            <PatientsTable
              columns={columns}
              data={patientsPage || []}
              selectedPatients={selectedPatientsState}
              setSelectedPatients={setSelectedPatientsState}
              canselSelection={canselSelection}
              setCanselSelection={setCanselSelection}
              onEdit={onEditPatient}
              onAdd={onAddPatient}
              onImport={onImportPatients}
              onDelete={onDeletePatients}
            />
          </Suspense>
        </div>
      </div>

      {openPatientModal && (
        <PatientModal
          onClose={() => {
            setOpenPatientModal(false);
            setPatientData(null);
          }}
          onSave={addUpdatePatient}
          patientData={patientData}
        />
      )}
      {openImportModal && (
        <ImportModal onClose={() => setOpenImportModal(false)} />
      )}
      {openDeleteModal && (
        <DeleteModal
          onClose={() => {
            setOpenDeleteModal(false);
            setSelectedPatient(null);
          }}
          onSubmit={deletePatients}
          patients={selectedPatient ? [selectedPatient] : selectedPatientsState}
        />
      )}
    </Layout>
  );
};

export default Patients;
