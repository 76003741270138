import axios from "axios";
import { BASE_URL } from "../../helpers/config";
import { setNotificationAction } from "../user/actions";
import {
  setCampaignLoaderAction,
  setCampaignsAction,
  setCampaignTagsAction,
  setSelectedCampaignAction,
  setUpcomingMessagesAction,
} from "./actions";
import { setPatientLoaderAction } from "../patient/actions";

export const getCampaigns = () => (dispatch) => {
  dispatch(setCampaignLoaderAction(true));
  axios.get(`${BASE_URL}/campaigns/list`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load campaigns",
        })
      );
    } else {
      dispatch(setCampaignsAction(response.data.data.campaigns));
    }
    dispatch(setCampaignLoaderAction(false));
  });
};

export const getCampaignTags = () => (dispatch) => {
  axios.get(`${BASE_URL}/customer/tags`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load tags",
        })
      );
    } else {
      dispatch(setCampaignTagsAction(response.data.data.tags));
    }
  });
};

export const getCampaign = (campaignId) => (dispatch) => {
  axios.get(`${BASE_URL}/campaigns/describe/${campaignId}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load campaign",
        })
      );
    } else {
      dispatch(setSelectedCampaignAction(response.data.data.campaign));
    }
  });
};

export const getUpcomingMessages = (campaignIds) => (dispatch) => {
  axios
    .post(`${BASE_URL}/campaigns/next-checkins`, {
      campaign_ids: campaignIds,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load upcoming messages",
          })
        );
      } else {
        dispatch(setUpcomingMessagesAction(response.data.data.next_checkins));
      }
    });
};

export const updateCampaignDetails =
  (
    campaignId,
    name,
    description,
    firstMessage,
    checkinMessage,
    lastMessage,
    urgentMessage,
    breakMessage,
    onEnd = () => {}
  ) =>
  (dispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/update/${campaignId}`, {
        campaign: {
          campaign: name,
          description: description,
          first_message: firstMessage,
          checkin_message: checkinMessage,
          last_message: lastMessage,
          urgent_message: urgentMessage,
          break_message: breakMessage,
        },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update campaign",
            })
          );
        } else {
          dispatch(getCampaigns());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated",
            })
          );
        }
        dispatch(setCampaignLoaderAction(true));
        onEnd();
      });
  };

export const updateCampaignControls =
  (
    campaignId,
    minQuestions,
    maxQuestions,
    defaultStart,
    defaultFrequency,
    reminderActive,
    reminderMessage,
    reminderTimes,
    firstFollowup,
    onEnd = () => {}
  ) =>
  (dispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/update/${campaignId}`, {
        campaign: {
          min_questions: minQuestions,
          max_questions: maxQuestions,
          default_time_to_send: defaultStart,
          default_frequency: defaultFrequency,
          reminder_active: reminderActive,
          reminder_message: reminderMessage,
          reminder_times: reminderTimes,
          first_followup: firstFollowup,
        },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update campaign",
            })
          );
        } else {
          dispatch(getCampaigns());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated",
            })
          );
        }
        dispatch(setCampaignLoaderAction(true));
        onEnd();
      });
  };

export const updateCampaign =
  (
    campaignId,
    patientIds,
    outreach_focus,
    outreach_goals,
    ehr_information,
    reschedule = false,
    onSuccess = () => {}
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/schedule/${campaignId}`, {
        patient_ids: patientIds,
        outreach_focus: outreach_focus,
        outreach_goals: outreach_goals,
        ehr_information: ehr_information,
        reschedule: reschedule,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update campaign",
            })
          );
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated for selected patients",
            })
          );
        }
        dispatch(setPatientLoaderAction(false));
      });
  };

export const scheduleCampaign =
  (
    campaignId,
    patientIds,
    start,
    frequency,
    outreach_focus,
    outreach_goals,
    ehr_information,
    reschedule = false,
    onSuccess = () => {}
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/schedule/${campaignId}`, {
        patient_ids: patientIds,
        start: start,
        frequency: frequency,
        outreach_focus: outreach_focus,
        outreach_goals: outreach_goals,
        ehr_information: ehr_information,
        reschedule: reschedule,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to schedule campaign",
            })
          );
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: reschedule
                ? "Successfully updated campaign"
                : "Successfully scheduled campaign",
              desc: reschedule
                ? "Campaign has been successfully updated for selected patient"
                : "Campaign has been successfully scheduled for selected patients",
            })
          );
        }
        dispatch(setPatientLoaderAction(false));
      });
  };

export const cancelUpcomingMessage =
  (campaignId, campaignPatientId, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/campaigns/cancel-checkin`, {
        campaign_id: campaignId,
        campaign_patient_id: campaignPatientId,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to cancel upcoming message",
            })
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully cancelled upcoming message",
              desc: "Upcoming message has been successfully cancelled",
            })
          );
          onSuccess();
        }
      });
  };
