import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientNotes } from "../../store/patient/thunks";
import { setProfileLoadingAction } from "../../store/patient/actions";
import NoteCard from "../Basic/NoteCard";

const NotesTab = () => {
  const { selectedPatientProfile } = useSelector((state) => state.patient);
  const [notes, setNotes] = useState([]);
  const [groupedNotes, setGroupedNotes] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;
    dispatch(setProfileLoadingAction(true));

    if (selectedPatientProfile) {
      dispatch(
        getPatientNotes(
          selectedPatientProfile.info.patient_id,
          selectedPatientProfile,
          () => dispatch(setProfileLoadingAction(false))
        )
      );

      interval = setInterval(() => {
        dispatch(
          getPatientNotes(
            selectedPatientProfile.info.patient_id,
            selectedPatientProfile,
            () => dispatch(setProfileLoadingAction(false))
          )
        );
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [selectedPatientProfile?.info.patient_id]);

  useEffect(() => {
    if (selectedPatientProfile && selectedPatientProfile.notes) {
      setNotes(selectedPatientProfile.notes);
    }
  }, [selectedPatientProfile?.notes]);

  useEffect(() => {
    let groups = {};
    if (notes && notes.length > 0) {
      groups = notes.reduce((acc, note) => {
        const startDate = new Date(note.started_at.replace(/-/g, "/"));
        const monthYear = startDate.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        });
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(note);
        return acc;
      }, {});
    }
    setGroupedNotes(groups);
  }, [notes]);

  return (
    <div className="max-w-2xl">
      {groupedNotes && Object.keys(groupedNotes).length > 0 ? (
        <div className="flex flex-col space-y-2">
          {Object.entries(groupedNotes)
            .sort((a, b) => {
              const dateA = new Date(a[0]);
              const dateB = new Date(b[0]);
              return dateB - dateA;
            })
            .map(([monthYear, notes]) => (
              <div key={monthYear} className="space-y-2">
                <div className="flex items-center justify-between">
                  <p className="text-base font-semibold">{monthYear}</p>
                  {/*<Button*/}
                  {/*  variant="gray"*/}
                  {/*  className="text-sm font-semibold"*/}
                  {/*  disabled*/}
                  {/*>*/}
                  {/*  View CCM Note*/}
                  {/*</Button>*/}
                </div>
                <div className="md:border-l md:ml-3 md:pl-6 pt-3 pb-4 space-y-3">
                  {notes.map((note, index) => (
                    <NoteCard
                      key={index}
                      note={note}
                      patient={selectedPatientProfile.info}
                      onSaveNote={() =>
                        dispatch(
                          getPatientNotes(
                            selectedPatientProfile.info.patient_id,
                            selectedPatientProfile
                          )
                        )
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div>
          <p className="text-base font-semibold mb-2">
            {new Date().toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </p>
          <div className="md:border-l md:ml-3 md:pl-6 pt-3 pb-4">
            <p className="text-sm font-semibold">No notes</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotesTab;
