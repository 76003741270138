import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage, suggestAnswer } from "../../../store/conversation/thunks";
import { setClosedResponsesAction } from "../../../store/conversation/actions";
import VerticalDivider from "../../Basic/VerticalDivider";
import LoaderDots from "../../Basic/LoaderDots";
import TooltipMui from "../../Basic/TooltipMui";
import MobileInputArea from "./MobileInputArea";
import { ReactComponent as SendIcon } from "../../../assets/icons/send.svg";
import { ReactComponent as WandIcon } from "../../../assets/icons/wand.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { convPerPage } from "../../../helpers/constants";

const InputTabs = {
  REPLY: "Reply",
};

const InputArea = ({
  setScrolledUp,
  messages,
  setMessages,
  showResponse,
  setShowResponse,
}) => {
  const { REPLY } = InputTabs;
  const [activeTab, setActiveTab] = useState(REPLY);
  const [suggestedAnswerData, setSuggestedAnswerData] = useState("");
  const [conversationChanged, setConversationChanged] = useState(true);
  const {
    currentConversation,
    currentConversationId,
    conversationSearchInput,
    activeFilters,
    activeInbox,
    closedResponses,
    convCurrPage,
  } = useSelector((state) => state.conversation);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    setSuggestedAnswerData(currentConversation.suggested_answer);
    if (conversationChanged) {
      if (currentConversation.suggested_answer) {
        setShowResponse(!closedResponses.includes(currentConversationId));
      } else {
        setShowResponse(false);
      }
      setConversationChanged(false);
    }
  }, [currentConversation]);

  useEffect(() => {
    setConversationChanged(true);
    if (currentConversationId && !messages[currentConversationId]) {
      setMessages({ ...messages, [currentConversationId]: "" });
    }
  }, [currentConversationId]);

  const onSendMessage = () => {
    if (messages[currentConversationId].trim()) {
      dispatch(
        sendMessage(
          messages[currentConversationId],
          currentConversationId,
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          activeInbox
        )
      );
      setScrolledUp(false);
    }
    setMessages({ ...messages, [currentConversationId]: "" });
  };

  const onResponse = () => {
    setSuggestedAnswerData(currentConversation.suggested_answer);
    if (!showResponse) {
      if (!currentConversation.suggested_answer) {
        dispatch(suggestAnswer(currentConversationId));
      }
      setShowResponse(true);
    } else setShowResponse(false);
  };

  const onKeyDown = (event) => {
    if (
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      event.key === "Enter"
    ) {
      event.preventDefault();
      onSendMessage();
    }
  };

  return isDesktop ? (
    <div className="px-4 pb-4 w-full max-w-5xl mx-auto">
      <div className="bg-white rounded-lg border border-gray-200 ">
        {showResponse && currentConversation.show_response && (
          <div className="border-b pb-2 pt-4 px-4 space-y-2">
            <div className="rounded-lg p-2 bg-gray-150">
              {suggestedAnswerData ? (
                <p className="max-h-34 overflow-y-auto scrollbar px-2 text-xs text-black font-normal">
                  <TextareaAutosize
                    value={suggestedAnswerData}
                    disabled
                    className="w-full resize-none bg-transparent outline-none overflow-hidden"
                  />
                </p>
              ) : (
                <div className="w-fit mx-auto py-2">
                  <LoaderDots />
                </div>
              )}
            </div>
            <div className="flex items-center justify-end space-x-1.5 text-xs font-semibold text-primary-blue">
              <button
                aria-label="Close response"
                onClick={() => {
                  setShowResponse(false);
                  if (!closedResponses.includes(currentConversationId)) {
                    dispatch(setClosedResponsesAction(currentConversationId));
                  }
                }}
              >
                <CloseIcon stroke="#2970FF" height="14" width="14" />
              </button>
              <VerticalDivider className="h-[14px]" />
              <button
                aria-label="Use response"
                onClick={() => {
                  setMessages({
                    ...messages,
                    [currentConversationId]: suggestedAnswerData,
                  });
                  setShowResponse(false);
                }}
              >
                Use
              </button>
            </div>
          </div>
        )}

        <div className="py-4 rounded-b-lg">
          <TextareaAutosize
            value={messages[currentConversationId]}
            onChange={(e) =>
              setMessages({
                ...messages,
                [currentConversationId]: e.target.value,
              })
            }
            onKeyDown={onKeyDown}
            placeholder="Type a message"
            minRows={4}
            maxRows={20}
            className="w-full resize-none scrollbar border-r-8 border-white pl-4 pr-2 pt-1 outline-none
                focus:outline-none focus:ring-0 text-xs bg-transparent align-top mb-1 leading-[1.3]"
          />

          <div className="px-4 h-8 flex items-end justify-end">
            {activeTab === REPLY &&
              (currentConversation.show_response ? (
                <button
                  className="flex items-center space-x-2 text-sm font-medium rounded-lg border py-1.25 px-2 mr-3 h-8
                    text-primary-blue border-primary-blue"
                  onClick={onResponse}
                >
                  <p>Ask AI</p>
                  <WandIcon width="16" height="16" stroke="#2970FF" />
                </button>
              ) : (
                <TooltipMui title="Conversation in progress" arrow>
                  <div
                    className="flex items-center space-x-2 text-sm font-medium rounded-lg border py-1.25 px-2 mr-3 h-8
                      text-zinc-500 border-gray-200 cursor-default"
                  >
                    <p>Ask AI</p>
                    <WandIcon width="16" height="16" stroke="#71717A" />
                  </div>
                </TooltipMui>
              ))}
            <button
              aria-label="Send message"
              className="w-8 h-8 bg-primary-blue rounded-lg flex items-center justify-center"
              onClick={onSendMessage}
            >
              <SendIcon width="18" height="18" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MobileInputArea
      messages={messages}
      setMessages={setMessages}
      onSendMessage={onSendMessage}
      suggestedAnswer={suggestedAnswerData}
    />
  );
};

export default InputArea;
