import React, { useEffect, useRef, useState } from "react";
import { TabsOrientation } from "../../helpers/constants";
import { getTagVariant } from "../../helpers/helpers";
import Tag from "./Tag";

const TabsBorder = ({
  tabs,
  campaigns,
  showDesc,
  tags,
  activeTab,
  setActiveTab,
  className,
  activeBorderColor = "primary-blue",
  activeBgColor = "transparent",
  activeTextColor = "primary",
  tabClassName = "pb-2.5 px-2",
  orientation = TabsOrientation.HORIZONTAL,
  rerender = [],
}) => {
  const [translate, setTranslate] = useState(0);
  const [tabSizes, setTabSizes] = useState(null);
  const [transition, setTransition] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const isVertical = orientation === TabsOrientation.VERTICAL;
  const tabsContainerRef = useRef();
  const tabRefs = useRef([]);
  const tabSizesRef = useRef(tabSizes);
  const activeTabRef = useRef(activeTab);
  const isVerticalRef = useRef(isVertical);

  useEffect(() => {
    const id = setTimeout(() => {
      setTransition("transition-all");
    }, 500);

    const tabsContainer = tabsContainerRef?.current;
    if (!tabsContainer) return;

    const observer = new ResizeObserver(() => {
      updateTabSizes(tabRefs.current, isVerticalRef.current);
    });
    observer.observe(tabsContainer);

    return () => {
      clearTimeout(id);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    tabSizesRef.current = tabSizes;
  }, [tabSizes]);

  useEffect(() => {
    updateTabSizes(tabRefs.current, isVerticalRef.current);
  }, [tabRefs.current, orientation, ...rerender]);

  useEffect(() => {
    isVerticalRef.current = orientation === TabsOrientation.VERTICAL;
  }, [orientation]);

  useEffect(() => {
    if (campaigns) {
      setActiveIndex(
        tabs.findIndex((tab) => tab.campaign_id === activeTab.campaign_id)
      );
    } else setActiveIndex(tabs.indexOf(activeTab));
  }, [activeTab]);

  useEffect(() => {
    updateTranslate(tabSizes);
  }, [activeIndex, tabSizes]);

  const updateTabSizes = (tabs, isVertical) => {
    tabs.forEach((ref, index) =>
      setTabSizes((tabSizes) => ({
        ...tabSizes,
        [index]: isVertical ? ref.offsetHeight : ref.offsetWidth,
      }))
    );
  };

  const updateTranslate = (sizes) => {
    let shift = 0;
    if (sizes) {
      shift = Object.values(sizes)
        .slice(0, activeIndex)
        .reduce((a, b) => a + b, 0);
    }
    setTranslate(shift);
  };

  const getTab = (tab, index) => {
    let tag = null;
    if (campaigns && tags && tags.length > 0) {
      tag = tags.find((tag) => tag.tag_id === tab.tag_id);
    }

    return campaigns ? (
      <li
        key={tab.campaign_id}
        ref={(el) => (tabRefs.current[index] = el)}
        onClick={() => {
          setActiveTab(tab);
          activeTabRef.current = tab;
        }}
        className={`cursor-pointer font-semibold relative transition-all pl-4 flex items-center 
          md:items-start md:flex-col py-4 pr-2
          ${
            activeTab.campaign_id === tab.campaign_id
              ? "md:bg-sky-50 text-primary"
              : "text-gray-500 md:text-primary"
          } `}
      >
        <div className="flex md:grid grid-cols-conv-layout items-center justify-between gap-1.5 w-full">
          <p className="text-sm md:text-base font-semibold whitespace-nowrap truncate">
            {tab.campaign}
          </p>
          {tag && (
            <Tag
              key={tag.tag_id}
              text={tag.tag}
              variant={getTagVariant(tag.tag)}
              className="whitespace-nowrap py-1 px-2"
            />
          )}
        </div>

        {showDesc && tab.description && (
          <p className="text-xs text-zinc-700 mt-2.5">{tab.description}</p>
        )}
      </li>
    ) : (
      <li
        key={tab}
        ref={(el) => (tabRefs.current[index] = el)}
        onClick={() => {
          setActiveTab(tab);
          activeTabRef.current = tab;
        }}
        className={`cursor-pointer font-semibold relative transition-all ${tabClassName}
          ${
            activeTab === tab
              ? `text-${activeTextColor} bg-${activeBgColor}`
              : "text-gray-500"
          }`}
      >
        {tab}
      </li>
    );
  };

  return (
    <ul
      ref={tabsContainerRef}
      className={`flex w-fit min-w-full md:w-full relative 
        ${isVertical ? "flex-col" : "flex-row"} ${className}`}
    >
      {tabs.map((tab, index) => getTab(tab, index))}
      <div
        aria-hidden="true"
        style={{
          transform: isVertical
            ? `translateY(${translate}px)`
            : `translateX(${translate}px)`,
          width: isVertical
            ? campaigns
              ? "3px"
              : "2px"
            : tabSizes
            ? tabSizes[activeIndex] + "px"
            : "33px",
          height: isVertical
            ? tabSizes
              ? tabSizes[activeIndex] + "px"
              : "55px"
            : "2px",
        }}
        className={`absolute rounded-lg z-0 bg-${activeBorderColor} ${transition}
          ${
            isVertical
              ? `top-0 ${campaigns ? "left-0" : "-left-0.5"}`
              : "-bottom-0.5 left-0"
          }`}
      >
        {isVertical && !campaigns && (
          <>
            <div className="absolute top-0 left-0 h-3.5 w-full bg-white" />
            <div className="absolute bottom-0 left-0 h-3.5 w-full bg-white" />
          </>
        )}
      </div>
    </ul>
  );
};

export default TabsBorder;
