import React, { forwardRef } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-box.svg";

const checkboxVariants = {
  primary: "border-primary-blue bg-purple-50 text-primary-blue",
  dark: "border-blue-1000 bg-blue-1000 text-white",
};

const checkIconColor = {
  primary: "#2970FF",
  dark: "#FFFFFF",
};

const Checkbox = forwardRef(
  (
    {
      label,
      name,
      checked,
      onChange,
      className = "items-center",
      error,
      variant = "primary",
      number,
      ...props
    },
    ref
  ) => {
    return (
      <label className={`flex ${className}`}>
        <input
          name={name}
          type="checkbox"
          className="hidden"
          ref={ref}
          onChange={onChange}
          {...props}
        />
        <div
          className={`w-4 h-4 rounded border flex items-center justify-center cursor-pointer text-xs
            ${
              error
                ? "border-red-400"
                : checked
                ? checkboxVariants[variant]
                : "border-gray-300"
            }`}
        >
          {checked &&
            (number ? (
              <span>{number}</span>
            ) : (
              <CheckIcon
                width="10"
                height="8"
                stroke={checkIconColor[variant]}
              />
            ))}
        </div>
        {label && (
          <span
            className={`text-sm font-medium ml-2 ${
              error ? "text-red-400" : ""
            }`}
          >
            {label}
          </span>
        )}
      </label>
    );
  }
);
export default Checkbox;
