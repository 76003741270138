import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { getPatientInfo, updatePatient } from "../../store/patient/thunks";
import {
  getConversation,
  searchConversationsWithFilters,
} from "../../store/conversation/thunks";
import {
  convPerPage,
  genderOptions,
  pronounsOptions,
  sexOptions,
} from "../../helpers/constants";
import DateTimeShortcutsInput from "../Basic/DateTimeShortcutsInput";
import PhoneInput from "../Basic/PhoneInput";
import Input from "../Basic/Input";
import Button from "../Basic/Button";
import Select from "../Basic/Select";

const InformationTab = ({ patientInfo, editMode, setEditMode }) => {
  const { rowsPerPage, searchValue, currPage, selectedPatientProfile } =
    useSelector((state) => state.patient);
  const {
    currentConversation,
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
  } = useSelector((state) => state.conversation);
  const [showInputSex, setShowInputSex] = useState(false);
  const [sexNotListed, setSexNotListed] = useState("");
  const [sexError, setSexError] = useState(null);
  const [showInputGender, setShowInputGender] = useState(false);
  const [genderNotListed, setGenderNotListed] = useState("");
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 640 });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: patientInfo.first_name,
      middle_name: patientInfo.middle_name,
      last_name: patientInfo.last_name,
      birthdate: patientInfo.birthdate ? dayjs(patientInfo.birthdate) : null,
      phone_number: patientInfo.phone_number,
      email: patientInfo.email,
      sex: patientInfo.sex || "",
      gender: patientInfo.gender || "",
      pronouns: patientInfo.pronouns,
      preffered_name: patientInfo.preffered_name,
      address_line_1: patientInfo.address_line_1,
      address_line_2: patientInfo.address_line_2,
      country: patientInfo.country,
      state: patientInfo.state,
      city: patientInfo.city,
      zipcode: patientInfo.zipcode,
    },
  });

  const watchSex = watch("sex");
  const watchGender = watch("gender");

  useEffect(() => {
    if (patientInfo) {
      const isSexListed = sexOptions.some(
        (option) => option.value === patientInfo.sex
      );
      if (!isSexListed && patientInfo.sex) {
        setValue("sex", "notListed");
        setSexNotListed(patientInfo.sex);
      }
      const isGenderListed = genderOptions.some(
        (option) => option.value === patientInfo.gender
      );
      if (!isGenderListed && patientInfo.gender) {
        setValue("gender", "notListed");
        setGenderNotListed(patientInfo.gender);
      }
    }
  }, [patientInfo]);

  useEffect(() => {
    if (watchSex === "notListed") {
      setShowInputSex(true);
    } else {
      setSexNotListed("");
      setShowInputSex(false);
    }
  }, [watchSex]);

  useEffect(() => {
    if (watchGender === "notListed") {
      setShowInputGender(true);
    } else {
      setGenderNotListed("");
      setShowInputGender(false);
    }
  }, [watchGender]);

  useEffect(() => {
    checkInputSexOther();
  }, [sexNotListed]);

  const checkInputSexOther = () => {
    if (watchSex === "notListed" && !sexNotListed) {
      setSexError({ message: "Biological Sex is required" });
    } else {
      setSexError(null);
    }
  };

  useEffect(() => {
    if (patientInfo) setDefaultValues();
  }, [patientInfo]);

  const setDefaultValues = () => {
    if (patientInfo) {
      setValue("first_name", patientInfo.first_name);
      setValue("middle_name", patientInfo.middle_name);
      setValue("last_name", patientInfo.last_name);
      setValue("birthdate", dayjs(patientInfo.birthdate));
      setValue("preffered_name", patientInfo.preffered_name);
      setValue("pronouns", patientInfo.pronouns);
      setValue("phone_number", patientInfo.phone_number);
      setValue("email", patientInfo.email);
      setValue("address_line_1", patientInfo.address_line_1);
      setValue("address_line_2", patientInfo.address_line_2);
      setValue("country", patientInfo.country);
      setValue("state", patientInfo.state);
      setValue("city", patientInfo.city);
      setValue("zipcode", patientInfo.zipcode);

      if (patientInfo.sex) {
        if (!sexOptions.some((option) => option.value === patientInfo.sex)) {
          setShowInputSex(true);
          setSexNotListed(patientInfo.sex);
          setValue("sex", "notListed");
        } else {
          setValue("sex", patientInfo.sex);
        }
      }

      if (patientInfo.gender) {
        if (
          !genderOptions.some((option) => option.value === patientInfo.gender)
        ) {
          setShowInputGender(true);
          setGenderNotListed(patientInfo.gender);
          setValue("gender", "notListed");
        } else {
          setValue("gender", patientInfo.gender);
        }
      }
    }
  };

  const onSubmit = (data) => {
    if (patientInfo) {
      data = { ...data, patient_id: patientInfo.patient_id };
    }
    data = {
      ...data,
      birthdate: data.birthdate.format("YYYY-MM-DD"),
    };
    if (data.sex === "notListed") {
      data.sex = sexNotListed;
    }
    if (data.gender === "notListed") {
      data.gender = genderNotListed;
    }
    if (!sexError) {
      dispatch(
        updatePatient(data, 1, rowsPerPage * currPage, searchValue, () => {
          dispatch(
            getPatientInfo(patientInfo.patient_id, selectedPatientProfile)
          );
          dispatch(getConversation(currentConversation.conversation_id));
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              1,
              convPerPage * convCurrPage,
              activeInbox
            )
          );
          setEditMode(false);
        })
      );
    }
  };

  const onDiscard = (e) => {
    e.preventDefault();
    setEditMode(false);
    setDefaultValues();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4 mb-8">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-4 md:gap-y-6">
          <Input
            register={register}
            name="first_name"
            label="First Name"
            placeholder="Enter first name"
            required="First name is required"
            error={errors.first_name}
            disabled={!editMode}
          />
          <Input
            register={register}
            name="middle_name"
            label="Middle Name"
            placeholder="Enter middle name"
            disabled={!editMode}
          />
          <Input
            register={register}
            name="last_name"
            label="Last Name"
            placeholder="Enter last name"
            required="Last name is required"
            error={errors.last_name}
            disabled={!editMode}
          />
          {isDesktop && (
            <Select
              label="Pronouns"
              placeholder="Select Pronouns"
              name="pronouns"
              options={pronounsOptions}
              register={register}
              disabled={!editMode}
              className="lg:hidden"
            />
          )}

          <div className="grid sm:col-span-2 lg:col-span-3 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-4 md:gap-y-6">
            <Select
              label="Sex"
              placeholder="Select Sex"
              name="sex"
              options={sexOptions}
              register={register}
              required="Biological Sex is required"
              error={errors.sex}
              disabled={!editMode}
            />
            {showInputSex && !isDesktop && (
              <Input
                value={sexNotListed}
                onChange={(e) => setSexNotListed(e.target.value)}
                label="Specify Sex"
                placeholder="Enter Sex"
                required="Biological Sex is required"
                error={sexError}
                disabled={!editMode}
              />
            )}

            <Select
              label="Gender"
              placeholder="Select Gender"
              name="gender"
              options={genderOptions}
              register={register}
              disabled={!editMode}
            />
            {showInputGender && !isDesktop && (
              <Input
                value={genderNotListed}
                onChange={(e) => setGenderNotListed(e.target.value)}
                label="Specify Gender"
                placeholder="Enter Gender"
                disabled={!editMode}
              />
            )}

            {(showInputSex || showInputGender) && isDesktop && (
              <div className="col-span-2 grid grid-cols-2 gap-x-5">
                <div className="col-start-1">
                  {showInputSex && (
                    <Input
                      value={sexNotListed}
                      onChange={(e) => setSexNotListed(e.target.value)}
                      label="Specify Sex"
                      placeholder="Enter Sex"
                      required="Biological Sex is required"
                      error={sexError}
                      disabled={!editMode}
                    />
                  )}
                </div>
                <div className="col-start-2">
                  {showInputGender && (
                    <Input
                      value={genderNotListed}
                      onChange={(e) => setGenderNotListed(e.target.value)}
                      label="Specify Gender"
                      placeholder="Enter Gender"
                      disabled={!editMode}
                    />
                  )}
                </div>
              </div>
            )}

            <Select
              label="Pronouns"
              placeholder="Select Pronouns"
              name="pronouns"
              options={pronounsOptions}
              register={register}
              disabled={!editMode}
              className="sm:hidden lg:flex lg:row-start-1 lg:col-start-3"
            />
          </div>

          <DateTimeShortcutsInput
            name="birthdate"
            label="DOB"
            control={control}
            required="Date of birth is required"
            error={errors.birthdate}
            validateFunc={(value) => {
              const currentDate = dayjs();
              const selectedDate = dayjs(value);
              if (selectedDate.isAfter(currentDate, "day")) {
                return "Please choose a date in the past";
              }
              return true;
            }}
            maxDateTime={dayjs()}
            disabled={!editMode}
          />

          <Input
            register={register}
            name="preffered_name"
            label="Preferred Name"
            placeholder="Enter preferred name"
            disabled={!editMode}
          />

          <Input
            register={register}
            name="email"
            label="Email"
            placeholder="Enter email"
            type="email"
            error={errors.email}
            validationPattern={{
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email address",
            }}
            disabled={!editMode}
          />

          <PhoneInput
            label="Phone Number"
            name="phone_number"
            control={control}
            countries={["US", "AR", "BR", "CA", "IN"]}
            countryOptionsOrder={["US", "CA", "..."]}
            required="Phone number is required"
            error={errors.phone_number}
            disabled={!editMode}
          />
        </div>

        <div className="font-semibold text-sm">Address</div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-4 md:gap-y-6">
          <Input
            register={register}
            name="address_line_1"
            label="Line 1"
            placeholder="Enter address"
            disabled={!editMode}
          />
          <Input
            register={register}
            name="address_line_2"
            label="Line 2"
            placeholder="Enter address line 2"
            disabled={!editMode}
          />
          <Input
            register={register}
            name="city"
            label="City"
            placeholder="Enter city"
            disabled={!editMode}
          />
          <Input
            register={register}
            name="state"
            label="State"
            placeholder="Enter state"
            disabled={!editMode}
          />
          <Input
            register={register}
            name="country"
            label="Country"
            placeholder="Enter country"
            disabled={!editMode}
          />
          <Input
            register={register}
            name="zipcode"
            label="Zip Code"
            placeholder="Enter zip code"
            disabled={!editMode}
          />
        </div>
      </div>

      {editMode && (
        <div className="flex justify-end space-x-4 text-sm md:text-base">
          <Button
            variant="red"
            className="font-semibold w-fit"
            onClick={onDiscard}
          >
            Discard Changes
          </Button>
          <Button className="font-semibold w-32" onClick={checkInputSexOther}>
            Save
          </Button>
        </div>
      )}
    </form>
  );
};

export default InformationTab;
