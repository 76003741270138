import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  inboxes,
  statusActiveOptions,
  statusCompleteOptions,
  statusReviewOptions,
  timeOptions,
} from "../../../helpers/constants";
import FilterDropdownButton from "./FilterDropdownButton";
import FilterOther from "./FilterOther";
import FilterFlags from "./FilterFlags";
import { ReactComponent as StatusIcon } from "../../../assets/icons/status.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/icons/campaigns.svg";
import { ReactComponent as TimeIcon } from "../../../assets/icons/clock.svg";

const FilterDropdownButtons = ({ className }) => {
  const { activeInbox, campaignFilterOptions } = useSelector(
    (state) => state.conversation
  );
  const [openedDropdown, setOpenedDropdown] = useState("");

  const getStatusOptions = () => {
    switch (activeInbox) {
      case inboxes.REVIEW:
        return statusReviewOptions;
      case inboxes.ACTIVE:
        return statusActiveOptions;
      case inboxes.COMPLETE:
        return statusCompleteOptions;
    }
  };

  return (
    <div className={`flex w-full justify-between relative z-10 ${className}`}>
      <FilterDropdownButton
        filterName="status"
        icon={<StatusIcon width="20" height="20" fill="#3C3C47" />}
        arrow
        options={getStatusOptions()}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
        multipleSelect
      />
      <FilterDropdownButton
        filterName="campaigns"
        icon={<CampaignIcon width="25" height="20" stroke="#3C3C47" />}
        arrow
        options={campaignFilterOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
        multipleSelect
      />
      <FilterDropdownButton
        filterName="time"
        icon={<TimeIcon width="20" height="20" stroke="#3C3C47" />}
        arrow
        options={timeOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <FilterFlags
        filterName="flag"
        arrow
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <FilterOther
        arrow
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
    </div>
  );
};

export default FilterDropdownButtons;
