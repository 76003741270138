import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import RoundIcon from "./RoundIcon";
import { ReactComponent as CheckIcon } from "../../assets/icons/success-cloud.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

export const NotificationContent = ({ id, title, text }) => {
  return (
    <div className="grid items-start gap-5 grid-cols-conv-layout">
      <div className="text-sm overflow-hidden w-full">
        <p className="font-semibold text-gray-900">{title}</p>
        <p className="font-normal text-slate-600">{text}</p>
      </div>
      <button onClick={() => toast.dismiss(id)}>
        <CloseIcon stroke="#6B7280" width="20" height="20" />
      </button>
    </div>
  );
};

const Notifications = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <Toaster
      position="top-right"
      containerStyle={{
        top: isDesktop ? "24px" : "20px",
        right: isDesktop ? "32px" : "20px",
      }}
      toastOptions={{
        style: {
          maxWidth: 400,
          maxHeight: 250,
          boxShadow:
            "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
          borderRadius: "12px",
          border: "1px solid #F2F4F7",
          display: "flex",
          alignItems: "start",
          padding: "12px 6px 12px 12px",
          overflow: "hidden",
        },
        success: {
          icon: (
            <RoundIcon
              icon={<CheckIcon width="20" height="20" />}
              size="10"
              className="border-6 border-emerald-50 bg-emerald-100 mr-1.5"
            />
          ),
        },
        error: {
          icon: (
            <RoundIcon
              icon={<ErrorIcon width="20" height="20" />}
              size="10"
              className="border-6 border-red-50 bg-red-100 mr-1.5"
            />
          ),
        },
      }}
    />
  );
};

export default Notifications;
