import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";
import {
  selectConversationAction,
  setConversationAction,
  setConversationsCompletedTempAction,
} from "../../../store/conversation/actions";
import { completeConversation } from "../../../store/conversation/thunks";
import { getAge, getFormattedPhoneNumber } from "../../../helpers/helpers";
import { convPerPage, Pages } from "../../../helpers/constants";
import CircleDivider from "../../Basic/CircleDivider";
import ProfilePicture from "../../Basic/ProfilePicture";
import BeamLine from "../../Basic/BeamLine";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-circle.svg";

const TopBar = () => {
  const {
    conversationsCompletedTemp,
    currentConversation,
    currentConversationId,
    conversationSearchInput,
    activeFilters,
    activeInbox,
    conversationsCompleted,
    convCurrPage,
  } = useSelector((state) => state.conversation);
  const [startAnimation, setStartAnimation] = useState(false);
  const dispatch = useDispatch();
  const isXl = useMediaQuery({ minWidth: 1280 });
  const { patient } = currentConversation;

  useEffect(() => {
    setStartAnimation(false);
  }, [currentConversation]);

  const returnToConversationList = () => {
    dispatch(selectConversationAction(""));
    dispatch(setConversationAction(null));
  };

  const isConversationCompleted = () => {
    return (
      conversationsCompleted &&
      conversationsCompleted.all.find(
        (conv) => conv.lastMessage.conversation_id === currentConversationId
      )
    );
  };

  const onCompleteConversation = () => {
    setTimeout(() => {
      dispatch(
        setConversationsCompletedTempAction([
          ...conversationsCompletedTemp,
          currentConversationId,
        ])
      );
      dispatch(
        completeConversation(
          currentConversationId,
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          activeInbox
        )
      );
    }, 500);
  };

  return (
    <div
      className="md:h-20 py-3 md:py-4 pl-4 pr-8 md:px-4 xl:px-6 w-full flex items-center gap-3 bg-white md:rounded-lg
        border-b md:border border-gray-200"
    >
      <button
        aria-label="Return to conversation list"
        className="md:hidden"
        onClick={returnToConversationList}
      >
        <ArrowIcon className="rotate-90" width="10" height="7" />
      </button>

      <NavLink
        className="flex items-center gap-3 cursor-pointer w-full"
        to={`${Pages.PATIENTS}/${patient.patient_id}`}
        onClick={() => {
          sessionStorage.setItem("patientProfileTab", "Scribe");
        }}
      >
        <ProfilePicture
          src={patient.profile_picture}
          firstName={
            patient.preffered_name ? patient.preffered_name : patient.first_name
          }
          lastName={patient.last_name}
          size={isXl ? 12 : 10}
          fontSize="base"
          className="h-10 w-10 xl:h-12 xl:w-12"
        />

        <div>
          <p className="text-sm xl:text-base font-semibold">
            {(patient.preffered_name
              ? patient.preffered_name
              : patient.first_name) +
              " " +
              patient.last_name}
          </p>
          <div
            className="flex flex-wrap mt-0.5 text-xs xxl:text-sm text-tertiary gap-x-1.5 xxl:space-x-2 items-center
              md:whitespace-nowrap"
          >
            {patient.birthdate && (
              <>
                <p>Age {getAge(patient.birthdate)}</p>
                <CircleDivider />
              </>
            )}
            {(patient.gender || patient.sex) && (
              <div className="flex space-x-2 items-center">
                <p className="capitalize">
                  {patient.gender ? patient.gender : patient.sex}
                </p>
                {patient.pronouns && <CircleDivider />}
              </div>
            )}
            {patient.pronouns && (
              <p className="capitalize">{patient.pronouns}</p>
            )}
          </div>
        </div>
      </NavLink>

      <div className="ml-auto flex items-center text-sm font-bold">
        {conversationsCompleted && isConversationCompleted() ? (
          <div className="rounded-full w-10 h-10 flex items-center justify-center">
            <CheckIcon stroke="#21AF8D" width="20" height="20" />
          </div>
        ) : (
          <button
            className={`rounded-lg bg-emerald-550/10 text-emerald-550 h-10 w-28 
              flex items-center justify-center relative
              ${startAnimation ? "animate-complete-button" : ""}`}
            onClick={() => {
              setStartAnimation(true);
              onCompleteConversation();
            }}
          >
            {startAnimation ? (
              <>
                <CheckIcon stroke="#21AF8D" width="20" height="20" />
                <BeamLine rotate="0" />
                <BeamLine rotate="30" />
                <BeamLine rotate="-30" />
                <BeamLine rotate="60" />
                <BeamLine rotate="-60" />
                <BeamLine rotate="90" />
              </>
            ) : (
              <div className="flex items-center space-x-1">
                <CheckIcon stroke="#21AF8D" width="20" height="20" />
                <p>Complete</p>
              </div>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default TopBar;
