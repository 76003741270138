import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNextConversationIdAction } from "../../../store/conversation/actions";
import { inboxes } from "../../../helpers/constants";
import ConversationPreview from "./ConversationPreview";

const ConversationGroup = ({
  name,
  icon,
  conversations,
  messages,
  containerRef,
}) => {
  const { currentConversationId, activeInbox, loader } = useSelector(
    (state) => state.conversation
  );
  const [clickedConversation, setClickedConversation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeInbox !== inboxes.COMPLETE) {
      if (conversations && conversations.all.length > 1) {
        const index = conversations.all.findIndex(
          (conversation) =>
            conversation.lastMessage.conversation_id === currentConversationId
        );
        const nextIndex = conversations.all.length > index + 1 ? index + 1 : 0;
        const nextConversationId =
          conversations.all[nextIndex].lastMessage.conversation_id;
        dispatch(setNextConversationIdAction(nextConversationId));
      } else {
        dispatch(setNextConversationIdAction(""));
      }
    }
  }, [conversations]);

  return (
    <div className="px-4 pb-4">
      {name && (
        <div className="flex space-x-2 items-center pb-4 px-4">
          {icon}
          <p className="uppercase text-neutral-500 font-semibold text-sm">
            {name}
          </p>
        </div>
      )}
      {conversations && conversations.all.length > 0
        ? conversations.all.map((conversation) => (
            <ConversationPreview
              key={conversation.lastMessage.conversation_id}
              conversation={conversation}
              messages={messages}
              containerRef={containerRef}
              clickedConversation={clickedConversation}
              setClickedConversation={setClickedConversation}
            />
          ))
        : !loader &&
          conversations &&
          conversations.total_conversations === 0 && (
            <p className="w-full flex justify-center text-sm p-3">
              No conversations found
            </p>
          )}
    </div>
  );
};

export default ConversationGroup;
