import React, { forwardRef, useEffect, useRef } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-box.svg";

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <label>
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          ref={resolvedRef}
          {...rest}
        />
        <div
          className={`w-4 h-4 rounded border flex items-center justify-center cursor-pointer ${
            checked || indeterminate
              ? "border-primary-blue text-primary-blue bg-purple-50"
              : "border-gray-300"
          }`}
        >
          {checked && <CheckIcon width="10" height="8" />}
          {indeterminate && "-"}
        </div>
      </label>
    );
  }
);

export default IndeterminateCheckbox;
