import React from "react";
import { getAge } from "../../helpers/helpers";
import ProfilePicture from "./ProfilePicture";
import CircleDivider from "./CircleDivider";

const PatientInfo = ({ patient }) => {
  return (
    <div className="flex items-center space-x-2 truncate">
      <ProfilePicture
        src={patient.profile_picture}
        firstName={
          patient.preffered_name ? patient.preffered_name : patient.first_name
        }
        lastName={patient.last_name}
        flagPadding={false}
      />
      <div className="space-y-0.5 font-medium truncate">
        <p className="text-sm truncate">
          {patient.preffered_name ? patient.preffered_name : patient.first_name}{" "}
          {patient.last_name}
        </p>
        <div className="flex items-center space-x-2 text-xs text-zinc-450">
          {patient.birthdate && (
            <>
              <p>Age {getAge(patient.birthdate)}</p>
              <CircleDivider />
            </>
          )}
          <p className="capitalize">
            {patient.gender ? patient.gender : patient.sex}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
