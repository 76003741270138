import {
  LOGOUT,
  SET_COUNTRY,
  SET_ERROR,
  SET_NOTIFICATION,
  OPEN_SUCCESS_MODAL,
  SET_ROLES,
  SET_USER,
  SET_USERS,
  SET_ASSISTANTS,
  SET_SIGNUP_INFO,
} from "../types";

const init = {
  user: sessionStorage.user
    ? JSON.parse(sessionStorage.user)
    : localStorage.user
    ? JSON.parse(localStorage.user)
    : null,
  users: [],
  roles: {
    Admin: 1,
    User: 2,
  },
  errorMessage: "",
  notification: null,
  successModal: false,
  country: localStorage.country,
  assistants: [],
  signupInfo: {},
};

export default function userReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      return { ...init, user: null };
    case SET_USER:
      return { ...state, user: payload.user };
    case SET_USERS:
      return { ...state, users: payload.users };
    case SET_ROLES:
      return { ...state, roles: payload.roles };
    case SET_ERROR:
      return { ...state, errorMessage: payload.errorMessage };
    case SET_NOTIFICATION:
      return { ...state, notification: payload.notification };
    case OPEN_SUCCESS_MODAL:
      return { ...state, successModal: payload.successModal };
    case SET_COUNTRY:
      return { ...state, country: payload.country };
    case SET_ASSISTANTS:
      return { ...state, assistants: payload.assistants };
    case SET_SIGNUP_INFO:
      return { ...state, signupInfo: payload.signupInfo };
    default:
      return state;
  }
}
