import {
  SET_CAMPAIGN_LOADER,
  SET_CAMPAIGNS,
  SET_CURRENT_VIEW,
  SET_SELECTED_CAMPAIGN,
  SET_SELECTED_START_DATE,
  SET_SELECTED_EVENT,
  SET_SELECTED_TAB,
  SET_SELECTED_UPCOMING_POSITION,
  SET_UPCOMING_MESSAGES,
  SET_SELECTED_CAMPAIGN_IDS,
  SET_ENROLL_FIELDS,
  SET_SELECTED_ENROLL_PATIENTS,
  SET_RERENDER_ENROLL_FIELDS,
  SET_START_DATE_SHORTCUT,
  LOGOUT,
  SET_CAMPAIGN_TAGS,
  SET_MORE_EVENTS_POSITION,
  SET_MORE_EVENTS_DATE,
} from "../types";
import { CampaignTabs } from "../../helpers/constants";

const init = {
  campaigns: [],
  campaignTags: [],
  upcomingMessages: [],
  selectedCampaign: sessionStorage.selectedCampaign
    ? JSON.parse(sessionStorage.selectedCampaign)
    : null,
  selectedCampaignIds: sessionStorage.selectedCampaignIds
    ? JSON.parse(sessionStorage.selectedCampaignIds)
    : [],
  selectedTab: sessionStorage.campaignTab || CampaignTabs.ENROLL,
  selectedEnrollPatients: [],
  rerenderEnrollFields: false,
  enrollFields: {},
  selectedStartDate: null,
  activeStartDateShortcut: "Tomorrow",
  selectedUpcomingPosition: null,
  moreEventsPosition: null,
  moreEventsDate: null,
  selectedEvent: null,
  currentView: sessionStorage.calendarView || "week",
  loader: false,
};

export default function campaignReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      sessionStorage.removeItem("selectedCampaign");
      sessionStorage.removeItem("selectedCampaignIds");
      sessionStorage.removeItem("campaignTab");
      sessionStorage.removeItem("calendarView");
      return init;
    case SET_CAMPAIGNS:
      return { ...state, campaigns: payload.campaigns };
    case SET_CAMPAIGN_TAGS:
      return { ...state, campaignTags: payload.campaignTags };
    case SET_SELECTED_CAMPAIGN:
      if (payload.campaign) {
        sessionStorage.setItem(
          "selectedCampaign",
          JSON.stringify(payload.campaign)
        );
      }
      return { ...state, selectedCampaign: payload.campaign };
    case SET_SELECTED_CAMPAIGN_IDS:
      sessionStorage.setItem(
        "selectedCampaignIds",
        JSON.stringify(payload.campaignIds)
      );
      return { ...state, selectedCampaignIds: payload.campaignIds };
    case SET_SELECTED_TAB:
      sessionStorage.setItem("campaignTab", payload.selectedTab);
      return { ...state, selectedTab: payload.selectedTab };
    case SET_SELECTED_ENROLL_PATIENTS:
      return { ...state, selectedEnrollPatients: payload.selectedPatients };
    case SET_RERENDER_ENROLL_FIELDS:
      return { ...state, rerenderEnrollFields: payload.rerenderEnrollFields };
    case SET_ENROLL_FIELDS:
      return { ...state, enrollFields: payload.enrollFields };
    case SET_SELECTED_START_DATE:
      return { ...state, selectedStartDate: payload.selectedStartDate };
    case SET_START_DATE_SHORTCUT:
      return {
        ...state,
        activeStartDateShortcut: payload.activeStartDateShortcut,
      };
    case SET_UPCOMING_MESSAGES:
      return { ...state, upcomingMessages: payload.upcomingMessages };
    case SET_SELECTED_UPCOMING_POSITION:
      return { ...state, selectedUpcomingPosition: payload.position };
    case SET_MORE_EVENTS_POSITION:
      return { ...state, moreEventsPosition: payload.position };
    case SET_MORE_EVENTS_DATE:
      return { ...state, moreEventsDate: payload.date };
    case SET_SELECTED_EVENT:
      return { ...state, selectedEvent: payload.selectedEvent };
    case SET_CURRENT_VIEW:
      sessionStorage.setItem("calendarView", payload.currentView);
      return { ...state, currentView: payload.currentView };
    case SET_CAMPAIGN_LOADER:
      return { ...state, loader: payload.loader };
    default:
      return state;
  }
}
