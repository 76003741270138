import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { logoutAction } from "../../store/user/actions";
import { Pages } from "../../helpers/constants";
import ProfilePicture from "../Basic/ProfilePicture";
import { ReactComponent as LogoIcon } from "../../assets/logo-quadrant-text.svg";
import { ReactComponent as InboxIcon } from "../../assets/icons/inbox-new.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/patients-new.svg";
import { ReactComponent as BillingMetricsIcon } from "../../assets/icons/billing-new.svg";
import { ReactComponent as CampaignsIcon } from "../../assets/icons/campaigns.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-new.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";

//styles
const navBtn =
  "flex items-center justify-center whitespace-nowrap cursor-pointer gap-1 lg:gap-2 px-1 lg:px-2 ";
const activeNavBtn = "text-primary-blue ";
const iconStroke = "#404654";
const activeIconStroke = "#2970FF";
const iconSize = 24;

const Topbar = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);

  const onClickProfileImg = () => {
    setShowLogoutMenu(!showLogoutMenu);
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <nav className="flex items-center justify-between h-18 w-screen px-6 bg-white border-b border-gray-200">
      <div className="flex items-center">
        <NavLink
          aria-label="Inbox"
          to={Pages.MESSAGING}
          className="mr-8 lg:mr-24 "
        >
          <LogoIcon width="128" height="40" />
        </NavLink>
        <div className="flex items-center space-x-1 lg:space-x-3 text-gray-650 text-sm lg:text-base">
          <NavLink
            aria-label="Inbox"
            to={Pages.MESSAGING}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
          >
            {({ isActive }) => (
              <>
                <InboxIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                />
                <p>Inbox</p>
              </>
            )}
          </NavLink>
          <NavLink
            aria-label="Patients"
            to={Pages.PATIENTS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
          >
            {({ isActive }) => (
              <>
                <PatientIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                />
                <p>Patients</p>
              </>
            )}
          </NavLink>
          <NavLink
            aria-label="Campaigns"
            to={Pages.CAMPAIGNS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
          >
            {({ isActive }) => (
              <>
                <CampaignsIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height="22"
                />
                <p>Enroll</p>
              </>
            )}
          </NavLink>
          <NavLink
            aria-label="Billing Metrics"
            to={Pages.BILLING_METRICS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
          >
            {({ isActive }) => (
              <>
                <BillingMetricsIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                />
                <p>Time Tracking</p>
              </>
            )}
          </NavLink>
          <NavLink
            aria-label="Settings"
            to={Pages.SETTINGS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
          >
            {({ isActive }) => (
              <>
                <SettingsIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                />
                <p>Settings</p>
              </>
            )}
          </NavLink>
        </div>
      </div>
      <div className="relative">
        <ProfilePicture
          size={11}
          fontSize="base"
          className="cursor-pointer"
          onClick={onClickProfileImg}
          firstName={user.first_name}
          lastName={user.last_name}
        />
        {showLogoutMenu && (
          <ClickAwayListener onClickAway={() => setShowLogoutMenu(false)}>
            <div className="absolute top-0 right-full mr-4 h-11 w-36 py-3 bg-white rounded-lg shadow-menu z-30">
              <button
                className="flex items-center space-x-2 px-4 w-full"
                onClick={logout}
              >
                <LogoutIcon width="20" height="20" />
                <p className="text-sm text-zinc-700">Logout</p>
              </button>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </nav>
  );
};

export default Topbar;
