import {
  EXPAND_PANEL,
  SET_CONVERSATIONS,
  SET_CONVERSATIONS_COMPLETED,
  SET_CONVERSATION,
  SELECT_CONVERSATION,
  SET_LOADER,
  SET_COLLAPSE_LIST,
  COLLAPSE_SIDEBAR,
  SET_SEARCH_INPUT,
  ADD_SENT_MESSAGE,
  REMOVE_SENT_MESSAGE,
  SET_CONVERSATIONS_COMPLETED_TEMP,
  SET_NEXT_CONVERSATION_ID,
  ADD_FAILED_MESSAGE,
  REMOVE_FAILED_MESSAGE,
  SET_CLOSED_RESPONSES,
  SET_NOTE_MARKUP,
  SET_ENCOUNTER_NOTE_MARKUP,
  SET_ENCOUNTERS,
  SET_ENCOUNTER_ACTIVE_TAG,
  SET_ENCOUNTER_SEARCH_INPUT,
  SET_CONVERSATIONS_SCROLL_POSITION,
  SET_CONV_CURR_PAGE,
  SET_ACTIVE_FILTERS,
  SET_CONVERSATIONS_REVIEW,
  SET_ACTIVE_INBOX,
  SET_INIT_FILTERS,
  SET_CAMPAIGN_OPTIONS,
  SET_CONVERSATIONS_UPDATED,
  SET_ON_MESSAGE_CONVERSATION_ID,
  SET_CONVERSATIONS_COUNTS,
  SET_FILTERS_UPDATED,
  SET_OPEN_PATIENT_DETAILS,
} from "../types";

export function setConversationsReviewAction(conversationsReview) {
  return {
    type: SET_CONVERSATIONS_REVIEW,
    payload: {
      conversationsReview: conversationsReview,
    },
  };
}

export function setConversationsAction(conversations) {
  return {
    type: SET_CONVERSATIONS,
    payload: {
      conversations: conversations,
    },
  };
}

export function setConversationsScrollPositionAction(
  conversationsScrollPosition
) {
  return {
    type: SET_CONVERSATIONS_SCROLL_POSITION,
    payload: {
      conversationsScrollPosition: conversationsScrollPosition,
    },
  };
}

export function setConvCurrPageAction(convCurrPage) {
  return {
    type: SET_CONV_CURR_PAGE,
    payload: {
      convCurrPage: convCurrPage,
    },
  };
}

export function setConversationsCompletedAction(conversationsCompleted) {
  return {
    type: SET_CONVERSATIONS_COMPLETED,
    payload: {
      conversationsCompleted: conversationsCompleted,
    },
  };
}

export function setActiveInboxAction(activeInbox) {
  return {
    type: SET_ACTIVE_INBOX,
    payload: {
      activeInbox: activeInbox,
    },
  };
}

export function setConversationsCompletedTempAction(
  conversationsCompletedTemp
) {
  return {
    type: SET_CONVERSATIONS_COMPLETED_TEMP,
    payload: {
      conversationsCompletedTemp: conversationsCompletedTemp,
    },
  };
}

export function setConversationAction(conversation) {
  return {
    type: SET_CONVERSATION,
    payload: {
      conversation: conversation,
    },
  };
}

export function selectConversationAction(conversationId) {
  return {
    type: SELECT_CONVERSATION,
    payload: {
      conversationId: conversationId,
    },
  };
}

export function setNextConversationIdAction(conversationId) {
  return {
    type: SET_NEXT_CONVERSATION_ID,
    payload: {
      conversationId: conversationId,
    },
  };
}

export function expandPanelAction(state) {
  return {
    type: EXPAND_PANEL,
    payload: {
      state: state,
    },
  };
}

export function collapseSidebarAction(state) {
  return {
    type: COLLAPSE_SIDEBAR,
    payload: {
      state: state,
    },
  };
}

export function setLoaderAction(loader) {
  return {
    type: SET_LOADER,
    payload: {
      loader: loader,
    },
  };
}

export function setCollapseListAction(state) {
  return {
    type: SET_COLLAPSE_LIST,
    payload: {
      state: state,
    },
  };
}

export function setActiveFiltersAction(activeFilters) {
  return {
    type: SET_ACTIVE_FILTERS,
    payload: {
      activeFilters: activeFilters,
    },
  };
}

export function setInitFiltersAction(initFilters) {
  return {
    type: SET_INIT_FILTERS,
    payload: {
      initFilters: initFilters,
    },
  };
}

export function setCampaignOptionsAction(campaignFilterOptions) {
  return {
    type: SET_CAMPAIGN_OPTIONS,
    payload: {
      campaignFilterOptions: campaignFilterOptions,
    },
  };
}

export function setSearchInputAction(conversationSearchInput) {
  return {
    type: SET_SEARCH_INPUT,
    payload: {
      conversationSearchInput: conversationSearchInput,
    },
  };
}

export function addSentMessageAction(message, conversationId) {
  return {
    type: ADD_SENT_MESSAGE,
    payload: {
      message: message,
      conversationId: conversationId,
    },
  };
}

export function removeSentMessageAction(conversationId) {
  return {
    type: REMOVE_SENT_MESSAGE,
    payload: {
      conversationId: conversationId,
    },
  };
}

export function addFailedMessageAction(message, conversationId) {
  return {
    type: ADD_FAILED_MESSAGE,
    payload: {
      message: message,
      conversationId: conversationId,
    },
  };
}

export function removeFailedMessageAction(messageId, conversationId) {
  return {
    type: REMOVE_FAILED_MESSAGE,
    payload: {
      messageId: messageId,
      conversationId: conversationId,
    },
  };
}

export function setClosedResponsesAction(conversationId) {
  return {
    type: SET_CLOSED_RESPONSES,
    payload: {
      conversationId: conversationId,
    },
  };
}

export function setNoteMarkupAction(conversationId, noteMarkup) {
  return {
    type: SET_NOTE_MARKUP,
    payload: {
      conversationId: conversationId,
      noteMarkup: noteMarkup,
    },
  };
}

export function setEncountersAction(encounters) {
  return {
    type: SET_ENCOUNTERS,
    payload: {
      encounters: encounters,
    },
  };
}

export function setEncounterNoteMarkupAction(
  conversationId,
  encounterId,
  noteMarkup,
  openEditor
) {
  return {
    type: SET_ENCOUNTER_NOTE_MARKUP,
    payload: {
      conversationId: conversationId,
      encounterId: encounterId,
      noteMarkup: noteMarkup,
      openEditor: openEditor,
    },
  };
}

export function setEncounterActiveTagAction(encounterActiveTag) {
  return {
    type: SET_ENCOUNTER_ACTIVE_TAG,
    payload: {
      encounterActiveTag: encounterActiveTag,
    },
  };
}

export function setEncounterSearchInputAction(encounterSearchInput) {
  return {
    type: SET_ENCOUNTER_SEARCH_INPUT,
    payload: {
      encounterSearchInput: encounterSearchInput,
    },
  };
}

export function setConversationsUpdatedAction(conversationsUpdated) {
  return {
    type: SET_CONVERSATIONS_UPDATED,
    payload: {
      conversationsUpdated: conversationsUpdated,
    },
  };
}

export function setOnMessageConversationIdAction(onMessageConversationId) {
  return {
    type: SET_ON_MESSAGE_CONVERSATION_ID,
    payload: {
      onMessageConversationId: onMessageConversationId,
    },
  };
}

export function setConversationsCountsAction(inbox, conversationsCounts) {
  return {
    type: SET_CONVERSATIONS_COUNTS,
    payload: {
      inbox: inbox,
      conversationsCounts: conversationsCounts,
    },
  };
}

export function setFiltersUpdatedAction(filtersUpdated) {
  return {
    type: SET_FILTERS_UPDATED,
    payload: {
      filtersUpdated: filtersUpdated,
    },
  };
}

export function setOpenPatientDetailsAction(openPatientDetails) {
  return {
    type: SET_OPEN_PATIENT_DETAILS,
    payload: {
      openPatientDetails: openPatientDetails,
    },
  };
}
