import React from "react";

const Select = ({
  label,
  options,
  className,
  placeholder,
  required,
  withoutAsterisk,
  name,
  register = () => {},
  disabled,
  defaultValue,
  error,
  ...props
}) => {
  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && (
        <p className="font-semibold mb-1.5">
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <select
        name={name}
        {...register(name, {
          required: required,
        })}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        {...props}
        className={`p-2 px-3 rounded-lg border outline-none h-10 relative
          ${error ? "border-red-400" : "border-gray-300"}
          ${disabled ? "bg-gray-150 opacity-100" : "bg-white"}`}
      >
        {options.length > 0 ? (
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))
        ) : (
          <option disabled value="">
            No options
          </option>
        )}
      </select>

      {error && error.message && (
        <p className="text-red-400 font-normal mt-1.5">{error.message}</p>
      )}
    </div>
  );
};

export default Select;
