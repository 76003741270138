import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEventAction } from "../../store/campaign/actions";
import NotificationsWrapper from "./NotificationsWrapper";
import Topbar from "./Topbar";
import MobileMenu from "./MobileMenu";

const Layout = ({ children, className }) => {
  const { currentConversation } = useSelector((state) => state.conversation);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedEventAction(null));
  }, [window.location.pathname]);

  return (
    <NotificationsWrapper>
      <div
        className={`h-screen w-screen overflow-hidden md:pt-0 
          ${isDesktop ? "grid grid-rows-tab-layout" : ""}
          ${!isDesktop && currentConversation ? "pt-0" : "pt-16"}`}
      >
        {!isDesktop && currentConversation ? (
          <></>
        ) : isDesktop ? (
          <Topbar />
        ) : (
          <MobileMenu />
        )}
        <div className={`h-full w-full overflow-hidden ${className}`}>
          {children}
        </div>
      </div>
    </NotificationsWrapper>
  );
};

export default Layout;
