import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { setPatientExistsErrorAction } from "../../store/patient/actions";
import {
  genderOptions,
  pronounsOptions,
  sexOptions,
} from "../../helpers/constants";
import Input from "../Basic/Input";
import Button from "../Basic/Button";
import Modal from "../Basic/Modal";
import PhoneInput from "../Basic/PhoneInput";
import Select from "../Basic/Select";
import DateTimeShortcutsInput from "../Basic/DateTimeShortcutsInput";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const PatientModal = ({ onClose, patientData, onSave }) => {
  const { patientExistsError } = useSelector((state) => state.patient);
  const [showInputSex, setShowInputSex] = useState(false);
  const [sexNotListed, setSexNotListed] = useState("");
  const [sexError, setSexError] = useState(null);
  const [showInputGender, setShowInputGender] = useState(false);
  const [genderNotListed, setGenderNotListed] = useState("");
  const isDesktop = useMediaQuery({ minWidth: 640 });
  const dispatch = useDispatch();
  const edit = !!patientData;
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: edit ? patientData.first_name : "",
      middle_name: edit ? patientData.middle_name : "",
      last_name: edit ? patientData.last_name : "",
      birthdate: edit ? dayjs(patientData.birthdate) : null,
      phone_number: edit ? patientData.phone_number : "",
      email: edit ? patientData.email : "",
      sex: edit ? patientData.sex : "",
      gender: edit ? patientData.gender : "",
      pronouns: edit ? patientData.pronouns : "",
      preffered_name: edit ? patientData.preffered_name : "",
      address_line_1: edit ? patientData.address_line_1 : "",
      address_line_2: edit ? patientData.address_line_2 : "",
      country: edit ? patientData.country : "",
      state: edit ? patientData.state : "",
      city: edit ? patientData.city : "",
      zipcode: edit ? patientData.zipcode : "",
    },
  });

  useEffect(() => {
    dispatch(setPatientExistsErrorAction(false));
  }, []);

  useEffect(() => {
    if (patientExistsError) {
      setError("phone_number", {
        message: "Phone number already added",
      });
    } else {
      clearErrors("phone_number");
    }
  }, [patientExistsError]);

  const watchSex = watch("sex");
  const watchGender = watch("gender");

  useEffect(() => {
    if (patientData) {
      const isSexListed = sexOptions.some(
        (option) => option.value === patientData.sex
      );
      if (!isSexListed) {
        setValue("sex", "notListed");
        setSexNotListed(patientData.sex);
      }
      const isGenderListed = genderOptions.some(
        (option) => option.value === patientData.gender
      );
      if (!isGenderListed) {
        setValue("gender", "notListed");
        setGenderNotListed(patientData.gender);
      }
    }
  }, [patientData]);

  useEffect(() => {
    if (watchSex === "notListed") {
      setShowInputSex(true);
    } else {
      setSexNotListed("");
      setShowInputSex(false);
    }

    if (!patientData) {
      if (watchSex === "male") {
        setValue("pronouns", "he/him/his");
      } else if (watchSex === "female") {
        setValue("pronouns", "she/her/hers");
      } else {
        setValue("pronouns", "");
      }
    }
  }, [watchSex]);

  useEffect(() => {
    if (watchGender === "notListed") {
      setShowInputGender(true);
    } else {
      setGenderNotListed("");
      setShowInputGender(false);
    }
  }, [watchGender]);

  useEffect(() => {
    checkInputSexOther();
  }, [sexNotListed]);

  const checkInputSexOther = () => {
    if (watchSex === "notListed" && !sexNotListed) {
      setSexError({ message: "Biological Sex is required" });
    } else {
      setSexError(null);
    }
  };

  const onSubmit = (data) => {
    if (patientData) {
      data = { ...data, patient_id: patientData.patient_id };
    }
    data = {
      ...data,
      birthdate: data.birthdate.format("YYYY-MM-DD"),
    };
    if (data.sex === "notListed") {
      data.sex = sexNotListed;
    }
    if (data.gender === "notListed") {
      data.gender = genderNotListed;
    }

    if (!sexError) {
      onSave(data);
    }
  };

  return (
    <Modal className="p-6 pr-px rounded-2xl" onClickAway={onClose}>
      <div className="w-134 max-h-[85vh] overflow-y-auto scrollbar pr-6">
        <div className="flex items-center justify-between mb-6">
          <p className="text-base md:text-xl font-semibold">
            {edit ? "Edit Patient" : "New Patient"}
          </p>
          <button onClick={onClose}>
            <CloseIcon stroke="#737373" width="24" height="24" />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4 mb-8 sm:mb-12">
            <div className="grid sm:grid-cols-3 gap-5">
              <Input
                register={register}
                name="first_name"
                label="First Name"
                placeholder="Enter first name"
                required="First name is required"
                error={errors.first_name}
              />
              <Input
                register={register}
                name="middle_name"
                label="Middle Name"
                placeholder="Enter middle name"
              />
              <Input
                register={register}
                name="last_name"
                label="Last Name"
                placeholder="Enter last name"
                required="Last name is required"
                error={errors.last_name}
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-x-5 gap-y-4">
              <DateTimeShortcutsInput
                name="birthdate"
                label="DOB"
                control={control}
                required="Date of birth is required"
                error={errors.birthdate}
                validateFunc={(value) => {
                  const currentDate = dayjs();
                  const selectedDate = dayjs(value);
                  if (selectedDate.isAfter(currentDate, "day")) {
                    return "Please choose a date in the past";
                  }
                  return true;
                }}
                maxDateTime={dayjs()}
              />

              <Input
                register={register}
                name="preffered_name"
                label="Preferred Name"
                placeholder="Enter preferred name"
              />

              <Select
                label="Sex"
                placeholder="Select Sex"
                name="sex"
                options={sexOptions}
                register={register}
                required="Biological Sex is required"
                error={errors.sex}
              />
              {showInputSex && !isDesktop && (
                <Input
                  value={sexNotListed}
                  onChange={(e) => setSexNotListed(e.target.value)}
                  label="Specify Sex"
                  placeholder="Enter Sex"
                  required="Biological Sex is required"
                  error={sexError}
                />
              )}

              <Select
                label="Gender"
                placeholder="Select Gender"
                name="gender"
                options={genderOptions}
                register={register}
              />
              {showInputGender && !isDesktop && (
                <Input
                  value={genderNotListed}
                  onChange={(e) => setGenderNotListed(e.target.value)}
                  label="Specify Gender"
                  placeholder="Enter Gender"
                />
              )}

              {(showInputSex || showInputGender) && isDesktop && (
                <div className="col-span-2 grid grid-cols-2 gap-x-5">
                  <div className="col-start-1">
                    {showInputSex && (
                      <Input
                        value={sexNotListed}
                        onChange={(e) => setSexNotListed(e.target.value)}
                        label="Specify Sex"
                        placeholder="Enter Sex"
                        required="Biological Sex is required"
                        error={sexError}
                      />
                    )}
                  </div>
                  <div className="col-start-2">
                    {showInputGender && (
                      <Input
                        value={genderNotListed}
                        onChange={(e) => setGenderNotListed(e.target.value)}
                        label="Specify Gender"
                        placeholder="Enter Gender"
                      />
                    )}
                  </div>
                </div>
              )}

              <Select
                label="Pronouns"
                placeholder="Select Pronouns"
                name="pronouns"
                options={pronounsOptions}
                register={register}
              />

              <Input
                register={register}
                name="email"
                label="Email"
                placeholder="Enter email"
                type="email"
                error={errors.email}
                validationPattern={{
                  value: /\S+@\S+\.\S+/,
                  message: "Enter a valid email address",
                }}
              />
              <PhoneInput
                label="Phone Number"
                name="phone_number"
                control={control}
                countries={["US", "AR", "BR", "CA", "IN"]}
                countryOptionsOrder={["US", "CA", "..."]}
                required="Phone number is required"
                error={errors.phone_number}
                onChange={() => {
                  if (patientExistsError) {
                    dispatch(setPatientExistsErrorAction(false));
                  }
                }}
              />
            </div>

            <div className="font-semibold text-sm">Address</div>
            <div className="grid sm:grid-cols-2 gap-x-5 gap-y-4">
              <Input
                register={register}
                name="address_line_1"
                label="Line 1"
                placeholder="Enter address"
              />
              <Input
                register={register}
                name="address_line_2"
                label="Line 2"
                placeholder="Enter address line 2"
              />
              <Input
                register={register}
                name="city"
                label="City"
                placeholder="Enter city"
              />
              <Input
                register={register}
                name="state"
                label="State"
                placeholder="Enter state"
              />
              <Input
                register={register}
                name="country"
                label="Country"
                placeholder="Enter country"
              />
              <Input
                register={register}
                name="zipcode"
                label="Zip Code"
                placeholder="Enter zip code"
              />
            </div>
          </div>

          <Button className="w-full font-semibold" onClick={checkInputSexOther}>
            Save
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default PatientModal;
