import { convert } from "html-to-text";

export const ConsoleLog = ({ children }) => console.log(children);

export const isSameDay = (date1, date2) => {
  const firstDate = new Date(date1.replace(/-/g, "/"));
  const secondDate = new Date(date2.replace(/-/g, "/"));

  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return firstDate.getTime() === secondDate.getTime();
};

export const isToday = (date) => {
  const currDate = new Date();
  const checkDate = new Date(date);

  currDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return currDate.getTime() === checkDate.getTime();
};

export const isYesterday = (date) => {
  const yesterdayDate = new Date();
  const checkDate = new Date(date);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  yesterdayDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return yesterdayDate.getTime() === checkDate.getTime();
};

export const isWeek = (date) => {
  const weekAgoDate = new Date();
  const checkDate = new Date(date);
  weekAgoDate.setDate(weekAgoDate.getDate() - 6);
  weekAgoDate.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return checkDate.getTime() > weekAgoDate.getTime();
};

export const isThisYear = (date) => {
  const currDate = new Date();
  const firstDayOfYear = new Date(currDate.getFullYear(), 0, 1);
  const checkDate = new Date(date);
  firstDayOfYear.setHours(0, 0, 0, 0);
  checkDate.setHours(0, 0, 0, 0);

  return checkDate.getTime() > firstDayOfYear.getTime();
};

export const getAge = (birthdate) => {
  const dob = new Date(birthdate.replace(/-/g, "/") + " GMT+0");
  const currDate = new Date();
  const oneOrZero =
    currDate.getMonth() < dob.getMonth() ||
    (currDate.getMonth() === dob.getMonth() &&
      currDate.getDate() < dob.getDate());
  const yearDifference = currDate.getFullYear() - dob.getFullYear();
  return yearDifference - oneOrZero;
};

export const getFormattedTime = (date) => {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
};

export const getFormattedDateWithDayOfWeek = (date) => {
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

export const getFormattedTimeOrDate = (date) => {
  if (isToday(date)) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  } else if (isThisYear(date)) {
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
  } else {
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
  }
};

export const getFormattedTimeWithSeconds = (duration) => {
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  let time = "";

  if (hrs > 0) {
    time += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  time += "" + mins + ":" + (secs < 10 ? "0" : "");
  time += "" + secs;
  return time;
};

export const getFormattedTimeHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours > 0 ? `${hours}h ` : ""}${
    minutes > 0 ? `${minutes}m ` : ""
  }${remainingSeconds}s`;
};

export const getTimeAndUnit = (minutes) => {
  const mins = Math.abs(minutes);
  let hours = 0;
  let days = 0;
  if (mins >= 60) {
    hours = mins / 60;
  }
  if (hours >= 24) {
    days = hours / 24;
  }
  if (days >= 1) {
    return { value: Math.round(days), unit: "days" };
  } else if (hours >= 1) {
    return { value: Math.round(hours), unit: "hours" };
  } else {
    return { value: mins, unit: "minutes" };
  }
};

export const getDateDivider = (date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (isWeek(date)) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  } else {
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }
};

export const addHours = (date, hours) => {
  const dateCopy = new Date(date);
  dateCopy.setTime(dateCopy.getTime() + hours * 60 * 60 * 1000);
  return dateCopy;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const onDragEnd = (result, values, setValues) => {
  const { destination, source } = result;

  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const newOrder = [...values];
  const [removed] = newOrder.splice(result.source.index, 1);
  newOrder.splice(result.destination.index, 0, removed);
  setValues(newOrder);
};

export const getFormattedPhoneNumber = (number) => {
  const cleanNumber = number.trim().replaceAll(/[^\d]/g, "");
  return cleanNumber.length > 11 ? (
    <p>{cleanNumber.replace(/(\d{2})(\d{3})(\d{3})(\d)/, "+$1 $2-$3-$4")}</p>
  ) : (
    <p>{cleanNumber.replace(/(\d)(\d{3})(\d{3})(\d)/, "+$1 $2-$3-$4")}</p>
  );
};

export const getTagVariant = (tagName) => {
  switch (tagName) {
    // case "HPI":
    //   return "slate";
    // case "CCM":
    //   return "violet";
    // case "PCM":
    //   return "red";
    // case "Registration":
    //   return "red";
    // case "BHI":
    //   return "amber";
    // case "CC":
    //   return "emerald";
    // case "Patient Initiated":
    //   return "sky";
    default:
      return "slate";
  }
};

export const getEventDotBgColor = (tagName) => {
  switch (tagName) {
    case "HPI":
      return "bg-slate-700";
    case "CCM":
      return "bg-violet-700";
    case "PCM":
      return "bg-red-700";
    case "Registration":
      return "bg-red-700";
    case "BHI":
      return "bg-amber-700";
    case "CC":
      return "bg-emerald-700";
    case "Patient Initiated":
      return "bg-sky-700";
    default:
      return "bg-slate-700";
  }
};

export const selectInputText = (e) => {
  e.target.select();
};

export const removeTags = (textWithHtmlTags) => {
  const hasBR = /<br\s*\/?>/i.test(textWithHtmlTags);
  return !hasBR
    ? textWithHtmlTags
    : convert(
        textWithHtmlTags
          .replace(
            /<\/[a-zA-Z]+>\s*<br\/>/g,
            (match) => `</${match.match(/[a-zA-Z]+/)[0]}>`
          )
          .replace(/<br><\/[a-zA-Z]+>/g, `</$1>`),
        {
          wordwrap: false,
        }
      );
};

export const copyText = (textWithHtmlTags, onSuccess) => {
  const text = removeTags(textWithHtmlTags);
  navigator.clipboard.writeText(text).then(() => onSuccess());
};

export const convertToHtmlList = (text) => {
  const lines = text.split("\n");

  let html = "";
  let inList = false;

  for (const line of lines) {
    if (line.trim().startsWith("- ")) {
      if (!inList) {
        html += "<ul style='list-style-type: disc; padding-left: 18px'>";
        inList = true;
      }
      html += `<li>${line.trim().substring(1).trim()}</li>`;
    } else {
      if (inList) {
        html += "</ul>";
        inList = false;
      }
      html += `<p>${line}</p>`;
    }
  }
  if (inList) {
    html += "</ul>";
  }
  return html;
};

export const deepEqual = (obj1, obj2) => {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }
  if (Array.isArray(obj1)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    const sortedObj1 = obj1.slice().sort();
    const sortedObj2 = obj2.slice().sort();
    return sortedObj1.every((value, index) =>
      deepEqual(value, sortedObj2[index])
    );
  }
  if (typeof obj1 === "object" && obj1 !== null) {
    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();
    if (!deepEqual(keys1, keys2)) {
      return false;
    }
    return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
  }
  return obj1 === obj2;
};

export const getFrequencyByInterval = (interval) => {
  switch (interval) {
    case 0:
      return "once";
    case 1440:
      return "daily";
    case 4320:
      return "every_3_days";
    case 10080:
      return "weekly";
    case 20160:
      return "biweekly";
    case 43200:
      return "monthly";
    case 86400:
      return "every_2_months";
    case 129600:
      return "every_3_months";
    case 259200:
      return "every_6_months";
    case 525600:
      return "yearly";
  }
};
