import html2pdf from "../../../node_modules/html2pdf.js/dist/html2pdf";
import { getAge } from "../helpers";
import { mulishFont } from "./Mulish-VariableFont_wght-normal";
import logoSrc from "../../assets/logo-quadrant-text-dark.png";

export const getHtmlMarkup = (htmlMarkup) => {
  const hasBR = /<br\s*\/?>/i.test(htmlMarkup);
  if (!hasBR) htmlMarkup = htmlMarkup.replaceAll(/\n/g, "<br/>");
  htmlMarkup = htmlMarkup.replaceAll(/<li><\/li>/g, "<li>&nbsp;</li>");
  return `<div class="generated-html">${htmlMarkup}<div class="last-line"/></div>`;
};

export const addPatientDetails = (patient, content) => {
  return `<p>ID: ${
    patient.preffered_name ? patient.preffered_name : patient.first_name
  } ${patient.last_name}, ${
    patient.birthdate ? `${getAge(patient.birthdate)} year-old` : ""
  } ${patient.gender ? patient.gender : patient.sex}</p>
    ${content}`;
};

export const addTimeAndSignature = (content, signatureImg, signatureText) => {
  const timeStamp = new Date().toLocaleString("en-US", {
    month: "numeric",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });

  const signature = signatureImg
    ? `<img src="${signatureImg}" width="200px" height="auto" alt="">`
    : `<p style="font-style: italic; font-size: 16px; font-family: 'Times New Roman', sans-serif; margin-bottom: 4px">${signatureText}</p>`;

  return `<div>
        ${content}
        <div style="width: 1px; height: 1px"></div>
        <div style="margin-top: 30px; display: flex; width: 100%; align-items: center; justify-content: flex-end">
          ${signature}
          <p style="margin-left: 30px">${timeStamp}</p> 
        </div>
      </div>`;
};

export const generatePdf = (
  filename,
  htmlMarkup,
  openInNewTab,
  setUrlString
) => {
  const options = {
    margin: [23, 15, 25, 15],
    filename: filename,
    pagebreak: { mode: ["avoid-all"] },
    image: { type: "jpeg", quality: 1 },
    jsPDF: { unit: "mm", format: "a4", orientation: "p" },
  };

  const worker = html2pdf()
    .set(options)
    .from(getHtmlMarkup(htmlMarkup))
    .toPdf()
    .get("pdf")
    .then((pdf) => {
      pdf.addFileToVFS("Mulish-VariableFont_wght-normal.ttf", mulishFont);
      pdf.addFont("Mulish-VariableFont_wght-normal.ttf", "Mulish", "normal");
      pdf.setFont("Mulish");

      const totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(100);
        pdf.text(
          "Page " + i + " of " + totalPages,
          pdf.internal.pageSize.getWidth() - 33,
          15
        );
        pdf.addImage({
          imageData: logoSrc,
          format: "PNG",
          x: 14,
          y: 11,
          compression: "NONE",
          width: 25,
          height: 8,
        });
        pdf.addImage({
          imageData: logoSrc,
          format: "PNG",
          x: pdf.internal.pageSize.getWidth() - 40,
          y: pdf.internal.pageSize.getHeight() - 17,
          compression: "NONE",
          width: 25,
          height: 8,
        });
      }
    });

  if (setUrlString) {
    worker.output("blob").then((blob) => {
      const url = URL.createObjectURL(blob);
      setUrlString(url);
    });
  } else if (openInNewTab) {
    worker.output("blob").then((blob) => {
      const url = URL.createObjectURL(blob);
      const newTab = window.open();
      newTab.document.open();
      newTab.document.write(`
      <html lang="en">
        <head>
          <title>${filename}</title>
          <style>
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            iframe {
              width: 100%;
              height: 100%;
              border: none;
            }
          </style>
        </head>
        <body>
          <iframe src="${url}"></iframe>
        </body>
      </html>
    `);
      newTab.document.close();
    });
  } else {
    worker.save(filename);
  }
};
