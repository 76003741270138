import React, { useEffect, useRef, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  setRerenderEnrollFieldsAction,
  setSelectedCampaignAction,
  setSelectedEventAction,
  setSelectedTabAction,
} from "../store/campaign/actions";
import { CampaignTabs, TabsOrientation } from "../helpers/constants";
import Layout from "../components/Layout";
import TabsBorder from "../components/Basic/TabsBorder";
import TabsBg from "../components/Basic/TabsBg";
import Loader from "../components/Basic/Loader";
import CampaignListCheckboxes from "../components/Campaigns/CampaignListCheckboxes";

const UpcomingTab = lazy(() => import("../components/Campaigns/UpcomingTab"));
const DetailsTab = lazy(() => import("../components/Campaigns/DetailsTab"));
const EnrollTab = lazy(() => import("../components/Campaigns/EnrollTab"));
const ControlsTab = lazy(() => import("../components/Campaigns/ControlsTab"));

const Campaigns = () => {
  const { ENROLL, DETAILS, UPCOMING, CONTROLS } = CampaignTabs;
  const { campaigns, campaignTags, selectedCampaign, selectedTab, loader } =
    useSelector((state) => state.campaign);
  const tabContent = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();

  useEffect(() => {
    tabContent.current.scrollTo(0, 0);
  }, [selectedCampaign]);

  useEffect(() => {
    dispatch(setSelectedEventAction(null));
  }, [selectedTab]);

  const onSelectCampaign = (campaign) => {
    dispatch(setSelectedCampaignAction(campaign));
    dispatch(setRerenderEnrollFieldsAction(true));
  };

  return (
    <Layout>
      <div className="grid grid-rows-tab-layout overflow-hidden md:flex md:flex-row border-neutral-200 w-full h-full">
        <div className="w-full md:w-60 lg:w-75 2xl:w-96 flex-none md:border-r flex flex-col justify-between">
          <div className="grid h-full md:overflow-hidden px-5 md:px-0 relative">
            {loader && (
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            )}
            {campaigns.length === 0 ? (
              !loader && <p className="px-6 py-5">Empty list</p>
            ) : selectedTab === UPCOMING ? (
              <div className="min-h-0 h-full overflow-x-auto md:overflow-y-auto scrollbar pb-1 md:pb-0">
                <CampaignListCheckboxes />
              </div>
            ) : (
              selectedCampaign && (
                <div className="min-h-0 h-full overflow-x-auto md:overflow-y-auto scrollbar pb-1 md:pb-0">
                  <TabsBorder
                    campaigns
                    tabs={campaigns}
                    activeTab={selectedCampaign}
                    setActiveTab={onSelectCampaign}
                    showDesc={isDesktop}
                    tags={campaignTags}
                    rerender={[selectedTab]}
                    orientation={
                      isDesktop
                        ? TabsOrientation.VERTICAL
                        : TabsOrientation.HORIZONTAL
                    }
                  />
                </div>
              )
            )}
          </div>
        </div>

        <div className="w-full h-full overflow-y-auto scrollbar md:overflow-hidden">
          <div
            className="md:min-h-0 h-full py-4 px-4 md:py-6 lg:pl-6 md:pr-6 w-full
              md:overflow-hidden md:grid grid-rows-tab-layout md:bg-gray-background"
          >
            {isDesktop ? (
              <div className="bg-white px-6 pt-5 rounded-t-lg border-t border-l border-r border-gray-200">
                <TabsBorder
                  tabs={Object.values(CampaignTabs)}
                  activeTab={selectedTab}
                  setActiveTab={(tab) => dispatch(setSelectedTabAction(tab))}
                  activeBgColor="sky-50"
                  activeTextColor="primary-blue"
                  tabClassName="p-3"
                  className="text-base border-b"
                />
              </div>
            ) : (
              <div className="rounded-xl border-4 border-gray-150 bg-gray-150 md:bg-white">
                <TabsBg
                  tabs={Object.values(CampaignTabs)}
                  activeTab={selectedTab}
                  onChange={(tab) => dispatch(setSelectedTabAction(tab))}
                  className="w-full"
                  tabClassName="py-2 text-center"
                />
              </div>
            )}

            <div
              ref={tabContent}
              className="md:min-h-0 md:overflow-y-auto md:pr-2 lg:pr-8 py-5 md:pl-6 scrollbar bg-white rounded-b-lg
                md:border-b md:border-l md:border-r border-gray-200"
            >
              <Suspense
                fallback={
                  <div className="w-full h-full flex items-center justify-center">
                    <Loader />
                  </div>
                }
              >
                {selectedCampaign &&
                  (selectedTab === ENROLL ? (
                    <EnrollTab />
                  ) : selectedTab === UPCOMING ? (
                    <UpcomingTab />
                  ) : selectedTab === DETAILS ? (
                    <DetailsTab />
                  ) : selectedTab === CONTROLS ? (
                    <ControlsTab />
                  ) : (
                    <></>
                  ))}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Campaigns;
