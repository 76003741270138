import React, { useEffect, useState } from "react";

const TabsBg = ({
  tabs,
  activeTab,
  onChange,
  className,
  tabClassName,
  activeBgColor = "primary-blue",
  activeTextColor = "white",
}) => {
  const [translateX, setTranslateX] = useState(0);
  const tabWidth = 100 / tabs.length;

  useEffect(() => {
    if (activeTab === tabs[tabs.length - 1]) {
      setTranslateX(100 * tabs.indexOf(activeTab) - 5);
    } else {
      setTranslateX(100 * tabs.indexOf(activeTab));
    }
  }, [activeTab]);

  const getTab = (tab) => {
    return (
      <li
        key={tab}
        onClick={() => onChange(tab)}
        style={{
          width: tabWidth + "%",
        }}
        className={`cursor-pointer font-medium rounded-lg py-1 px-2 
          flex items-center justify-center z-[1] transition-all 
          ${tabClassName} 
          ${activeTab === tab ? `text-${activeTextColor}` : "text-zinc-500"}
        `}
      >
        {tab}
      </li>
    );
  };

  return (
    <ul
      className={`flex bg-gray-150 text-xs rounded-2.5 p-0.5 relative ${className}`}
    >
      <div
        aria-hidden="true"
        style={{
          transform: `translateX(${translateX}%) translateY(-50%)`,
          width: tabWidth + "%",
        }}
        className={`absolute h-[80%] rounded-lg z-0 left-0.5 top-1/2 transition-all bg-${activeBgColor}`}
      />
      {tabs.map((tab) => getTab(tab))}
    </ul>
  );
};

export default TabsBg;
