import {
  SET_CURR_PAGE,
  SET_NUM_FILTERED_PATIENTS,
  SET_NUM_PATIENTS,
  SET_PATIENT_EXISTS_ERROR,
  SET_PATIENT_LOADER,
  SET_PATIENTS,
  SET_PROFILE_LOADING,
  SET_SEARCH,
  SET_SELECTED_PATIENT_PROFILE,
  SET_SELECTED_PATIENTS,
  SET_TABLE_SCROLL_POSITION,
} from "../types";

export function setPatientsAction(patients) {
  return {
    type: SET_PATIENTS,
    payload: {
      patients: patients,
    },
  };
}

export function setSelectedPatientsAction(patients) {
  return {
    type: SET_SELECTED_PATIENTS,
    payload: {
      patients: patients,
    },
  };
}

export function setSelectedPatientProfileAction(patient) {
  return {
    type: SET_SELECTED_PATIENT_PROFILE,
    payload: {
      patient: patient,
    },
  };
}

export function setProfileLoadingAction(profileLoading) {
  return {
    type: SET_PROFILE_LOADING,
    payload: {
      profileLoading: profileLoading,
    },
  };
}

export function setCurrPageAction(currPage) {
  return {
    type: SET_CURR_PAGE,
    payload: {
      currPage: currPage,
    },
  };
}

export function setSearchAction(searchValue) {
  return {
    type: SET_SEARCH,
    payload: {
      searchValue: searchValue,
    },
  };
}

export function setNumOfPatientsAction(numOfPatients) {
  return {
    type: SET_NUM_PATIENTS,
    payload: {
      numOfPatients: numOfPatients,
    },
  };
}

export function setNumOfFilteredPatientsAction(numOfFilteredPatients) {
  return {
    type: SET_NUM_FILTERED_PATIENTS,
    payload: {
      numOfFilteredPatients: numOfFilteredPatients,
    },
  };
}

export function setPatientLoaderAction(loader) {
  return {
    type: SET_PATIENT_LOADER,
    payload: {
      loader: loader,
    },
  };
}

export function setTableScrollPositionAction(tableScrollPosition) {
  return {
    type: SET_TABLE_SCROLL_POSITION,
    payload: {
      tableScrollPosition: tableScrollPosition,
    },
  };
}

export function setPatientExistsErrorAction(patientExistsError) {
  return {
    type: SET_PATIENT_EXISTS_ERROR,
    payload: {
      patientExistsError: patientExistsError,
    },
  };
}
