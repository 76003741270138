import React from "react";
import { useSelector } from "react-redux";
import Button from "../Basic/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as CloudIcon } from "../../assets/icons/upload-cloud.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-small.svg";

const TableHeader = ({
  selectedPatients,
  setCanselSelection,
  onImportPatients,
  onAddPatient,
}) => {
  const { numOfPatients } = useSelector((state) => state.patient);

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 px-4 md:px-6 py-5">
      <div className="flex items-center space-x-2 md:h-10">
        <p className="flex text-lg font-semibold">
          <span className="hidden md:flex mr-1">My </span> Patients
        </p>
        <div className="text-sm font-semibold text-primary-blue px-2 py-0.5 bg-indigo-50 rounded-full">
          {numOfPatients}
        </div>
        {selectedPatients.length > 0 && (
          <div className="text-xs text-primary-blue px-2 py-0.5 border border-primary-blue rounded-full flex items-center space-x-1">
            <p>{selectedPatients.length} selected</p>
            <button onClick={() => setCanselSelection(true)}>
              <CloseIcon stroke="#ef4444" width="16" height="17" />
            </button>
          </div>
        )}
      </div>

      {numOfPatients === 0 && (
        <div className="flex items-center space-x-3 text-sm">
          <Button
            className="space-x-2 font-semibold"
            variant="gray"
            onClick={onImportPatients}
          >
            <CloudIcon width="20" height="20" />
            <p>Import</p>
          </Button>
          <Button
            className="space-x-2 font-semibold whitespace-nowrap"
            onClick={onAddPatient}
          >
            <PlusIcon stroke="white" width="12" height="12" />
            <p>Add patient</p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default TableHeader;
