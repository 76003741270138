import {
  SET_CONVERSATION_ID_GENERATING,
  SET_CONVERSATION_RECORDS,
  SET_OPEN_ATTACH_MODAL,
  SET_OPEN_ENCOUNTER_MODAL,
  SET_PATIENT_ID_GENERATING,
  SET_SAVED_AUDIO_ID,
  SET_TRANSCRIBING,
} from "../types";

const init = {
  conversationRecords: [],
  transcribing: false,
  savedAudioId: null,
  openAttachModal: false,
  openEncounterModal: false,
  conversationIdGenerating: null,
  patientIdGenerating: null,
};

export default function voiceRecorderReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CONVERSATION_RECORDS:
      return { ...state, conversationRecords: payload.conversationRecords };
    case SET_TRANSCRIBING:
      return { ...state, transcribing: payload.transcribing };
    case SET_SAVED_AUDIO_ID:
      return { ...state, savedAudioId: payload.savedAudioId };
    case SET_OPEN_ATTACH_MODAL:
      return { ...state, openAttachModal: payload.openAttachModal };
    case SET_OPEN_ENCOUNTER_MODAL:
      return { ...state, openEncounterModal: payload.openEncounterModal };
    case SET_CONVERSATION_ID_GENERATING:
      return {
        ...state,
        conversationIdGenerating: payload.conversationIdGenerating,
      };
    case SET_PATIENT_ID_GENERATING:
      return {
        ...state,
        patientIdGenerating: payload.patientIdGenerating,
      };
    default:
      return state;
  }
}
