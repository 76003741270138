import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCampaignIdsAction } from "../../store/campaign/actions";
import { getTagVariant } from "../../helpers/helpers";
import Checkbox from "../Basic/Checkbox";
import Tag from "../Basic/Tag";

const CampaignListCheckboxes = ({ className }) => {
  const { campaigns, selectedCampaignIds, campaignTags } = useSelector(
    (state) => state.campaign
  );
  const dispatch = useDispatch();

  const isChecked = (campaignId) => {
    return selectedCampaignIds.includes(campaignId);
  };

  const onSelect = (campaignId) => {
    if (isChecked(campaignId)) {
      dispatch(
        setSelectedCampaignIdsAction(
          selectedCampaignIds.filter((id) => id !== campaignId)
        )
      );
    } else {
      dispatch(
        setSelectedCampaignIdsAction([...selectedCampaignIds, campaignId])
      );
    }
  };

  return (
    <ul
      className={`flex w-fit min-w-full md:w-full relative md:flex-col ${className}`}
    >
      {campaigns.map((campaign) => {
        let tag = null;
        if (campaignTags && campaignTags.length > 0) {
          tag = campaignTags.find((tag) => tag.tag_id === campaign.tag_id);
        }
        return (
          <li
            key={campaign.campaign_id}
            className={`cursor-pointer font-semibold relative py-4 pl-3 pr-2 flex items-start space-x-2 
              border-b-2 md:border-b-0 md:border-l-4
              ${
                isChecked(campaign.campaign_id)
                  ? "md:bg-sky-50 text-primary border-primary-blue"
                  : "text-gray-500 md:text-primary border-gray-200 md:border-transparent"
              } `}
            onClick={() => onSelect(campaign.campaign_id)}
          >
            <Checkbox
              className="mt-1"
              checked={isChecked(campaign.campaign_id)}
              onClick={(e) => e.stopPropagation()}
            />
            <div className="w-full">
              <div className="w-full flex md:grid grid-cols-conv-layout items-center justify-between gap-1.5 w-full">
                <p className="text-sm md:text-base font-semibold whitespace-nowrap truncate">
                  {campaign.campaign}
                </p>
                {tag && (
                  <Tag
                    key={tag.tag_id}
                    text={tag.tag}
                    variant={getTagVariant(tag.tag)}
                    className="whitespace-nowrap py-1 px-2"
                  />
                )}
              </div>

              {campaign.description && (
                <p className="hidden md:flex text-xs text-zinc-700 mt-2.5">
                  {campaign.description}
                </p>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CampaignListCheckboxes;
