import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../../helpers/history";

const ProtectedRoute = () => {
  const { user } = useSelector((state) => state.user);

  if (!user) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
