import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  setSelectedStartDateAction,
  setStartDateShortcutAction,
} from "../../../store/campaign/actions";
import TabsBg from "../TabsBg";

const CustomDateShortcuts = ({ items, onChange, isValid, withoutPadding }) => {
  const { selectedStartDate, activeStartDateShortcut } = useSelector(
    (state) => state.campaign
  );
  const [activeBgColor, setActiveBgColor] = useState("gray-150");
  const [activeTextColor, setActiveTextColor] = useState("zinc-500");
  const dispatch = useDispatch();

  useEffect(() => {
    checkDate(selectedStartDate);
  }, [selectedStartDate]);

  if (items == null || items.length === 0) {
    return null;
  }

  const onChangeShortcut = (tab) => {
    setActiveTabColors();
    const newValue = items
      .find((item) => item.label === tab)
      .getValue({ isValid });
    onChange(newValue);
    dispatch(setStartDateShortcutAction(tab));
    dispatch(
      setSelectedStartDateAction(newValue.toDate().toLocaleString("en-us"))
    );
  };

  const checkDate = (date) => {
    if (date === "now") {
      dispatch(setStartDateShortcutAction("Now"));
    } else {
      const currentDate = dayjs();
      const checkedDate = dayjs(date);
      const tomorrowDate = currentDate.clone().startOf("day").add(1, "day");
      const nextWeekStart = currentDate.clone().startOf("week").add(7, "days");
      const nextWeekEnd = currentDate.clone().endOf("week").add(7, "days");
      const nextMonthStart = currentDate
        .clone()
        .startOf("month")
        .add(1, "month");
      const nextMonthEnd = currentDate.clone().add(1, "month").endOf("month");

      if (checkedDate.isSame(currentDate, "minute")) {
        dispatch(setStartDateShortcutAction("Now"));
      } else if (checkedDate.isSame(tomorrowDate, "day")) {
        dispatch(setStartDateShortcutAction("Tomorrow"));
      } else if (
        checkedDate.isBetween(nextWeekStart, nextWeekEnd, "day", "[]")
      ) {
        dispatch(setStartDateShortcutAction("Next week"));
      } else if (
        checkedDate.isBetween(nextMonthStart, nextMonthEnd, "day", "[]")
      ) {
        dispatch(setStartDateShortcutAction("Next month"));
      } else {
        setActiveBgColor("gray-150");
        setActiveTextColor("zinc-500");
        return;
      }
    }

    setActiveTabColors();
  };

  const setActiveTabColors = () => {
    setActiveBgColor("primary-blue");
    setActiveTextColor("white");
  };

  return (
    <div
      className={`flex row-span-1 col-span-2 
        ${withoutPadding ? "" : "pt-2 md:pt-16 pb-6 md:mb-6 px-5 md:border-b"}`}
    >
      <TabsBg
        tabs={items.map((item) => item.label)}
        activeTab={activeStartDateShortcut}
        onChange={onChangeShortcut}
        className="w-full"
        tabClassName="py-1 text-center"
        activeBgColor={activeBgColor}
        activeTextColor={activeTextColor}
      />
    </div>
  );
};

export default CustomDateShortcuts;
